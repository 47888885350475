import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MoodService {
  constructor() {}

  getRandomQuote = () => {
    return new Date().getDay();
  };
}
