import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-page',
  templateUrl: './admin.page.html',
  styleUrls: ['./admin.page.scss'],
})
export class AdminPage {
  toolbarData = {
    arrowRouter: '/',
    btnSlot: 'end',
    title: 'Admin page',
  };

  constructor() {}
}
