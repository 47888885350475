import { environment } from "src/environments/environment";

export interface ISlide {
  title: string;
  icon: string;
  description: string;
}

export class TutorialService {
  constructor() {}

  tutorialData = [
    {
      title: environment.app === 'danceApp' ? 'Find Dancers Close To You' : 'World Community',
      icon: environment.app === 'danceApp' ? '/assets/tutorial/1.jpg' : '/assets/tutorial/tutorial-community.webp',
      description:
        environment.app === 'danceApp' ? 'On our global map & meet up in groups and recommended dancing locations!' : 'Connect with fellow merpeople all around the globe - online and offline.',
    },
    {
      title: environment.app === 'danceApp' ? 'Actively Participate in the Dancing World' : 'MerMarketplace',
      icon: environment.app === 'danceApp' ? '/assets/tutorial/2.jpg' : '/assets/tutorial/tutorial-marketplace.webp',
      description:
      environment.app === 'danceApp' ? "Via gatherings, courses & events and don't miss a single dance!" : 'Your marketplace for new and 2nd hand articles as well as for finding and offering jobs and services.',
    },
    {
      title: environment.app === 'danceApp' ? 'One Uniting Dance MarketPlace!' : 'Information Hub',
      icon: environment.app === 'danceApp' ? '/assets/tutorial/3.jpg' : '/assets/tutorial/tutorial-info-hub.webp',
      description: environment.app === 'danceApp' ? 'Your go-to for new & 2nd hand articles as well as for finding & offering jobs and services.' :
        "Know what's going on where &when - media,events and environmental projects easily accessible in one place for you to participate on- and offline.",
    },
    {
      title: environment.app === 'danceApp' ? 'Be part of the Game!' : 'Be part of the game',
      icon: environment.app === 'danceApp' ? '/assets/tutorial/4.jpg' : '/assets/tutorial/tutorial-gamification.webp',
      description: environment.app === 'danceApp' ? 'Engage, contribute, and move with the flow for free premium benefits!' :
        'Contribute to the community by moderating and adding content yourself while earning rewards and unlocking surprises',
    },
  ];
}
