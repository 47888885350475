import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InterstitialComponent } from './interstitial.component';
import { IonicModule } from '@ionic/angular';
import { AdsenseModule } from 'ng2-adsense';

@NgModule({
  declarations: [InterstitialComponent],
  imports: [
    AdsenseModule.forRoot({
      adClient: 'ca-pub-2609549176457465',
      adSlot: 5551665146,
    }),
    CommonModule,
    IonicModule,
  ],
  exports: [InterstitialComponent],
})
export class InterstitialModule {}
