import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TutorialComponent } from './tutorial.component';
import { TutorialFirstPageComponent } from './tutorial-first-page/tutorial-first-page.component';
import { TutorialSecondPageComponent } from './tutorial-second-page/tutorial-second-page.component';
import { TutorialThirdPageComponent } from './tutorial-third-page/tutorial-third-page.component';
import { TutorialFourthPageComponent } from './tutorial-fourth-page/tutorial-fourth-page.component';

const routes: Routes = [
  {
    path: '',
    component: TutorialComponent,
    children: [
        {
          path: '1',
          component: TutorialFirstPageComponent,
        },
        {
          path: '2',
          component: TutorialSecondPageComponent,
        },
        {
          path: '3',
          component: TutorialThirdPageComponent,
        },
        {
          path: '4',
          component: TutorialFourthPageComponent,
        },
      ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TutorialRoutingModule {}
