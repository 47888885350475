import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'number',
})
export class NumberPipe implements PipeTransform {
  transform(value: number): unknown {
    const numerous = ['one', 'two', 'three', 'four', 'five'];
    return numerous.length >= +value ? numerous[value - 1] : value;
  }
}
