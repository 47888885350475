import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'utc',
})
export class UtcPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    if (value && value.includes('GMT')) {
      return value.replace('GMT', 'UTC');
    } else return value;
  }
}
