import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { AdsenseModule } from 'ng2-adsense';
import { MrecComponent } from './mrec.component';

@NgModule({
  declarations: [MrecComponent],
  imports: [
    AdsenseModule.forRoot({
      adClient: 'ca-pub-2609549176457465',
      adSlot: 2422066789,
    }),
    CommonModule,
    IonicModule,
  ],
  exports: [MrecComponent],
})
export class MrecModule {}
