import { Component, OnDestroy, OnInit } from '@angular/core';
import { IToolbarData } from '../shared/models/components-data.models';
import { showLoading } from '../shared/components/modals/loading';
import { Subject, combineLatest, filter } from 'rxjs';
import { PlanetStore } from './planet-store';
import { PlanetService } from './planet.service';
import { AuthService } from '../firebase/auth.service';
import { takeUntil, map } from 'rxjs/operators';
import { FormService } from '../shared/components/form/form.service';
import { IPlanetFilter } from './planet';
import mixpanel from 'mixpanel-browser';
import { environment } from '../../environments/environment';

const TOOLBAR_DATA: IToolbarData = {
  arrowRouter: '/',
  btnSlot: 'end',
  title: environment.app === 'danceApp' ? 'Stage' : 'Planet',
};
@Component({
  selector: 'app-planet',
  templateUrl: './planet.component.html',
  styleUrls: ['./planet.component.scss'],
})
export class PlanetComponent implements OnInit, OnDestroy {
  TOOLBAR_DATA = TOOLBAR_DATA;
  status;
  loader = showLoading();
  myContent: boolean = false;
  country: string | null = null;
  user: string;
  creator;
  isOpenFormModal = false;
  items;
  planetItem;
  olderItems;
  startDate: number;
  endDate: number;
  color = 'dark';
  properties;
  private destroy$ = new Subject<void>();

  constructor(
    private planetStore: PlanetStore,
    private planetService: PlanetService,
    private authService: AuthService,
    private formService: FormService
  ) {}

  ngOnInit() {
    mixpanel.init(environment.mixpanelToken, { debug: true });
    const { uid } = this.authService.isUserAuth();
    mixpanel.identify(uid);
    this.planetStore.getItems({});
    this.getUser();
    this.listenTodayChallengeChanges();
    this.listenStatusChanges();
    this.listenOlderChallengeChanges();
    this.listenAllChallengeChanges();
    this.listenFilter();
    this.planetStore.selectShowFormModal().subscribe(isOpen => {
      this.isOpenFormModal = isOpen;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.formService.planetMenuData$.next(false);
  }

  trackEvent(event, parameter, parameterValue) {
    if (parameter === 'screen-name') {
      this.properties = {
        'screen-name': parameterValue,
      };
    }

    mixpanel.track(event, this.properties);
  }

  openModal() {
    this.planetStore.updateShowFormModal(true);
  }

  closeModal(e) {
    e ? this.planetStore.updateShowFormModal(false) : null;
  }

  private listenStatusChanges() {
    this.planetStore
      .selectLoading()
      .pipe(takeUntil(this.destroy$))
      .subscribe(loading => {
        loading
          ? this.loader.then(data => data.present())
          : this.loader.then(data => data.dismiss());
      });
  }

  private listenTodayChallengeChanges() {
    combineLatest([this.planetStore.selectItems()])
      .pipe(
        takeUntil(this.destroy$),
        map(([items]) => {
          return (
            items &&
            (items.find(item => {
              let actualDate = new Date();
              actualDate.setHours(3);
              actualDate.setMinutes(0);
              actualDate.setSeconds(0);
              if (!this.myContent && item.challengeDate) {
                return (
                  item.challengeDate.seconds ===
                  Math.floor(actualDate.getTime() / 1000)
                );
              }
            }) ||
              items.find(item => item.challengeDate !== null))
          );
        })
      )
      .subscribe(item => {
        this.planetItem = item;
      });
  }

  private listenFilter() {
    this.formService.planetMenuData$.subscribe((data: IPlanetFilter) => {
      const initialData = this.planetService.checkFilter(data);

      this.myContent = (data as IPlanetFilter).myContent;
      this.planetStore.getItems(data);
    });
  }

  private listenOlderChallengeChanges() {
    combineLatest([this.planetStore.selectItems()])
      .pipe(
        takeUntil(this.destroy$),
        map(([items]) => {
          return (
            items &&
            items.filter(item => {
              if (!this.myContent && item.challengeDate) {
                const currentDate = new Date(); // получить текущую дату и время
                const year = currentDate.getFullYear();
                const month = currentDate.getMonth();
                const day = currentDate.getDate();
                const startOfDay = new Date(year, month, day, 0, 0, 0);
                const seconds = startOfDay.getTime() / 1000;
                return item.challengeDate.seconds < seconds;
              }
            })
          );
        })
      )
      .subscribe(items => {
        this.olderItems = items;
      });
  }

  private listenAllChallengeChanges() {
    combineLatest([this.planetStore.selectItems()])
      .pipe(
        takeUntil(this.destroy$),
        map(([items]) => {
          if (this.myContent) {
            return items;
          }
        })
      )
      .subscribe(items => {
        this.items = items;
      });
  }

  private getUser() {
    const { uid } = this.authService.isUserAuth();

    this.authService
      .getUserByUid(uid)
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user)
      )
      .subscribe(user => {
        this.user = user.id;
      });
  }
}
