import { Injectable } from '@angular/core';

import { CommonComponentStore } from '../../shared/component-store/common-component-store';
import { Shop } from '../bazaar';
import { ShopService } from './shop.service';

@Injectable({ providedIn: 'root' })
export class ShopStore extends CommonComponentStore<Shop> {
  constructor(shopService: ShopService) {
    super(shopService, {
      createdId: null,
      item: null,
      items: null,
      loading: false,
      showFormModal: false,
      showPendingModal: false,
      collectionName: 'shops',
    });
  }

  selectShowFormModal() {
    // TODO update
    // @ts-ignore
    return this.select(state => state.showFormModal);
  }

  updateShowFormModal(value) {
    // TODO update
    // @ts-ignore
    this.patchState({ showFormModal: value });
  }

  selectShowPendingModal() {
    // TODO update
    // @ts-ignore
    return this.select(state => state.showPendingModal);
  }

  updateShowPendingModal(value) {
    // TODO update
    // @ts-ignore
    this.patchState({ showPendingModal: value });
  }
}
