import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { IonModal } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  createToaster,
  positionType,
} from 'src/app/shared/components/modals/toaster';

@Component({
  selector: 'app-profile-picture-uploader',
  templateUrl: './profile-picture-uploader.component.html',
  styleUrls: ['./profile-picture-uploader.component.scss'],
})
export class ProfilePictureUploaderComponent implements OnDestroy {
  @ViewChild(IonModal) modal: IonModal;

  @Input() isOpen: boolean = false;
  @Input() imagePath: string = '';
  @Input() imageName: string = '';
  @Input() imageChangedEvent: any = '';
  @Input() saveImageFn: (...args: any) => Observable<any>;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onCloseModal = new EventEmitter();

  croppedImage: string = '';
  isLoading: boolean = false;

  private destroy$ = new Subject();

  constructor() {}

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  loadImageFailed() {
    createToaster('image loading failed', 'danger', positionType.BOTTOM);
  }

  cancel() {
    this.onCloseModal.emit();
    this.modal.dismiss(null, 'cancel');
  }

  confirm() {
    this.isLoading = true;
    this.saveImageFn(this.croppedImage, this.imagePath, this.imageName)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.isLoading = false;
          this.modal.dismiss(this.croppedImage, 'confirm');
        },
        error: () => {
          this.isLoading = false;
        },
      });
  }

  onWillDismiss() {
    this.onCloseModal.emit();
  }
}
