import { Component, Input, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { map } from 'rxjs/operators';
import { IReaction } from '../../../activity-feed/activity-feed';
import { ReactionsService } from '../../../services/reactions.service';

@Component({
  selector: 'app-like-button',
  templateUrl: './like-button.component.html',
  styleUrls: ['./like-button.component.scss'],
})
export class LikeButtonComponent implements OnInit {
  @Input() itemId: string;
  @Input() currentUserId: string;
  @Input() itemCollection: string;

  likesInfo;
  loadingLikeButton;

  private destroy$ = new Subject<void>();
  constructor(private reactionsService: ReactionsService) {}

  ngOnInit() {
    this.reactionsService
      .getReactions()
      .pipe(
        takeUntil(this.destroy$),
        map((items: any) => {
          return items && items.filter(item => item.id === this.itemId);
        })
      )
      .subscribe((data: IReaction[]) => {
        this.likesInfo = data[0]?.likes;
        this.loadingLikeButton = false;
      });
  }

  putLikeOrDislike() {
    this.loadingLikeButton = true;
    try {
      this.reactionsService.putLikeOrDislike(this.itemId, this.itemCollection);
    } finally {
      this.loadingLikeButton = false;
    }
  }
}
