import { Component, EventEmitter, Input, Output } from '@angular/core';
@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class RequestModalComponent {
  @Input() userData;

  @Output() accept = new EventEmitter<boolean>(false);
  @Output() reject = new EventEmitter<boolean>(false);

  constructor() {}

  handleOk() {
    this.accept.next(true);
  }

  handleCancel() {
    this.reject.next(true);
  }
}
