export interface InfoItem {
  id: string;
  balancePearls: number;
  balanceShells: number;
  lastTreasureOpening?: number;
}

export const subscriptionsInfo = [
  {
    period: '1 month',
    price: '4.99',
    billPeriod: 'billed monthly',
    weeklyPrice: '1.25',
  },
  {
    period: '3 months',
    price: '11.99',
    billPeriod: 'billed quarterly',
    weeklyPrice: '0.99',
  },
  {
    period: '12 months',
    price: '30',
    billPeriod: 'billed annuall',
    weeklyPrice: '0.62',
  },
];
export enum subscriptionMsg {
  APP_PREMIUM = "You're enjoying Beta Premium Access - free until early 2024!",
  PAID_PREMIUM = '',
  PERSONAL_PREMIUM = "Exclusive Access: You're on VIP Premium - our treat!",
}
