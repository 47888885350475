import { Pipe, PipeTransform } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { filter, Observable } from 'rxjs';
@Pipe({
  name: 'getImage',
})
export class GetImagePipe implements PipeTransform {
  constructor(private storage: AngularFireStorage) {}
  transform(item: any): Observable<string> {
    return this.storage
      .ref(item)
      .getDownloadURL()
      .pipe(filter(image => !!image));
  }
}
