import {
  Component,
  OnInit,
} from '@angular/core';
import {
  BehaviorSubject,
  filter,
  Subject,
  takeUntil,
} from 'rxjs';
import { User } from '../../shared/models/user.model';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { UserService } from 'src/app/auth/user.service';
import { CrudHelper } from 'src/app/firebase/helpers/crudHelper';
import { IPod } from 'src/app/shared/models/item.models';
import { PodStore } from 'src/app/merfolk/pods/pod-store';
import { successInviteModalData } from 'src/app/merfolk/pods/pods/pods';
import { ProfileService } from '../profile.service';
@Component({
  selector: 'app-block-list',
  templateUrl: './block-list.component.html',
  styleUrls: ['./block-list.component.scss'],
})
export class BlockListComponent implements OnInit {

  loading: boolean;
  id: string = this.activeRouter.snapshot.params.id;
  currentUser;
  updatedUser;
  users;
  itemsPerPage = 10;
  start = 0;
  end = 10;
  currentPage = new BehaviorSubject<number>(0);
  countPages: number;
  updatedCountPages: number;
  previewUsers$ = new BehaviorSubject([]);
  blockListLength$ = new BehaviorSubject(0);
  isLastBtnDisabled = false;
  isNextBtnDisabled = false;
  isFirstBtnDisabled = true;
  isPrevBtnDisabled = true;
  users$ = new BehaviorSubject<User[]>([]);
  allUsers$ = new BehaviorSubject<User[]>([]);
  searchString = new BehaviorSubject<string>('');
  userPods = new BehaviorSubject<IPod[]>([]);
  blockListInfo: any[] = [];
  usersCount: number;

  successModalData = successInviteModalData;
  showSuccessModal = false;

  private destroy$ = new Subject<void>();

  constructor(
    public activeRouter: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private podStore: PodStore,
    private profileService: ProfileService
  ) {}

  ngOnInit() {
    this.loading = true;
    const currentRoute = this.router.url;
    const parts = currentRoute.split('/');
    const idIndex = parts.indexOf('profile') + 1;
    this.id = parts[idIndex];
    this.podStore.selectInviteModal().subscribe(isOpen => {
      this.showSuccessModal = isOpen;
    });
    this.getUser();
  }
  async ionViewDidEnter() {
    if (this.currentUser) await this.getCurrentPage();
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  searchUser($event) {
    this.searchString.next($event.target.value);
    this.blockListInfo = [];
    this.profileService.currentFollowingPage.next(0);
  }
  handleOk(e) {
    e ? this.podStore.updateInviteModal(false) : null;
  }

  handleCancel(e) {
    e ? this.podStore.updateInviteModal(false) : null;
  }

  private getUser() {
    this.userService.currentUser$
      .pipe(
        filter(user => !!user.uid),
        takeUntil(this.destroy$),
        switchMap(async user => {
          this.currentUser = user;
          await this.getCurrentPage();
        })
      )
      .subscribe(pods => this.userPods.next(pods as any));
  }

  async getCurrentPage() {
    this.profileService.currentFollowingPage.subscribe(page => {
      this.start = 1 + page * this.itemsPerPage;
      this.end = (page + 1) * this.itemsPerPage;

      if (this.searchString.value.length > 0) {
        this.getFollowingSearchedUsers();
      } else {
        this.getFollowingUsers();
      }

      if (this.end > this.usersCount && this.usersCount) {
        this.end = this.usersCount;
      }

      this.isFirstBtnDisabled = !!(this.start === 1);
      this.isPrevBtnDisabled = !!(this.start === 1);
      this.isNextBtnDisabled =
        !!(page === this.countPages - 1) || this.countPages === 0;
      this.isLastBtnDisabled =
        !!(page === this.countPages - 1) || this.countPages === 0;
    });
  }

  async getFollowingUsers() {
    let page = this.profileService.currentFollowingPage.value;
    this.loading = true;
    const functions = getFunctions();
    const getFollowingUsers = httpsCallable(functions, 'getBlockList');
    getFollowingUsers({
      id: this.id,
      page: page,
    })
      .then(res => {
        this.start = 1 + page * this.itemsPerPage;
        this.end = (page + 1) * this.itemsPerPage;

        this.blockListInfo.push(res);
        this.previewUsers$.next((res as any).data.data);
        this.blockListLength$.next((res as any).data.totalCount);

        this.countPages = Math.ceil(
          (res as any).data.totalCount / this.itemsPerPage
        );

        this.usersCount = (res as any).data.totalCount;
        this.usersCount = (res as any).data.totalCount;
        if (
          this.end > (res as any).data.totalCount &&
          (res as any).data.totalCount
        ) {
          this.end = (res as any).data.totalCount;
        }

        this.isFirstBtnDisabled = !!(this.start === 1);
        this.isPrevBtnDisabled = !!(this.start === 1);
        this.isNextBtnDisabled =
          !!(page === this.countPages - 1) || this.countPages === 0;
        this.isLastBtnDisabled =
          !!(page === this.countPages - 1) || this.countPages === 0;
      })
      .catch(error => console.log(error))
      .finally(() => {
        this.loading = false;
      });
  }

  getFollowingSearchedUsers() {
    this.loading = true;
    let page = this.profileService.currentFollowingPage.value;
    const functions = getFunctions();
    const followSearch = httpsCallable(functions, 'followSearch');
    followSearch({
      id: this.currentUser?.uid,
      page: this.profileService.currentFollowingPage.value,
      type: 'blockList',
      message: this.searchString.value,
    })
      .then(res => {
        this.blockListInfo.push(res);
        this.previewUsers$.next((res as any).data.data);
        this.blockListLength$.next((res as any).data.totalCount);

        this.countPages = Math.ceil(
          (res as any).data.totalCount / this.itemsPerPage
        );

        this.usersCount = (res as any).data.totalCount;
        if (
          this.end > (res as any).data.totalCount &&
          (res as any).data.totalCount
        ) {
          this.end = (res as any).data.totalCount;
        }

        this.isFirstBtnDisabled = !!(this.start === 1);
        this.isPrevBtnDisabled = !!(this.start === 1);
        this.isNextBtnDisabled =
          !!(page === this.countPages - 1) || this.countPages === 0;
        this.isLastBtnDisabled =
          !!(page === this.countPages - 1) || this.countPages === 0;
      })
      .catch(error => console.log(error))
      .finally(() => {
        this.loading = false;
      });
  }

  getNextPage(e) {
    this.profileService.currentFollowingPage.next(
      this.profileService.currentFollowingPage.value + 1
    );
  }

  getPrevPage() {
    this.profileService.currentFollowingPage.next(
      this.profileService.currentFollowingPage.value - 1
    );
  }

  getLastPage() {
    this.profileService.currentFollowingPage.next(this.countPages - 1);
  }

  getFirstPage() {
    this.profileService.currentFollowingPage.next(0);
  }

}
