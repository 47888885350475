import { Injectable } from '@angular/core';
import { SecondHandService } from './second-hand.service';
import { CommonComponentStore } from '../../shared/component-store/common-component-store';
import { SecondHandItem } from '../bazaar';

@Injectable({ providedIn: 'root' })
export class SecondHandStore extends CommonComponentStore<SecondHandItem> {
  constructor(secondHandService: SecondHandService) {
    super(secondHandService, {
      createdId: null,
      item: null,
      items: null,
      loading: false,
      showFormModal: false,
      showPendingModal: false,
      collectionName: 'secondHand',
      priceRange: '',
    });
  }

  selectShowFormModal() {
    // TODO update
    // @ts-ignore
    return this.select(state => state.showFormModal);
  }

  updateShowFormModal(value) {
    // TODO update
    // @ts-ignore
    this.patchState({ showFormModal: value });
  }

  selectShowPendingModal() {
    // TODO update
    // @ts-ignore
    return this.select(state => state.showPendingModal);
  }
  updatePriceRange(value) {
    // TODO update
    // @ts-ignore
    this.patchState({ priceRange: value });
  }
  selectPriceRange() {
    // TODO update
    // @ts-ignore
    return this.select(state => state.priceRange);
  }

  updateShowPendingModal(value) {
    // TODO update
    // @ts-ignore
    this.patchState({ showPendingModal: value });
  }
}
