import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  Subject,
  take,
  takeUntil,
} from 'rxjs';
import {
  FormService,
  linkRegex,
} from 'src/app/shared/components/form/form.service';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { showLoading } from 'src/app/shared/components/modals/loading';
import { JobsService } from '../jobs.service';
import { JobsStore } from '../jobs-store';
import { confirmAddingModalData, imageField } from '../jobs';
import { countries } from 'src/app/auth/sign-up/countries';
import { IonModal } from '@ionic/angular';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { environment } from '../../../../../environments/environment';
import mixpanel from 'mixpanel-browser';
import { UserService } from 'src/app/auth/user.service';
import { ImagesService } from 'src/app/services/images.service';
import { MapService } from 'src/app/services/map.service';
@Component({
  selector: 'app-add-job',
  templateUrl: './add-job.component.html',
  styleUrls: ['./add-job.component.scss'],
})
export class AddJobComponent implements OnInit, OnDestroy {
  @Output() isClose = new EventEmitter<boolean>(false);
  @ViewChild(IonModal) modal: IonModal;
  @ViewChild('map') mapRef;
  @ViewChild('imageLoader') imageLoader;
  @ViewChild('placesRef', { static: false }) placesRef: GooglePlaceDirective;
  environment = environment;
  map;
  isOpen = false;

  jobForm: FormGroup;
  showSuccessModal = false;
  newJobItem;
  disabled = true;
  addingBtnDisabled = true;
  imageField = imageField;
  typeOptions = ['part-time', 'full-time', 'per hour', 'gig based'];
  id: string;
  successModalData;

  imagesControls = 1;
  formIsReset = new BehaviorSubject(false);
  businessAccount = false;
  isOnline = false;
  status;
  currentImage: string;
  loadingImage: boolean = false;
  imagesArr: string[];
  urls;

  businessWebsiteURLValue = null;
  businessNameValue = null;

  loader = showLoading();
  user;
  isLocationReset = new BehaviorSubject<boolean>(false);
  countries = countries;
  item = { icon: 'pricetag', title: 'Offer your Services' };
  confirmModalData = confirmAddingModalData;
  showConfirmModal = false;
  properties;
  private destroy$ = new Subject<void>();

  constructor(
    private formService: FormService,
    private imagesService: ImagesService,
    private router: Router,
    private userService: UserService,
    private jobsService: JobsService,
    private jobsStore: JobsStore,
    private fb: FormBuilder,
    private mapService: MapService
  ) {}

  ngOnInit() {
    mixpanel.init(environment.mixpanelToken, { debug: true });

    this.jobForm = this.fb.group({
      images: this.fb.array([
        {
          0: this.fb.group({
            image: [null],
          }),
        },
      ]),
      title: ['', [Validators.required, Validators.minLength(7)]],
      type: ['', [Validators.required]],
      description: ['', [Validators.required, Validators.minLength(20)]],
      salary: this.fb.group({
        value: [null, [Validators.required]],
        currency: ['$'],
      }),
      location: this.fb.group({
        online: [false, [Validators.required]],
        country: [null],
        state: [null],
        googleMapLink: [null],
      }),

      account: ['my member account'],
    });

    // this.jobForm.get('location.country').valueChanges.subscribe(() => {
    //   this.jobForm.patchValue({
    //     location: {
    //       state: null,
    //       googleMapLink: null,
    //     },
    //   });
    //   this.formService.location$.next({ googleMapLink: null });
    // });

    this.listenStatusChanges();
    this.listenItemId();
    this.getUser();

    this.imagesService.imagesLoading
      .pipe(takeUntil(this.destroy$))
      .subscribe(loading => {
        this.loadingImage = loading;
        this.disabled = loading || this.jobForm.status === 'INVALID';
      });

    this.imagesService.compressedImages
      .pipe(takeUntil(this.destroy$))
      .subscribe(images => {
        this.imagesArr = images;
      });

    combineLatest([
      this.jobForm.valueChanges,
      this.imagesService.compressedImages,
      this.imagesService.imagesLoading,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([value, images, loading]) => {
        this.imagesArr = images;
        this.loadingImage = loading;
        this.isLocationReset.next(false);
        this.disabled =
          this.jobForm.status === 'INVALID' ||
          this.status === 'loading' ||
          this.loadingImage ||
          loading ||
          !this.imagesArr?.length;
      });

    this.jobForm
      .get('account')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value: string) => {
        if (value === 'a business account') {
          this.businessAccount = true;
          this.jobForm.addControl(
            'businessName',
            new FormControl(this.businessNameValue, [Validators.required])
          );
          this.jobForm.addControl(
            'businessWebsiteUrl',
            new FormControl(this.businessWebsiteURLValue, [
              Validators.required,
              Validators.pattern(linkRegex),
            ])
          );
        } else {
          this.businessAccount = false;
          this.jobForm.removeControl('businessName');
          this.jobForm.removeControl('businessWebsiteUrl');
        }
      });

    this.jobForm
      .get('location.online')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value: string) => {
        if (value) {
          this.isOnline = true;
          (this.jobForm.get('location') as FormGroup).removeControl(
            'googleMapLink'
          );
        } else {
          this.isOnline = false;
          (this.jobForm.get('location') as FormGroup).addControl(
            'googleMapLink',
            new FormControl(null)
          );
        }
      });

    this.jobsService.isOpenForm.subscribe(
      isOpen => (this.showSuccessModal = isOpen)
    );
  }

  saveBusinessName(e) {
    this.businessNameValue = e.target.value;
  }

  saveBusinessWebsiteURL(e) {
    this.businessWebsiteURLValue = e.target.value;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.businessWebsiteURLValue = null;
    this.businessNameValue = null;
    this.imagesService.reset();
  }

  trackEvent(event, parameter) {
    if (parameter === 'content-type') {
      this.properties = {
        'content-type': 'Service Offer',
      };

      mixpanel.track(event, this.properties);
    } else if (parameter === 'new event') {
      let myJobs = this.jobsService.getMyItems('jobs', this.user.uid);
      myJobs.pipe(take(1)).subscribe(data => {
        let jobsIdArray = [];
        data.forEach(pod => {
          jobsIdArray.push(pod.id);
        });
        mixpanel.people.set({ '$service-offers-id': [...jobsIdArray] });
      });
    }
  }

  getFormsControls(): FormArray {
    return <FormArray>this.jobForm.controls['images'];
  }

  close(e: boolean) {
    e ? (this.showConfirmModal = true) : null;
  }

  discardChanges(e: boolean) {
    if (e) {
      this.isClose.next(true);
      this.showConfirmModal = false;
    }
  }

  closeModal(e: boolean) {
    if (e) {
      this.showConfirmModal = false;
    }
  }

  addMapLink(e) {
    e.valueChanges.subscribe(async () => {
      const location = e.get('googleMapLink')?.value;
      const { country } = await this.mapService.getAdDress(location);

      let currentCountry = countries.find(item => item.name === country);

      if(location.length > 0) {
        this.jobForm.get('location.googleMapLink')?.patchValue(location);
      }

      this.jobForm.get('location.country').patchValue(currentCountry);
    });
  }

  async addItem() {
    this.jobsStore.setLoading();
    await this.imageLoader.upLoadImages();
    this.imagesService.imageUrls
      .pipe(
        filter(data => !!data),
        takeUntil(this.destroy$)
      )
      .subscribe(urls => {
        if (urls.length) {
          this.disabled = true;
          const itemImages = [...urls].filter(item => !!item);
          this.newJobItem = {
            ...this.jobForm.value,
            createdAt: new Date(),
            creator: {
              id: this.user.id,
            },
          };
          (this.newJobItem.location = this.newJobItem.location.online
            ? Object.assign({
                ...this.newJobItem.location,
                country: { region: 'online' },
              })
            : this.newJobItem.location),
            (this.newJobItem.images = itemImages);
          this.imagesService.imageUrls.next([]);
          this.jobsStore.createItem(this.newJobItem);
          this.trackEvent('Content Submitted', 'content-type');
          this.trackEvent('', 'new event');
        }
      });
  }

  listenItemId() {
    this.jobsStore
      .selectId()
      .pipe(
        filter(id => !!id),
        takeUntil(this.destroy$)
      )
      .subscribe(id => {
        this.isClose.next(true);
        this.jobsStore.updateShowFormModal(false);
        this.jobsService.isOpenForm.next(true);
        this.jobsStore.updateShowPendingModal(true);
        setTimeout(() => {
          this.jobsStore.patchState({ createdId: null });
          this.router.navigate(['/bazaar/job&services/jobs', id]);
        }, 0);
      });
  }

  private getUser() {
    this.userService.currentUser$
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user.uid)
      )
      .subscribe(user => {
        this.user = user;
        mixpanel.identify(user.uid);
      });
  }

  private listenStatusChanges() {
    this.jobsStore
      .selectLoading()
      .pipe(takeUntil(this.destroy$))
      .subscribe(loading => {
        loading
          ? this.loader.then(data => data.present())
          : this.loader.then(data => data.dismiss());
      });
  }
}
