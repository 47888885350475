import { LoadingController } from '@ionic/angular';

export const showLoading = async () => {
  const loadingCtrl = new LoadingController();
  const loading = await loadingCtrl.create({
    message: '',
    spinner: 'bubbles',
    cssClass: 'custom-loading',
  });
  return loading;
};

export const showDeleteLoading = async () => {
  const loadingCtrl = new LoadingController();
  const loading = await loadingCtrl.create({
    message: 'PLEASE, BE PATIENT, IT WILL TAKE A FEW SECONDS...',
    spinner: 'bubbles',
    cssClass: 'custom-delete-loading',
  });
  return loading;
};
