import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FormArray, FormBuilder } from '@angular/forms';
import { CrudHelper } from '../firebase/helpers/crudHelper';
import { AuthService } from '../firebase/auth.service';
import { CommonService } from '../shared/component-store/common-service';

@Injectable({ providedIn: 'root' })
export class PlanetService extends CommonService {
  isOpenForm = new BehaviorSubject(false);

  userId: string;

  constructor(
    private fb: FormBuilder,
    private firebaseHelperService: CrudHelper,
    private authService: AuthService
  ) {
    super(firebaseHelperService);
    this.getUser();
  }

  addImage(images) {
    let i = images.length;
    i = this.fb.group({
      image: [null],
    });
    images.push(i);
  }

  deleteImage(images: FormArray, i: number) {
    images.removeAt(i);
  }

  getProfile(email: string) {
    return this.firebaseHelperService.searchByField('users', 'email', email);
  }

  getItems({ collectionName, filter }) {
    const queryFn = ref => {
      let query = ref;

      const { status, creator, myContent } = filter;

      if (status) {
        query = query.where('status', '==', status);
      }

      if (creator && !myContent) {
        query = query.where('creator.id', '==', creator.id);
        query = query.where('status', 'in', [
          'approved',
          'pending',
          'rejected',
        ]);
      }

      if (!status && !creator && !myContent) {
        query = query.where('status', '==', 'approved');
      }

      query = query.orderBy('challengeDate', 'desc');

      return query;
    };

    return this.firebaseHelperService.getCollectionItems({
      collectionName,
      queryFn,
    });
  }

  checkFilter(filter) {
    const result = filter === false || !filter.myContent;
    return !result;
  }

  private getUser() {
    const { uid } = this.authService.isUserAuth();
    this.authService
      .getUserByUid(uid)

      .subscribe(user => (this.userId = user.id));
  }
}
