import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pending-approval-item',
  templateUrl: './pending-approval-item.component.html',
  styleUrls: ['./pending-approval-item.component.scss'],
})
export class PendingApprovalItemComponent {
  @Input() items;
  @Input() divider: { title: string; icon: string };
  @Input() collectionName: string;

  currentTime: Date = new Date();

  constructor(private router: Router) {}
  navigate(route) {
    this.router.navigate(route);
  }

  getDaysDiff(item) {
    const timeDiff = Math.abs(
      +this.currentTime / 1000 - item.createdAt?.seconds
    );
    return Math.ceil(timeDiff / (1000 * 3600 * 24));
  }

  getShortDate(item) {
    return item ? item.substr(0, 10) : '';
  }
}
