import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { AuthService } from '../auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  environment = environment;
  loginForm: FormGroup;
  type = 'password';
  iconName = 'eye';

  private errorLoginMsg = new BehaviorSubject<string | null>('');
  private loadSpinner = new BehaviorSubject(false);
  loadSpinner$ = this.loadSpinner.asObservable();

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private toastController: ToastController
  ) {
    
  }

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, Validators.minLength(6)]],
      rememberMe: [false],
    });
  }

  login() {
    this.loadSpinner.next(true);
    this.errorLoginMsg.next(null);
    this.loginForm.markAsPristine();
    this.authService
      .signIn(this.loginForm.value)
      .then(() => {
        this.router.navigateByUrl('/mood', { replaceUrl: true });
      })
      .catch(error => {
        if (error === 'auth/network-request-failed') {
          this.createToaster('Internal server error');
        } else {
          this.createToaster(
            `Invalid credentials. New here? <span class='message-span'>Sign up</span> now!`
          );
        }
      })
      .finally(() => {
        this.loadSpinner.next(false);
      });
  }

  changeType() {
    this.type = this.type === 'password' ? 'text' : 'password';
    this.iconName = this.iconName === 'eye' ? 'eye-off' : 'eye';
  }

  private createToaster(message: string) {
    this.toastController
      .create({
        message,
        color: 'danger',
        duration: 5000,
        position: 'top',
      })
      .then(t => {
        (
          t.shadowRoot?.querySelector('.message-span') as HTMLElement
        ).style.cursor = 'pointer';
        (
          t.shadowRoot?.querySelector('.message-span') as HTMLElement
        ).style.textDecoration = 'underLine';
        (
          t.shadowRoot?.querySelector('.message-span') as HTMLElement
        ).addEventListener('click', () => {
          this.router.navigateByUrl('/auth/sign-up');
          t.dismiss();
        });
        t.present();
      });
  }
}
