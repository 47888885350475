import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'slicer',
})
export class SlicerPipe implements PipeTransform {
  transform(
    value: any[],
    more: boolean,
    count: number,
    position: string
  ): unknown {
    const newValue = value;

    if (more) {
      return position === 'start'
        ? newValue.slice(count)
        : newValue.slice(0, count);
    }
    return newValue;
  }
}
