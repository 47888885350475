import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  Subject,
  combineLatest,
  BehaviorSubject,
  takeUntil,
  filter,
} from 'rxjs';
import {
  IEventFilter,
  eventDiscordModalData,
  eventPremiumModalData,
} from './events';
import { EventsService } from './events.service';
import { FormService } from '../shared/components/form/form.service';
import { EventsStore } from './events-store';
import { showLoading } from '../shared/components/modals/loading';
import { map } from 'rxjs/operators';
import mixpanel from 'mixpanel-browser';
import { environment } from '../../environments/environment';
import { IPod } from '../shared/models/item.models';
import { Platform } from '@ionic/angular';
import { DateService } from '../services/date.service';
import { Timestamp } from 'firebase/firestore';
import { UserService } from '../auth/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
})
export class EventsComponent implements OnInit, OnDestroy {
  searchString: BehaviorSubject<string> = new BehaviorSubject<string>('');
  environment = environment;
  status;
  loader = showLoading();
  loading: boolean;
  myContent: boolean = false;
  country: string | null = null;
  currentUserID;
  currentUser;
  isAdmin: boolean;
  creator;

  isOpenFormModal = false;
  items;
  startDate: number;
  endDate: number;
  color = 'dark';
  isPremium: boolean;
  isOpenDiscordModal = false;
  premiumModalData = eventPremiumModalData;
  eventDiscordModalData = eventDiscordModalData;
  isUserPremiumModalOpen = false;
  infoSnapshot;
  userInfo;
  properties;
  allEvents;
  isMobile = this.platform.platforms().includes('capacitor');
  isIosApp = this.platform.platforms().includes('ios') && this.isMobile;
  isUserHasSuspendedContent: any;

  usersPods = new BehaviorSubject<IPod[]>([]);
  private destroy$ = new Subject<void>();

  constructor(
    private eventsStore: EventsStore,
    private eventsService: EventsService,
    private dateService: DateService,
    private formService: FormService,
    private platform: Platform,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit() {
    mixpanel.init(environment.mixpanelToken, { debug: true });

    this.getUser();
    this.listenItemsChanges();
    this.listenStatusChanges();
    this.listenFilter();
    this.eventsStore.selectShowFormModal().subscribe(isOpen => {
      this.isOpenFormModal = isOpen;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.formService.eventMenuData$.next(false);
    this.formService.formInitialValues.next({ myContent: null });
  }

  trackEvent(event, parameter, parameterValue, eventValue) {
    if (parameter === 'screen-name') {
      this.properties = {
        'screen-name': parameterValue,
        'event title': eventValue?.title,
        'event id': eventValue?.id,
      };
    }

    mixpanel.track(event, this.properties);
  }

  openModal() {
    if (this.currentUser.discordLink) {
      if (!this.isPremium && !this.isAdmin) {
        this.isUserPremiumModalOpen = true;
      } else {
        this.eventsStore.updateShowFormModal(true);
      }
    } else if (this.isAdmin) {
      this.eventsStore.updateShowFormModal(true);
    } else {
      this.isOpenDiscordModal = true;
    }
  }

  closeModal(e) {
    e ? this.eventsStore.updateShowFormModal(false) : null;
  }

  onSearchChange(searchString: string) {
    this.searchString.next(searchString);
  }

  private listenFilter() {
    this.formService.eventMenuData$.subscribe((data: IEventFilter) => {
      if (data) {
        this.startDate = Date.parse(data.startDate);
        this.endDate = Date.parse(data.endDate);
      } else {
        this.startDate = null;
        this.endDate = null;
      }
      this.color = this.eventsService.checkFilter(data);

      this.myContent = (data as IEventFilter).myContent;
      this.eventsStore.getItems(data);
    });
  }

  private listenStatusChanges() {
    this.eventsStore
      .selectLoading()
      .pipe(takeUntil(this.destroy$))
      .subscribe(loading => {
        loading
          ? this.loader.then(data => {
              this.loading = true;
              data.present();
            })
          : this.loader.then(data => {
              this.loading = false;
              data.dismiss();
            });
      });
  }

  private listenItemsChanges() {
    combineLatest([
      this.eventsStore.selectItems(),
      this.searchString,
      this.usersPods,
    ])
      .pipe(
        takeUntil(this.destroy$),
        filter(items => !!items),
        map(([items, searchString, pods]) => {
          return (
            items &&
            items.filter(
              item =>
                item.title.toLowerCase().includes(searchString.toLowerCase()) &&
                this.eventsService.podMemberCheck(
                  item,
                  pods,
                  this.currentUserID
                )
            )
          );
        })
      )
      .subscribe(async items => {
        if (!this.myContent) {
          this.allEvents = items
            ?.slice()
            .filter(item => !this.currentUser?.blockList?.includes(item?.creator?.id))
            .filter(
              item => item.suspended === false || item?.suspended === undefined
            );
        } else {
          this.allEvents = items?.slice();
        }

        this.items = this.dateService
          .getScheduledItems(this.allEvents, this.myContent)
          ?.reverse();
        this.isUserHasSuspendedContent = items
          ? items.find(
              item =>
                item.creator.id === this.currentUserID &&
                item.suspended &&
                item.status === 'approved'
            )
          : false;
      });
  }

  private getUser() {
    this.userService.currentUser$
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user.uid)
      )
      .subscribe(user => {
        this.currentUser = user;
        this.currentUserID = user.uid;
        this.isPremium = this.userService.isPremium;
        this.isAdmin = this.userService.isAdmin;
        this.userInfo = this.userService.balanceInfo;
        this.eventsService
          .getPodsWithEvents(user.uid)
          .subscribe((items: any) => this.usersPods.next(items));
        mixpanel.identify(user.uid);
      });
  }
  checkDate(startDate: Timestamp, endDate: Timestamp) {
    return this.dateService.checkSameDate(startDate, endDate);
  }
}
