import { Injectable } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { CrudHelper } from '../../../firebase/helpers/crudHelper';
import { CommonService } from '../../../shared/component-store/common-service';
import { AuthService } from 'src/app/firebase/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ServicesService extends CommonService {
  isOpenForm = new BehaviorSubject(false);
  userId;

  constructor(
    private fb: FormBuilder,
    private firebaseHelperService: CrudHelper,
    private authService: AuthService
  ) {
    super(firebaseHelperService);
  }

  addImage(images) {
    let i = images.length;
    i = this.fb.group({
      image: [null],
    });
    images.push(i);
  }

  deleteImage(images: FormArray, i: number) {
    images.removeAt(i);
  }

  getProfile(email: string) {
    return this.firebaseHelperService.searchByField('users', 'email', email);
  }

  getItems({ collectionName, filter }) {
    const queryFn = ref => {
      let query = ref;

      const { location, type, creator, status, myContent } = filter;

      if (type && type.length > 0) {
        query = query.where('type', 'in', type);
      }

      if (location && location.length) {
        query = query.where('location.country.region', 'in', location);
      }

      if (creator || myContent) {
        const user = creator || this.authService.isUserAuth().uid;
        query = query.where('creator.id', '==', user);
        query = query.where('status', 'in', [
          'approved',
          'pending',
          'rejected',
          'pending-repeat',
        ]);
      }

      if (status) {
        query = query.where('status', '==', status);
      }

      if (!status && !creator && !myContent) {
        query = query.where('status', '==', 'approved');
      }
      query = query.orderBy('createdAt', 'desc');

      return query;
    };

    return this.firebaseHelperService.getCollectionItems({
      collectionName,
      queryFn,
    });
  }

  getMyItems(collectionName, creator) {
    const queryFn = ref => {
      let query = ref;

      query = query.where('creator.id', '==', creator);

      return query;
    };

    return this.firebaseHelperService.getCollectionItems({
      collectionName,
      queryFn,
    });
  }

  checkFilter(filter) {
    const result =
      filter === false ||
      ((filter.type === null || !filter.type?.length) &&
        !filter.endDate &&
        !filter.startDate &&
        (filter.location === null || !filter.location?.length) &&
        !filter.myContent);
    return !result;
  }

  formatStartDate(date) {
    if (
      date?.completionDate.startDate?.seconds &&
      date?.completionDate.startDate?.seconds !== 0
    )
      return new Date(
        date?.completionDate.startDate?.seconds * 1000
      ).toISOString();

    if (
      date?.completionDate.startDate &&
      !date?.completionDate.startDate?.seconds && date?.completionDate.startDate?.seconds !== 0
    )
      return new Date(date?.completionDate.startDate).toISOString();
    return null;
  }

  formatEndDate(date) {
    if (date?.completionDate.endDate?.seconds)
      return new Date(
        date?.completionDate.endDate?.seconds * 1000
      ).toISOString();
    if (date?.completionDate.endDate && !date?.completionDate.endDate?.seconds)
      return new Date(date?.completionDate.endDate).toISOString();
    return null;
  }
}
