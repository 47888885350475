import { Injectable } from '@angular/core';
import { MapGeocoder } from '@angular/google-maps';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { countries } from '../auth/sign-up/countries';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  modalIsOpen = new BehaviorSubject(false);
  countries = countries;

  constructor(private geocoder: MapGeocoder) {}

  getCoordinatesByUrl(googleMapLink: string) {
    const coordinatesStr = googleMapLink.split('&query=')[1];
    const [lat, lng] = coordinatesStr.split(',');

    return {
      lat: +lat,
      lng: +lng,
    };
  }

  getAddressPosition(address: any) {
    return this.geocoder
      .geocode({
        address: `${address.country}, ${address.city}, ${address.address}`,
      })
      .pipe(
        map(position => {
          console.log(
            position,
            position?.results[0]?.geometry.location.toJSON()
          );
          return position?.results[0]?.geometry.location.toJSON();
        })
      );
  }

  createCoordinateDeviation = coordinate => {
    return coordinate + Math.floor(Math.random() * 10000) / 1000000;
  };

  createCoordinates(googleMapLink) {
    if (!googleMapLink) return;
    const lng = +googleMapLink?.slice(googleMapLink.lastIndexOf(',') + 1);
    const lat = +googleMapLink?.slice(
      googleMapLink.lastIndexOf('=') + 1,
      googleMapLink.lastIndexOf(',')
    );
    return { lng, lat };
  }

  async getAdDress(googleMapLink) {
    if (googleMapLink) {
      const latLng = this.createCoordinates(googleMapLink);
      const geocoder = new google.maps.Geocoder();
      const address = await geocoder.geocode({
        location: latLng,
        language: 'en-GB',
      });

      const country = address.results[1]?.address_components
        .filter(item => item.types.includes('country'))
        .map(
          item =>
            countries.find(country => country.code === item.short_name).name
        )[0];

      const city = address.results[1]?.address_components.find(
        item =>
          item.types.includes('administrative_area_level_5') ||
          item.types.includes('administrative_area_level_4') ||
          item.types.includes('administrative_area_level_3') ||
          item.types.includes('administrative_area_level_2') ||
          item.types.includes('administrative_area_level_1') ||
          item.types.includes('locality')
      )?.long_name;

      const postalCode = address.results[1]?.address_components.find(item =>
        item.types.includes('postal_code')
      )?.long_name;

      return { country, city, postalCode };
    } else
      return {
        country: '',
        city: '',
        postalCode: '',
      };
  }
}
