import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter, Subject, takeUntil } from 'rxjs';
import { IToolbarData } from 'src/app/shared/models/components-data.models';
import { ItemStatus } from 'src/app/shared/models/item.models';
import { Navigation, SwiperOptions } from 'swiper';
import { successSecondModalData } from '../second-hand';
import { SecondHandStore } from '../second-hand-store';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/auth/user.service';
import { IUser } from 'src/app/auth/auth.models';
import { getFunctions, httpsCallable } from 'firebase/functions';

const TOOLBAR_DATA: IToolbarData = {
  arrowRouter: '/bazaar/second-hand',
  btnSlot: 'end',
  title: 'Second Hand',
};

const TOOLBAR_SWIPER: IToolbarData = {
  arrow: true,
  btnSlot: 'end',
  title: 'Second Hand',
};

@Component({
  selector: 'app-second-hand-details',
  templateUrl: './second-hand-details.component.html',
  styleUrls: ['./second-hand-details.component.scss'],
})
export class SecondHandDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('col') col: ElementRef;
  environment = environment;
  TOOLBAR_DATA = TOOLBAR_DATA;
  TOOLBAR_SWIPER = TOOLBAR_SWIPER;
  config: SwiperOptions;

  secondHandItem;
  galleryUrls: string[];

  currentUserID;
  creator;
  currentUser: IUser;
  isAdmin: boolean;

  itemImage: string;
  status: ItemStatus;

  showStatusModal: boolean = false;
  itemStatus;

  flagData
  isContentFlaggedModal
  isOpenFlagModal

  isOpen = false;
  showSuccessModal = false;
  successModalData;
  isOpenFormModal = false;

  discordServerInviteLink = environment.discordServerInviteLink;
  itemId = this.router.snapshot.params.id;
  private destroy$ = new Subject<void>();

  constructor(
    private secondHandStore: SecondHandStore,
    private router: ActivatedRoute,
    private userService: UserService
  ) {
    
  }

  ngOnInit() {
    this.getUser();
    const app: HTMLElement = document.querySelector('ion-router-outlet');
    app.style.marginBottom = '0px';

    this.secondHandStore.getItemByByValue({
      documentId: this.router.snapshot.params.id,
    });

    this.listenSecondHandDetails();

    this.secondHandStore.selectShowFormModal().subscribe(isOpen => {
      this.isOpenFormModal = isOpen;
    });

    this.secondHandStore.selectShowPendingModal().subscribe(isOpen => {
      this.showSuccessModal = isOpen;
    });
    this.successModalData = successSecondModalData;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  handleOk(e) {
    e ? this.secondHandStore.updateShowPendingModal(false) : null;
  }

  handleCancel(e) {
    e ? this.secondHandStore.updateShowPendingModal(false) : null;
  }

  openModal() {
    this.secondHandStore.updateShowFormModal(true);
  }

  closeModal(e) {
    e ? this.secondHandStore.updateShowFormModal(false) : null;
  }

  toggleGallery(i?) {
    this.isOpen = !this.isOpen;
    this.config = {
      modules: [Navigation],
      loop: true,
      navigation: true,
      initialSlide: i,
    };
  }

  async flagContent(slide) {
    slide.type = 'secondHand';

    this.flagData = slide;

    const functions = getFunctions();
    const isContentFlagged = httpsCallable(functions, 'isContentFlagged');
    await isContentFlagged({
      itemId: slide.id,
      url: slide.images[0],
      type: this.flagData.type
    }).then(res => {
      if(res.data === true) {
        this.isContentFlaggedModal = true;
      } else {
        this.isOpenFlagModal = true;
      }
    });
  }

  openStatusModal(status?) {
    this.showStatusModal = !this.showStatusModal;
    if (status) this.itemStatus = status;
  }

  private listenSecondHandDetails() {
    this.secondHandStore
      .selectItem()
      .pipe(
        takeUntil(this.destroy$),
        filter(item => !!item),
        switchMap(item => {
          this.secondHandItem = item;
          this.itemImage = item.images[0];
          return this.userService.getUserByUid(item.creator.id);
        }),
        filter(user => !!user)
      )
      .subscribe(user => {
        this.creator = user;
        this.galleryUrls = this.secondHandItem.images.map(
          pic => `url("${pic}") no-repeat center center / cover`
        );
      });
  }

  private getUser() {
    this.userService.currentUser$
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user.uid)
      )
      .subscribe(user => {
        this.currentUser = user;
        this.currentUserID = user.uid;
        this.isAdmin = this.userService.isAdmin;
      });
  }
}
