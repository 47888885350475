import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { combineLatest, filter, Subject, takeUntil } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Navigation, SwiperOptions } from 'swiper';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../firebase/auth.service';
import { ItemStatus } from '../../shared/models/item.models';
import { IToolbarData } from '../../shared/models/components-data.models';
import { EventsStore } from '../events-store';
import { EventItem, successEventModalData } from '../events';
import { PodStore } from 'src/app/merfolk/pods/pod-store';
import mixpanel from 'mixpanel-browser';
import { environment } from '../../../environments/environment';
import { UserService } from 'src/app/auth/user.service';
import {ReactionsService} from "../../services/reactions.service";
import { getFunctions, httpsCallable } from 'firebase/functions';

const TOOLBAR_DATA: IToolbarData = {
  arrowRouter: '/events',
  btnSlot: 'end',
  title: 'Event',
};

const TOOLBAR_SWIPER: IToolbarData = {
  arrow: true,
  btnSlot: 'end',
  title: 'Events',
};

@Component({
  selector: 'app-events-details',
  templateUrl: './events-details.component.html',
  styleUrls: ['./events-details.component.scss'],
})
export class EventsDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('col') col: ElementRef;
  
  environment = environment;
  TOOLBAR_DATA = TOOLBAR_DATA;
  TOOLBAR_SWIPER = TOOLBAR_SWIPER;
  config: SwiperOptions;

  eventItem;
  podItem;
  galleryUrls: string[];
  currentUser;
  currentUserID;
  creator;
  eventCreator;
  status: ItemStatus;
  isEventFree: boolean = false;
  isEventPaid: boolean = false;
  event: EventItem;
  isOpen = false;
  showSuccessModal = false;
  successModalData;
  isOpenFormModal = false;
  isEventJoined: boolean = false;
  isCanJoin: boolean = false;
  infoSnapshot;
  userInfo;
  showStatusModal: boolean = false;
  itemStatus;
  properties;
  isAdmin;
  flagData
  isContentFlaggedModal
  isOpenFlagModal

  discordServerInviteLink = environment.discordServerInviteLink;

  contactLink: string;

  likesInfo;
  loadingLikeButton;
  itemId = this.activatedRoute.snapshot.params.id;

  private destroy$ = new Subject<void>();

  constructor(
    private eventStore: EventsStore,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private podStore: PodStore,
    private userService: UserService,
    private reactionsService: ReactionsService,
  ) {
    
  }

  ngOnInit() {
    mixpanel.init(environment.mixpanelToken, { debug: true });
    const { uid } = this.authService.isUserAuth();
    mixpanel.identify(uid);
    this.getUser();
    this.eventStore.getItemByByValue({
      documentId: this.activatedRoute.snapshot.params.id,
    });

    this.listenSecondHandDetails();
    this.eventStore.selectShowFormModal().subscribe(isOpen => {
      this.isOpenFormModal = isOpen;
    });
    this.eventStore.selectShowPendingModal().subscribe(isOpen => {
      this.showSuccessModal = isOpen;
    });
    this.successModalData = successEventModalData;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.eventItem = null;
    this.podItem = null;
    this.isCanJoin = false;
    this.isEventJoined = false;
    if (this.infoSnapshot) {
      this.infoSnapshot.unsubscribe();
    }
  }

  trackEvent(event, eventValue, creator) {
    this.properties = {
      type: eventValue?.eventType,
      organizer: [creator?.username, eventValue?.creator],
    };

    mixpanel.track(event, this.properties);
  }

  toggleGallery(i?) {
    this.isOpen = !this.isOpen;
    this.config = {
      modules: [Navigation],
      loop: true,
      navigation: true,
      initialSlide: i,
    };
  }

  openModal() {
    this.eventStore.updateShowFormModal(true);
  }

  joinEvent() {
    const newEvent = Object.assign({
      ...this.eventItem,
      participants: this.eventItem?.participants?.length
        ? [
            ...this.eventItem?.participants,
            {
              id: this.currentUser?.id,
              profilePicture: this.currentUser?.profilePicture,
              username: this.currentUser?.username,
            },
          ]
        : [
            {
              id: this.currentUser?.id,
              profilePicture: this.currentUser?.profilePicture,
              username: this.currentUser?.username,
            },
          ],
    });

    this.eventStore.updateProfile(newEvent);
    this.trackEvent('Joined Event', newEvent, this.creator);
  }

  async flagContent(slide) {
    slide.type = 'events';

    this.flagData = slide;

    const functions = getFunctions();
    const isContentFlagged = httpsCallable(functions, 'isContentFlagged');
    await isContentFlagged({
      itemId: slide.id,
      url: slide.images[0],
      type: this.flagData.type
    }).then(res => {
      if(res.data === true) {
        this.isContentFlaggedModal = true;
      } else {
        this.isOpenFlagModal = true;
      }
    });
  }

  closeModal(e) {
    e ? this.eventStore.updateShowFormModal(false) : null;
  }

  handleOk(e) {
    e ? this.eventStore.updateShowPendingModal(false) : null;
  }

  handleCancel(e) {
    e ? this.eventStore.updateShowPendingModal(false) : null;
  }

  openStatusModal(status?) {
    this.showStatusModal = !this.showStatusModal;
    if (status) this.itemStatus = status;
  }

  navigateToMeetingLink() {
    window.location.href = this.eventItem?.meetingLink;
  }

  private listenSecondHandDetails() {
    this.eventStore
      .selectItem()
      .pipe(
        takeUntil(this.destroy$),
        filter(item => !!item),
        switchMap(item => {
          this.eventItem = item;
          this.isEventJoined = this.eventItem.participants?.some(
            element => element.id === this.currentUser?.uid
          );

          if (item.podName) {
            this.podStore.getItems({});
            this.listenPodDetails(item.podName);
          } else {
            this.isCanJoin =
              this.eventItem?.participants?.find(
                element => element.id !== this.currentUser?.uid
              ) && !this.isEventJoined;
          }

          if (Object.keys(this.eventItem.price)?.length > 1) {
            this.isEventFree = false;
            this.isEventPaid = true;
          } else {
            this.isEventPaid = false;
            this.isEventFree = true;
          }
          return this.authService.getUserByUid(item.creator.id);
        })
      )

      .subscribe(user => {
        this.creator = user;
        this.contactLink =
          this.eventItem?.eventType === 'Externally hosted & paid'
            ? `https://${this.eventItem?.externalEventLink}`
            : this.creator?.discordLink;
        this.galleryUrls = this.eventItem?.images.map(
          pic => `url("${pic}") no-repeat center center / cover`
        );
      });
  }

  private listenPodDetails(id) {
    combineLatest([this.podStore.selectItems()])
      .pipe(
        takeUntil(this.destroy$),
        map(([items]) => {
          return items && items.find(item => item.id === id);
        })
      )
      .subscribe(item => {
        this.podItem = item;
        if (this.eventItem.onlyPodMembers) {
          this.isCanJoin =
            item?.participants.includes(this.currentUser?.uid) &&
            !this.isEventJoined;
        } else {
          this.isCanJoin = !this.eventItem.participants.some(
            participant => participant.id === this.currentUser?.uid
          );
        }
      });
  }

  private getUser() {
    this.userService.currentUser$
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user.uid)
      )
      .subscribe(user => {
        this.currentUser = user;
        this.currentUserID = user.uid;
        this.isAdmin = this.userService.isAdmin;
        this.userInfo = this.userService.balanceInfo;
        mixpanel.identify(user.uid);
      });
  }
}
