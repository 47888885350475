export enum FormFieldTypes {
  TEXT_INPUT = 'TEXT_INPUT',
  TEXT_AREA = 'TEXT_AREA',
  RADIO = 'RADIO',
  CHECKBOX = 'CHECKBOX',
  DATE = 'DATE',
  TOGGLE = 'TOGGLE',
  SELECT = 'SELECT',
  GROUP = 'GROUP',
  ARRAY = 'ARRAY',
  FILE = 'FILE',
}

export interface FormField {
  id?: string;
  name: string;
  type: FormFieldTypes;
  label?: string;
  required?: boolean;
  line?: boolean | string;
  multiple?: boolean;
  disabled?: boolean;
  options?: string[] | { label: string; name: string; icon?: string }[];
  children?: FormField[];
  defaultValue?: string | boolean;
  icon?: string;
  addBtn?: string;
  template?: FormField;
  if?: string;
  value?: string;
  modal?: string;
}
