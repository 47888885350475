import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  HTTP_INTERCEPTORS,
  HttpClientJsonpModule,
  HttpClientModule,
} from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { MainMenuPage } from './main-menu/main-menu.page';
import { ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { AuthModule } from './auth/auth.module';
import { ProfileModule } from './profile/profile.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { TutorialModule } from './tutorial/tutorial.module';
import { AngularFireModule } from '@angular/fire/compat';
import { RouteReuseStrategy } from '@angular/router';
import { SharedModule } from './shared/shared.module';
import { FlagPipe } from './pipes/flag-pipe';
import { PipesModule } from './pipes/pipes.module';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { EventsModule } from './events/events.module';
import { PlanetModule } from './planet/planet.module';
import { PaymentsStore } from './payments/payments-store';
import { PaymentsService } from './payments/payments.service';
import { PoolsService } from './merfolk/pools/pools.service';
import { PoolsStore } from './merfolk/pools/pools.store';
import { PodService } from './merfolk/pods/pods.service';
import { PodStore } from './merfolk/pods/pod-store';
import { AdsenseModule } from 'ng2-adsense';
import { AdminPageModule } from './admin-page/admin-page.module';
import { NgxImageCompressService } from 'ngx-image-compress';

export const tokenGetter = () => localStorage.getItem('accessToken');

@NgModule({
  declarations: [AppComponent, MainMenuPage],
  imports: [
    AdsenseModule.forRoot({
      adClient: 'ca-pub-2609549176457465',
    }),
    BrowserModule,
    NgxPaginationModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
    ReactiveFormsModule,
    PipesModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
        allowedDomains: ['localhost'],
      },
    }),
    StoreModule.forRoot({}),
    EffectsModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    AuthModule,
    ProfileModule,
    EventsModule,
    PlanetModule,
    TutorialModule,
    SharedModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireStorageModule,
    GooglePlaceModule,
    AdminPageModule,
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    FlagPipe,
    PaymentsStore,
    PaymentsService,
    PoolsStore,
    PoolsService,
    PodStore,
    PodService,
    NgxImageCompressService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
