import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-lets-go-premium-modal',
  templateUrl: './lets-go-premium-modal.component.html',
  styleUrls: ['./lets-go-premium-modal.component.scss'],
})
export class LetsGoPremiumModalComponent {
  @Input() premiumModalData;
  @Input() isUserPremiumModalOpen;
  @Output() isUserPremiumModalOpenChange = new EventEmitter<any>();
  @Output() ok: EventEmitter<boolean> = new EventEmitter();
  environment = environment;
  constructor(private router: Router) {}

  handleOk() {
    
    this.isUserPremiumModalOpenChange.next(false);
  }

  navigateToPayments() {
    this.isUserPremiumModalOpenChange.next(false);
    setTimeout(() => {
      this.router.navigate(['/payments/subscription']);
    }, 0);
  }
}
