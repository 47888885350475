import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort',
})
export class SortPipe implements PipeTransform {
  transform(value: unknown[], field: string, order: string): unknown {
    if (!value || order === '' || !order || field === '' || !field) {
      return value;
    }
    if (value.length <= 1) {
      return value;
    }
    if (field === 'completionDate.startDate') {
      const result = value.sort((a: any, b: any) => {
        if (
          a.completionDate.startDate.seconds <
          b.completionDate.startDate.seconds
        )
          return -1;
        if (
          a.completionDate.startDate.seconds >
          b.completionDate.startDate.seconds
        )
          return 1;
        if (
          a.completionDate.startDate.seconds ===
          b.completionDate.startDate.seconds
        )
          return 0;
      });
      return order === 'asc' ? result : result.reverse();
    }

    if (
      field === 'startDate' ||
      field === 'createdAt' ||
      field === 'completionDate.startDate'
    ) {
      const result = value.sort((a: any, b: any) => {
        if (a[field].seconds) {
          if (a[field].seconds < b[field].seconds) return -1;
          if (a[field].seconds > b[field].seconds) return 1;
          if (a[field].seconds === b[field].seconds) return 0;
        }
        if (a[field]._seconds) {
          if (a[field]._seconds < b[field]._seconds) return -1;
          if (a[field]._seconds > b[field]._seconds) return 1;
          if (a[field]._seconds === b[field]._seconds) return 0;
        }
      });
      return order === 'asc' ? result : result.reverse();
    }

    const result = value.sort((a, b) => {
      if (a[field].toLowerCase() < b[field].toLowerCase()) return -1;
      if (a[field].toLowerCase() > b[field].toLowerCase()) return 1;
      if (a[field].toLowerCase() === b[field].toLowerCase()) return 0;
    });

    return order === 'asc' ? result : result.reverse();
  }
}
