// @ts-nocheck
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationsService {
  userProperties$ = new BehaviorSubject<string[]>([]);
  constructor() {}

  initWonderPush(uid: string) {
    window.WonderPush = window.WonderPush || [];

    WonderPush.push([
      'init',
      {
        webKey:
          environment.wonderPushWebKey,
          userId: uid,
        // optInOptions: {
        //   externalBoxSuccessMessage: null,
        // },
      },
    ]);
    WonderPush.push(['setUserConsent', true]).then(() => this.subscribe(uid));
  }

  subscribe(uid: string) {
    WonderPush.push(['setUserId', uid]);
    WonderPush.subscribeToNotifications();
    this.getUserProperties(WonderPush, 'web');
  }

  unsubscribe() {
    WonderPush.unsubscribeFromNotifications();
    WonderPush.push(['unsetUserId']);
  }

  removeUserData() {
    WonderPush.unsubscribeFromNotifications();
    WonderPush.push(['clearAllData']);
    WonderPush.push(['unsetUserId']);
  }

  updateUsersProperties(props) {
    let userProperties = this.userProperties$.value;
    if (userProperties.includes(props.title)) {
      userProperties = userProperties.filter(item => item !== props.title);
    } else {
      userProperties.push(props.title);
    }
    this.userProperties$.next(userProperties);
    WonderPush.push([
      'putProperties',
      {
        string_merfolkTopics: userProperties,
      },
    ]);
  }

  getUserProperties(wonderPush, platform) {
    //!  settimeout is chosen because the push method don't see 'this'
    setTimeout(() => {
      wonderPush
        .getPropertyValues('string_merfolkTopics')
        .then(props => {
          this.userProperties$.next(props);
          return props;
        })
        .then(data => {
          const defaultProperties = [
            'test',
            'merfolk',
            'events',
            'likes',
            'comments',
            'daily_challenges',
          ];
          if (!data.length && platform !== 'capacitor') {
            wonderPush.push([
              'putProperties',
              {
                string_merfolkTopics: defaultProperties,
              },
            ]);
            this.getUserProperties(wonderPush, 'web');
          } else if (!data.length && platform === 'capacitor') {
            wonderPush.putProperties({
              string_merfolkTopics: defaultProperties,
            });
            this.getUserProperties(wonderPush, 'capacitor');
          }
        });
    }, 0);
  }
}
