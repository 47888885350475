import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { IonicModule } from '@ionic/angular';

@Component({
  standalone: true,
  selector: 'app-email-sent',
  templateUrl: './email-sent.component.html',
  styleUrls: ['./email-sent.component.scss'],
  imports: [IonicModule],
})
export class EmailSentComponent {
  constructor(private route: Router) {}

  navigateClick() {
    this.route.navigate(['/']);
  }
}
