import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { INotifyData } from 'src/app/profile/notification-settings/notifications-settings-data';
import { ProfileStore } from 'src/app/profile/store/profile-store';

@Component({
  selector: 'app-item-settings-notifications',
  templateUrl: './item-settings-notifications.component.html',
  styleUrls: ['./item-settings-notifications.component.scss'],
})
export class ItemSettingsNotificationsComponent {
  @Input() data;
  @Input() pushNotifications;
  @Input() mailNotifications;
  @Output() clickHandler = new EventEmitter<INotifyData>(null);
  constructor() {}

  select(title, type) {
    this.clickHandler.next({ title: this.titleTransform(title), type });
  }
  titleTransform(title) {
    return title
      .replace(',', '')
      .replace('&', '')
      .split(' ')
      .filter(item => item.length)
      .map(item => item.toLowerCase())
      .join('_');
  }
}
