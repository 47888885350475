import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent {
  @Input() currentPage: number;
  @Input() countPages: number;
  @Input() disabledPrev: boolean;
  @Input() disabledNext: boolean;
  @Output() clickNext = new EventEmitter<boolean>(false);
  @Output() clickPrev = new EventEmitter<boolean>(false);
  @Output() clickFirst = new EventEmitter<boolean>(false);
  @Output() clickLast = new EventEmitter<boolean>(false);

  constructor() {}

  onClickNext() {
    this.clickNext.next(true);
  }
  onClickLast() {
    this.clickLast.next(true);
  }
  onClickPrev() {
    this.clickPrev.next(true);
  }
  onClickFirst() {
    this.clickFirst.next(true);
  }
}
