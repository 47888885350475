import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'joined',
})
export class JoinedPipe implements PipeTransform {
  transform(value: unknown, user): unknown {
    if (Array.isArray(value) && value) {
      const newValue = value.filter(item => {
        let partArr =
          typeof item.participants[0] === 'string'
            ? item.participants
            : item.participants.map(item => item.uid);
        return item.status === 'approved' && partArr.includes(user);
      });

      return newValue;
    } else return value;
  }
}
