import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JobsServicesService } from './jobs-services.service';
import { takeUntil, filter, Subject } from 'rxjs';
import { FormService } from 'src/app/shared/components/form/form.service';
import { AdmobService } from 'src/app/shared/adv/admob.service';
import { UserService } from 'src/app/auth/user.service';

@Component({
  selector: 'app-jobs-services',
  templateUrl: './jobs-services.component.html',
  styleUrls: ['./jobs-services.component.scss'],
})
export class JobsServicesComponent implements OnInit, OnDestroy {
  toolbarData = {
    arrowRouter: '/bazaar',
    btnSlot: 'end',
    title: 'Service Exchange',
  };
  tab: string;
  isPremium = false;
  isAdmin;
  isMobile = this.admobService.isMobile;
  isShowPage = false;

  private destroy$ = new Subject<void>();
  isHeaderDisabled =
    this.router.url.includes('/bazaar/job&services/services/') ||
    this.router.url.includes('/bazaar/job&services/jobs/');

  private sub;

  constructor(
    private router: Router,
    private service: JobsServicesService,
    private userService: UserService,
    private fs: FormService,
    private admobService: AdmobService
  ) {}

  async ngOnInit() {
    this.getUser();
    this.sub = this.service.urlChanged.subscribe(async (e: any) => {
      if (
        e.url.includes('/bazaar/job&services/services/') ||
        e.url.includes('/bazaar/job&services/jobs/')
      ) {
        await this.admobService.hideBanner();
        this.isHeaderDisabled = true;
        const app: HTMLElement = document.querySelector('ion-router-outlet');
        app.style.marginBottom = '0px';
      } else {
        await this.admobService.resumeBanner();
        this.isHeaderDisabled = false;
      }
    });
  }

  async ngOnDestroy() {
    this.sub.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
    this.fs.jobsMenuData$.next(false);
    await this.admobService.ionViewWillLeave();
  }

  async ionViewWillEnter() {
    this.isShowPage = true;
    await this.admobService.resumeBanner();
    this.admobService.ionViewWillEnter();
  }

  async ionViewWillLeave() {
    this.isShowPage = false;
    await this.admobService.hideBanner();
  }

  getSelected(_e) {
    this.tab = _e;
    _e === 'jobs'
      ? this.fs.servicesMenuData$.next(false)
      : this.fs.jobsMenuData$.next(false);
  }

  private getUser() {
    this.userService.currentUser$
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user.uid)
      )
      .subscribe(async user => {
        this.isAdmin = this.userService.isAdmin;
        this.isPremium = this.userService.isPremium;
        if (!this.isAdmin && this.isMobile && !this.isPremium) {
          this.admobService.showBottomBanner();
        } else {
          await this.admobService.ionViewWillLeave();
        }
      });
  }
}
