import { Component, EventEmitter, Input, Output } from '@angular/core';
import {Router} from "@angular/router";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-buy-pearls-notification',
  templateUrl: './buy-pearls-notification.component.html',
  styleUrls: ['./buy-pearls-notification.component.scss'],
})
export class BuyPearlsNotificationComponent{
  @Input() pearlsNumber;
  @Input() isBuyPearlsModalOpen;
  @Output() pearlsNumberChange = new EventEmitter<any>();
  @Output() isBuyPearlsModalOpenChange = new EventEmitter<any>();
  @Output() getInfoHandler = new EventEmitter<any>();
  environment = environment;
  constructor(private router: Router) {
    
  }

  closeBuyPearlsNotification() {
    this.isBuyPearlsModalOpenChange.next(false);
    this.pearlsNumberChange.next(null);
    setTimeout(() => {
      this.router.navigate(['/?notification=shown']);
      this.getInfoHandler.emit();
    }, 0);
  }
}
