import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CrudHelper } from 'src/app/firebase/helpers/crudHelper';
import { CommonService } from 'src/app/shared/component-store/common-service';

@Injectable({ providedIn: 'root' })
export class ShopService extends CommonService {
  constructor(
    private fb: FormBuilder,
    private firebaseHelperService: CrudHelper
  ) {
    super(firebaseHelperService);
  }

  getItems({ collectionName, filter }) {
    const queryFn = ref => {
      let query = ref;

      query = query.orderBy('order', 'asc');

      return query;
    };

    return this.firebaseHelperService.getCollectionItems({
      collectionName,
      queryFn,
    });
  }
}
