import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tutorial-third-page',
  templateUrl: './tutorial-third-page.component.html',
  styleUrls: ['./tutorial-third-page.component.scss'],
})
export class TutorialThirdPageComponent implements OnInit {
  isOpen = true;
  environment = environment;
  constructor(private router: Router) {
    
   }

  ngOnInit() {}

  toNextPage() {
    this.isOpen = false;
    setTimeout(() => {
      this.router.navigate(['/tutorial/4'], { replaceUrl: true });
    }, 0);
  }
}
