import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  Subject,
  takeUntil,
} from 'rxjs';
import { showLoading } from 'src/app/shared/components/modals/loading';
import { map, switchMap } from 'rxjs/operators';
import { JobsStore } from './jobs-store';
import { FormService } from 'src/app/shared/components/form/form.service';
import { JobsService } from './jobs.service';
import { IJobsFilter, jobDiscordModalData, jobPremiumModalData } from './jobs';
import { Platform } from '@ionic/angular';
import { UserService } from 'src/app/auth/user.service';
import { JobsServicesService } from '../jobs-services.service';
import { CrudHelper } from 'src/app/firebase/helpers/crudHelper';
@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss'],
})
export class JobsComponent implements OnInit, OnDestroy {
  searchString: BehaviorSubject<string> = new BehaviorSubject<string>('');

  status;
  loader = showLoading();

  isOpenFormModal = false;
  myContent: boolean = false;
  filter = {};

  userId: string;
  items;

  color = 'dark';
  isAdmin = false;
  isPremium;

  isOpenDiscordModal = false;
  premiumModalData = jobPremiumModalData;
  serviceDiscordModalData = jobDiscordModalData;
  isUserPremiumModalOpen = false;
  currentUser;
  infoSnapshot;
  userInfo;
  duration;
  currentTime: number = new Date().getTime();

  isMobile = this.platform.platforms().includes('capacitor');
  isIosApp = this.platform.platforms().includes('ios') && this.isMobile;
  isUserHasSuspendedContent$ = new BehaviorSubject<boolean>(false);
  isPremium$ = new BehaviorSubject<boolean>(false);
  private destroy$ = new Subject<void>();

  constructor(
    private jobsStore: JobsStore,
    private jobsService: JobsService,
    private fs: FormService,
    private userService: UserService,
    private platform: Platform,
    private service: JobsServicesService,
    private crudHelper: CrudHelper
  ) {}

  async ngOnInit() {
    this.service.isUserHasSuspendedContent$.subscribe(data =>
      this.isUserHasSuspendedContent$.next(data)
    );
    this.listenFilter();
    this.getUser();
    this.listenItemsChanges();
    this.listenStatusChanges();
    this.jobsStore.selectShowFormModal().subscribe(isOpen => {
      this.isOpenFormModal = isOpen;
    });
  }

  ngOnDestroy() {
    this.fs.formInitialValues.next({ myContent: null });
    this.destroy$.next();
    this.destroy$.complete();
    this.fs.jobsMenuData$.next(false);
  }

  openModal() {
    if (this.currentUser.discordLink) {
      if (!this.isPremium && !this.isAdmin) {
        this.isUserPremiumModalOpen = true;
      } else {
        this.jobsStore.updateShowFormModal(true);
      }
    } else if (this.isAdmin) {
      this.jobsStore.updateShowFormModal(true);
    } else {
      this.isOpenDiscordModal = true;
    }
  }

  closeModal(e) {
    e ? this.jobsStore.updateShowFormModal(false) : null;
  }

  onSearchChange(searchString) {
    this.searchString.next(searchString);
  }

  private listenFilter() {
    this.fs.jobsMenuData$.subscribe(data => {
      this.filter = data;
      const initialData = this.jobsService.checkFilter(data);
      if (initialData) {
        this.color = 'success';
      } else {
        this.color = 'dark';
      }
      this.myContent = (data as IJobsFilter).myContent;
      this.jobsStore.getItems(data);
    });
  }

  private listenStatusChanges() {
    this.jobsStore
      .selectLoading()
      .pipe(takeUntil(this.destroy$))
      .subscribe(loading => {
        loading
          ? this.loader.then(data => data.present())
          : this.loader.then(data => data.dismiss());
      });
  }

  private listenItemsChanges() {
    combineLatest([this.jobsStore.selectItems(), this.searchString])
      .pipe(
        takeUntil(this.destroy$),
        map(([items, searchString]) => {
          return (
            items &&
            items.filter(item =>
              item.title.toLowerCase().includes(searchString.toLowerCase())
            )
          );
        }),

        switchMap((items: any[]) => {
          if (!items?.length) return (this.items = []);
          const users = this.crudHelper.getCollectionItems({
            collectionName: 'users',
            queryFn: null,
          });
          return users.pipe(
            filter(users => !!users),
            map(users => {
              return items.map(item => {
                const user = users.find(user => user.id === item.creator.id);
                if (user) return Object.assign({ ...item, creator: user });
                return item;
              });
            })
          );
        })
      )
      .subscribe(items => {
        if (!this.myContent) {
          this.items = items
            ?.slice()
            .filter(item => !this.currentUser?.blockList?.includes(item.creator.id))
            .filter(
              item => item.suspended === false || item?.suspended === undefined
            );
        } else {
          this.items = items?.slice().filter(item => !this.currentUser?.blockList?.includes(item?.creator?.id));
        }
        this.isUserHasSuspendedContent$.next(
          this.jobsService.jobsSuspendedCheck(items, this.currentUser?.uid)
        );
      });
  }
  private getUser() {
    this.userService.currentUser$
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user.uid)
      )
      .subscribe(user => {
        this.currentUser = user;
        this.isPremium = this.userService.isPremium;
        this.isPremium$.next(this.isPremium);
        this.isAdmin = this.userService.isAdmin;
        this.userId = user.uid;
        this.userInfo = this.userService.balanceInfo;
      });
  }

  jobsSuspendedCheck() {
    return this.jobsService.jobsSuspendedCheck(
      this.items,
      this.currentUser?.uid
    );
  }
}
