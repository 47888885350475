import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-confirm-modal2',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent {
  @Input() showConfirmModal: boolean;
  @Input() confirmModalData: {
    title: string;
    iconName: string;
    description: string;
    okBtn: { title: string; handler?: void };
    cancelBtn?: { title: string; handler?: void };
  };
  @Input() template: TemplateRef<any>;
  @Input() podsSuggestionsList;
  @Input() spinner: boolean;
  @Output() ok: EventEmitter<boolean> = new EventEmitter();
  @Output() cancel: EventEmitter<boolean> = new EventEmitter();
  environment = environment;
  constructor() {
    
  }

  handleOk() {
    
    this.ok.next(true);
  }

  handleCancel() {
    this.cancel.next(true);
  }
}
