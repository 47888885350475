import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'workTypeConverter',
})
export class WorkTypeConverterPipe implements PipeTransform {
  transform(value: string): unknown {
    switch (value) {
      case 'per hour':
        return '/hr';
      case 'gig based':
        return '/gig';
      case 'full-time':
        return '/month';
      case 'part-time':
        return '/hr';
      default:
        '';
        break;
    }
    return null;
  }
}
