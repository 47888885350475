import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { quoteDus } from 'src/app/shared/components/modals/traveling-modal/traveling-quotes';
import { ProfileService } from '../profile.service';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/auth/user.service';
import { Subject, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'app-profile-items-component',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss'],
})
export class ProfileItemsComponent implements OnInit {
  @Input() title: string;
  @Input() count: number;
  @Input() button: {
    title: string;
    path: string;
    name: string;
    userId: string;
  };
  environment = environment;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onShowMoreClicked = new EventEmitter<boolean>(false);
  currentUserId = this.activeRouter.snapshot.params.id;
  authUserId;
  isUserPremium = false;
  isAdmin = false;
  showTravelingModal = false;
  travelingBg: string;
  random: number;
  routerLink: string[] | null;
  routerTitle: string;
  private destroy$ = new Subject();

  authUser: string;
  isMobile = this.platform.platforms().includes('capacitor');

  constructor(
    private router: Router,
    private activeRouter: ActivatedRoute,
    private userService: UserService,
    private platform: Platform,
    private profileService: ProfileService
  ) {}
  ngOnInit(): void {
    this.getUser()
  }

  showMoreClick() {
    this.onShowMoreClicked.next(true);
  }

  goTo(link, name, userId, premium, admin) {
    if (this.currentUserId === this.authUserId) {
      this.profileService.setFilterData(this.currentUserId, name);
    }
    
    const max = quoteDus.length;
    this.random = Math.round(0 - 0.5 + Math.random() * (max + 1));
    if (!premium && !admin) {
      this.showTravelingModal = true;
    } else {
      this.router.navigate(link);
      // this.formService.formInitialValues.next({ myContent: true });
    }
  }

  closeTravelingModal(e) {
    this.showTravelingModal = !e;
  }

  private getUser() {
    this.userService.currentUser$
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user.uid)
      )
      .subscribe(user => {
        this.authUserId = user.uid;
      });
  }
}
