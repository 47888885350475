import { Injectable } from '@angular/core';
import {
  DataUrl,
  NgxImageCompressService,
  UploadResponse,
} from 'ngx-image-compress';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImagesService {
  compressionLoading = new BehaviorSubject<boolean>(false);
  imagesLoading = new BehaviorSubject<boolean>(false);
  compressedImages = new BehaviorSubject<any[]>([]);
  addImageToGalleryModalIsOpen = new BehaviorSubject<boolean>(false);
  imageUrls = new BehaviorSubject<any[]>(null);

  constructor(private imageCompress: NgxImageCompressService) {}

  getImageSize(image) {
    const base64Image = image;
    const yourBase64String = base64Image.substring(
      base64Image.indexOf(',') + 1
    );
    const bits = yourBase64String.length * 6;
    const bytes = bits / 8;
    const kb1 = Math.ceil(bytes / 1000);
    const kb = Math.ceil((yourBase64String.length * 6) / 8 / 1000);
    return kb;
  }

  dataURItoBlob(dataURI: DataUrl, name: string) {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new File([new Blob([ab], { type: mimeString })], name);
  }

  async compressMultiplyImages() {
    this.compressionLoading.next(true);
    const imgs = [];
    return this.imageCompress
      .uploadMultipleFiles()
      .then((multipleOrientedFiles: UploadResponse[]) => {
        if (!multipleOrientedFiles.length) {
          return;
        }
        multipleOrientedFiles.map((image, index) => {
          const quality = this.getImageSize(image.image) > 500 ? 50 : 100;
          this.imageCompress
            .compressFile(image.image, image.orientation, quality, quality)
            .then(data => {
              const file = this.dataURItoBlob(data, image.fileName);
              imgs.push(file);
              if (index === multipleOrientedFiles.length - 1) {
                this.compressedImages.next([
                  ...this.compressedImages.value,
                  ...imgs,
                ]);
                this.compressionLoading.next(false);
              }
            });
        });
      });
  }

  async compressImage() {
    return this.imageCompress.uploadFile().then((file: UploadResponse) => {
      if (!file) return;
      this.compressedImages.next([]);
      this.compressionLoading.next(true);
      const quality = this.getImageSize(file.image) > 500 ? 50 : 100;
      this.imageCompress
        .compressFile(file.image, file.orientation, quality, quality)
        .then((result: DataUrl) => {
          const fileResult = this.dataURItoBlob(result, file.fileName);
          this.compressedImages.next([fileResult]);
          this.compressionLoading.next(false);
        });
    });
  }

  reset() {
    this.compressedImages.next([]);
    this.imageUrls.next([]);
    this.imagesLoading.next(false);
    this.compressionLoading.next(false);
  }
}
