import { Injectable } from '@angular/core';
import { User } from '../../shared/models/user.model';
import { ProfileService } from '../profile.service';
import { CommonComponentStore } from '../../shared/component-store/common-component-store';
import {
  EMPTY,
  Observable,
  catchError,
  filter,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { tapResponse } from '@ngrx/component-store';
import {
  createToaster,
  positionType,
} from 'src/app/shared/components/modals/toaster';

@Injectable({ providedIn: 'root' })
export class ProfileStore extends CommonComponentStore<User> {
  constructor(private profileService: ProfileService) {
    super(profileService, {
      item: null,
      items: null,
      loading: false,
      showFormModal: false,
      collectionName: 'users',
      gallery: null,
      pods: null,
      pools: null,
      events: null,
      jobs: null,
      services: null,
    });
  }

  logOut() {
    this.patchState(null);
  }

  getGallery(id) {
    return this.effect((id: Observable<any>) =>
      id.pipe(
        filter(id => !!id),
        tap(() => this.patchState({ loading: true })),

        switchMap(id => {
          this.patchState({ loading: true });

          return this.profileService.getProfileGallery(id).pipe(
            tapResponse(
              (gallery: any) => {
                return this.patchState({
                  gallery: gallery[0],
                  loading: false,
                });
              },
              (error: HttpErrorResponse) => {
                this.patchState({ loading: false });
                console.log(error);
              }
            ),
            catchError(() => EMPTY)
          );
        })
      )
    )(id);
  }

  addToGallery(gallery) {
    return this.effect((gallery: Observable<any>) =>
      gallery.pipe(
        filter(gallery => !!gallery),
        tap(() => this.patchState({ loading: true })),
        switchMap(gallery => {
          return this.profileService
            .updateItemById('gallery', gallery, gallery.id)
            .pipe(
              tapResponse(
                () => this.patchState({ gallery, loading: false }),
                (error: HttpErrorResponse) => console.log(error)
                // ? it causes error if you want  to create new item  after that
                // () => this.patchState({ loading: true })
              ),
              catchError(() => EMPTY)
            );
        })
      )
    )(gallery);
  }

  createGallery(userId) {
    return this.effect((userId: Observable<any>) =>
      userId.pipe(
        filter(userId => !!userId),
        tap(() => this.patchState({ loading: true })),
        switchMap(userId => {
          return this.profileService
            .addItem({
              collectionName: 'gallery',
              data: { userId, images: [] },
            })
            .pipe(
              tapResponse(
                id =>
                  this.patchState({
                    gallery: { id, userId, images: [] },
                    loading: false,
                  }),
                (error: HttpErrorResponse) => console.log(error)
                // ? it causes error if you want  to create new item  after that
                // () => this.patchState({ loading: true })
              ),
              catchError(() => EMPTY)
            );
        })
      )
    )(userId);
  }

  selectGallery() {
    return this.select(state => state.gallery);
  }
  selectPods() {
    return this.select(state => state.pods);
  }
  selectPools() {
    return this.select(state => state.pools);
  }
  selectEvents() {
    return this.select(state => state.events);
  }
  selectJobs() {
    return this.select(state => state.jobs);
  }
  selectServices() {
    return this.select(state => state.services);
  }

  updateProfile(item) {
    return this.effect((item: Observable<any>) =>
      item.pipe(
        filter(item => !!item),
        tap(() => this.patchState({ loading: true })),
        switchMap(profile =>
          this.selectItem().pipe(
            take(1),
            switchMap(item => this.updateUser(profile, item))
          )
        )
      )
    )(item);
  }

  updateGallery(item) {
    return this.effect((item: Observable<any>) =>
      item.pipe(
        filter(item => !!item),
        tap(() => this.patchState({ loading: true })),
        switchMap(gallery =>
          this.selectGallery().pipe(
            take(1),
            switchMap(item => {
              return this.profileService
                .updateItemById('gallery', gallery, item.id)
                .pipe(
                  tapResponse(
                    () => this.patchState({ gallery, loading: false }),
                    (error: HttpErrorResponse) => console.log(error),
                    () =>
                      createToaster(
                        'Image is successfully deleted!',
                        'success',
                        positionType.BOTTOM
                      )
                  ),
                  catchError(() => EMPTY)
                );
            })
          )
        )
      )
    )(item);
  }

  updateUser(profile: any, item: any) {
    return this.select(state => state.collectionName).pipe(
      switchMap(collectionName => {
        return this.profileService
          .updateItemById(collectionName, profile, item.uid)
          .pipe(
            tapResponse(
              () => this.patchState({ item: profile, loading: false }),
              (error: HttpErrorResponse) => console.log(error),
              () =>
                createToaster(
                  'Successfully updated!',
                  'success',
                  positionType.TOP
                )
            ),
            catchError(() => EMPTY)
          );
      })
    );
  }

  getPods(forbidden, userId) {
    const filter = forbidden ? { myContent: true } : { creator: userId };
    return this.profileService
      .getItems({ collectionName: 'pods', filter })
      .pipe(
        tapResponse(
          (items: any) => {
            this.patchState({ pods: items, loading: false });
          },
          (error: HttpErrorResponse) => {
            this.patchState({ loading: false });
          }
        ),
        catchError(() => EMPTY)
      );
  }

  resetState() {
    this.select(state => (state.item = null));
  }
}
