import { Component, Input, OnDestroy } from '@angular/core';
import { JobsService } from '../jobs.service';
import { Router } from '@angular/router';
import { confirmDeletingModalData } from '../jobs';
import { JobsStore } from '../jobs-store';
import { Subject, takeUntil } from 'rxjs';
import {
  createToaster,
  positionType,
} from 'src/app/shared/components/modals/toaster';
import { FormService } from 'src/app/shared/components/form/form.service';

@Component({
  selector: 'app-jobs-item',
  templateUrl: './jobs-item.component.html',
  styleUrls: ['./jobs-item.component.scss'],
})
export class JobsItemComponent implements OnDestroy {
  @Input() items;
  @Input() feedItemId: string;
  @Input() divider: { title: string; icon: string; color: string };
  @Input() transparent: boolean;
  @Input() currentUserId: string;
  @Input() isAdmin;
  @Input() status: string;

  showStatusModal: boolean = false;
  itemStatus: string;

  showConfirmModal;
  confirmDeletingModalData = confirmDeletingModalData;
  disabled = false;
  loading = false;

  currentTime: number = new Date().getTime();
  job;

  private destroy$ = new Subject<void>();

  constructor(
    private service: JobsService,
    private router: Router,
    private jobsStore: JobsStore,
    private formService: FormService
  ) {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openStatusModal(status) {
    if (status) this.itemStatus = status;
    this.showStatusModal = !this.showStatusModal;
  }

  openConfirmModal(item, e) {
    e.stopPropagation();
    this.showConfirmModal = true;
    this.job = item;
  }

  closeModal(e) {
    this.showConfirmModal = false;
  }

  openItem(job, currentUserId: string, isAdmin: boolean) {
    if (!this.jobSuspendedCheck(job, currentUserId, isAdmin)) {
      if (this.feedItemId) {
        this.router.navigate(['/bazaar/job&services/jobs/', this.feedItemId]);
      } else {
        this.router.navigate(['/bazaar/job&services/jobs/', job.id]);
      }
    }
  }

  removeItem(item, e) {
    this.disabled = true;
    this.loading = true;
    this.jobsStore.removeItem(this.job.id);

    this.jobsStore
      .selectLoading()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.showConfirmModal = false;
        if (data) {
          this.loading = false;
          this.disabled = false;
          this.showConfirmModal = false;
        } else {
          createToaster(
            'Successfully deleted!',
            'success',
            positionType.BOTTOM
          );
        }
      });
  }

  jobSuspendedCheck(job, currentUserId, isAdmin) {
    return isAdmin
      ? false
      : this.service.jobsSuspendedCheck(job, currentUserId);
  }
}
