import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-status-modal',
  templateUrl: './status-modal.component.html',
  styleUrls: ['./status-modal.component.scss'],
})
export class StatusModalComponent {
  @Input() showStatusModal: boolean;
  @Input() status: string;
  @Output() ok: EventEmitter<boolean> = new EventEmitter();
  constructor() {}

  handleOk() {
    this.ok.emit(true);
  }
}
