import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  FormControl,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IonModal } from '@ionic/angular';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import {
  BehaviorSubject,
  Subject,
  combineLatest,
  filter,
  takeUntil,
} from 'rxjs';
import { countries } from 'src/app/auth/sign-up/countries';
import { AuthService } from 'src/app/firebase/auth.service';
import {
  FormService,
  linkRegex,
} from 'src/app/shared/components/form/form.service';
import { showLoading } from 'src/app/shared/components/modals/loading';
import {
  imageField,
  confirmDeletingModalData,
  confirmEditingModalData,
} from '../services';
import { ServicesService } from '../services.service';
import { ServicesStore } from '../services.store';
import {
  createToaster,
  positionType,
} from 'src/app/shared/components/modals/toaster';
import { ImagesService } from 'src/app/services/images.service';
import { environment } from 'src/environments/environment';
import { MapService } from 'src/app/services/map.service';

@Component({
  selector: 'app-edit-service',
  templateUrl: './edit-service.component.html',
  styleUrls: ['./edit-service.component.scss'],
})
export class EditServiceComponent implements OnInit, OnDestroy {
  @Output() isClose = new EventEmitter<boolean>(false);
  @ViewChild(IonModal) modal: IonModal;
  @ViewChild('map') mapRef;
  @ViewChild('imageLoader') imageLoader;
  @ViewChild('placesRef', { static: false }) placesRef: GooglePlaceDirective;
  map;
  isOpen = false;
  countries = countries;
  serviceForm: FormGroup;
  showSuccessModal = false;
  showForDelete = false;
  newServiceItem;
  serviceItem;
  disabled = true;
  addingBtnDisabled = true;
  imageField = imageField;
  typeOptions = ['part-time', 'full-time', 'per hour', 'gig based'];
  id: string = this.activatedRouter.snapshot.params.id;
  successModalData;
  statusDeleting: boolean;
  statusAdding: boolean;
  imagesControls = 1;
  formIsReset = new BehaviorSubject(false);
  businessAccount = false;

  businessWebsiteURLValue = null;
  businessNameValue = null;

  status;
  currentImage: string;
  loadingImage: boolean = false;
  imagesArr: string[];
  urls;

  isOnline = false;
  loader = showLoading();
  user;
  today = new Date().toISOString();
  item = { icon: 'pricetag', title: 'Offer your Services' };
  confirmModalData = confirmEditingModalData;
  confirmForDelete = confirmDeletingModalData;
  showConfirmModal = false;
  initialData;
  environment = environment;
  private destroy$ = new Subject<void>();

  constructor(
    private formService: FormService,
    private router: Router,
    private authService: AuthService,
    private activatedRouter: ActivatedRoute,
    private imagesService: ImagesService,
    private servicesService: ServicesService,
    private servicesStore: ServicesStore,
    private fb: FormBuilder,
    private mapService: MapService
  ) {}

  ngOnInit() {
    this.listenStatusChanges();
    this.serviceForm = this.fb.group({
      images: this.fb.array([
        {
          0: this.fb.group({
            image: [null],
          }),
        },
      ]),
      title: ['', [Validators.required, Validators.minLength(7)]],
      type: ['', [Validators.required]],
      salary: this.fb.group({
        value: [null],
        currency: ['$'],
      }),
      completionDate: this.fb.group({
        startDate: [null],
        endDate: [null],
      }),
      description: ['', [Validators.required, Validators.minLength(20)]],
      location: this.fb.group({
        online: [false, [Validators.required]],
        country: [null],
        state: [null],
        googleMapLink: [null],
      }),

      account: ['my member account'],
    });

    this.serviceForm
      .get('location')
      .get('country')

      .valueChanges.pipe(
        filter(value => value.name !== this.initialData?.location.country.name)
      )
      .subscribe(() => {
        if (this.serviceForm.get('location.googleMapLink')?.value !== null) {
          this.serviceForm.patchValue({
            location: {
              state: null,
              googleMapLink: null,
            },
          });
          this.formService.location$.next({ googleMapLink: null });
        }
      });
    this.getUser();
    this.servicesStore.getItemByByValue({
      documentId: this.activatedRouter.snapshot.params.id,
    });

    this.imagesService.compressedImages.subscribe(images => {
      this.imagesArr = images;
    });

    combineLatest([
      this.serviceForm.valueChanges,
      this.imagesService.imagesLoading,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([value, loading]) => {
        this.loadingImage = loading;
        this.disabled =
          this.serviceForm.status === 'INVALID' ||
          this.status === 'loading' ||
          this.loadingImage;
      });
    this.initialDataListener();
    this.serviceForm
      .get('account')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value: string) => {
        if (value === 'a business account') {
          this.businessAccount = true;
          this.serviceForm.addControl(
            'businessName',
            new FormControl(this.businessNameValue, [Validators.required])
          );
          this.serviceForm.addControl(
            'businessWebsiteUrl',
            new FormControl(this.businessWebsiteURLValue, [
              Validators.required,
              Validators.pattern(linkRegex),
            ])
          );
        } else {
          this.businessAccount = false;
          this.serviceForm.removeControl('businessName');
          this.serviceForm.removeControl('businessWebsiteUrl');
        }
      });
    this.serviceForm
      .get('location.online')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value: string) => {
        if (value) {
          this.isOnline = true;
          (this.serviceForm.get('location') as FormGroup).removeControl(
            'country'
          );
          (this.serviceForm.get('location') as FormGroup).removeControl(
            'state'
          );
          (this.serviceForm.get('location') as FormGroup).removeControl(
            'googleMapLink'
          );
        } else {
          this.isOnline = false;
          (this.serviceForm.get('location') as FormGroup).addControl(
            'country',
            new FormControl(this.initialData.location.country, [
              Validators.required,
            ])
          );
          (this.serviceForm.get('location') as FormGroup).addControl(
            'state',
            new FormControl(this.initialData.location.state, [
              Validators.required,
            ])
          );
          (this.serviceForm.get('location') as FormGroup).addControl(
            'googleMapLink',
            new FormControl(this.initialData.location.googleMapLink)
          );
        }
      });
  }

  ngOnDestroy() {
    this.formService.itemGallery.next([]);
    this.destroy$.next();
    this.destroy$.complete();
  }

  initialDataListener() {
    this.servicesStore
      .selectItem()
      .pipe(
        filter(item => !!item),
        takeUntil(this.destroy$)
      )
      .subscribe(data => {
        this.businessNameValue = data.businessName;
        this.businessWebsiteURLValue = data.businessWebsiteUrl;
        const start = this.servicesService.formatStartDate(data);
        const end = this.servicesService.formatEndDate(data);
        this.initialData = Object.assign({
          ...data,
          completionDate: {
            startDate: start,
            endDate: end,
          },
        });
        this.formService.location$.next({
          googleMapLink: data.location.googleMapLink,
        });
        this.serviceForm.patchValue(this.initialData);
        if (data.account === 'a business account') {
          this.businessAccount = true;
          this.serviceForm.addControl(
            'businessName',
            new FormControl(this.businessNameValue, [Validators.required])
          );
          this.serviceForm.addControl(
            'businessWebsiteUrl',
            new FormControl(this.businessWebsiteURLValue, [
              Validators.required,
              Validators.pattern(linkRegex),
            ])
          );
        } else {
          this.businessAccount = false;
          this.serviceForm.removeControl('businessName');
          this.serviceForm.removeControl('businessWebsiteUrl');
        }
        this.serviceItem = data;
        this.imagesService.imageUrls.next(data.images);
        this.imagesService.compressedImages.next(data.images);
      });

    this.serviceForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        this.disabled =
          this.serviceForm.status === 'INVALID' || this.loadingImage;

        if (this.serviceItem?.creator) {
          this.newServiceItem = {
            ...value,
            id: this.id,
            status: 'pending',
            creator: this.serviceItem?.creator,
          };
        }
      });

    this.serviceForm
      .get('location.country')
      .valueChanges.pipe(
        filter(value => value !== this.initialData.location.country)
      )
      .subscribe(() => {
        this.serviceForm.patchValue({
          location: {
            state: null,
            googleMapLink: null,
          },
        });
      });
  }

  saveBusinessName(e) {
    this.businessNameValue = e.target.value;
  }

  saveBusinessWebsiteURL(e) {
    this.businessWebsiteURLValue = e.target.value;
  }

  async updateItem() {
    this.disabled = true;
    this.servicesStore.setLoading();
    if (this.imagesArr.length) {
      await this.imageLoader.upLoadImages();
      this.imagesService.imageUrls
        .pipe(
          filter(data => !!data),
          takeUntil(this.destroy$)
        )
        .subscribe(urls => {
          if (urls.length && urls.length === this.imagesArr.length) {
            this.setNewItem(urls);
          }
        });
    } else {
      this.setNewItem(null);
    }
  }
  setNewItem(urls) {
    this.newServiceItem.location = this.newServiceItem.location.online
      ? Object.assign({
          ...this.newServiceItem.location,
          country: { region: 'online' },
        })
      : this.newServiceItem.location;
    this.newServiceItem.images = urls ? [...urls] : [];
    this.formService.itemGallery.next([]);
    const start = new Date(
      this.serviceForm.get('completionDate.startDate').value
    );
    const end = this.serviceForm.get('completionDate.endDate').value
      ? new Date(this.serviceForm.get('completionDate.endDate').value)
      : null;

    this.servicesStore.updateProfile(
      Object.assign({
        ...this.newServiceItem,
        completionDate: {
          startDate: start,
          endDate: end,
        },
        createdAt: this.initialData.createdAt,
      })
    );
    this.servicesStore
      .selectLoading()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        if (data) {
          this.disabled = true;
          this.statusAdding = true;
        } else {
          this.isClose.next(true);
          createToaster(
            'Successfully updated!',
            'success',
            positionType.BOTTOM
          );
          this.disabled = false;
          this.statusAdding = false;
        }
      });
  }

  removeItem(e) {
    this.disabled = true;
    if (e) {
      this.servicesStore.removeItem(this.id);
      this.servicesStore
        .selectLoading()
        .pipe(takeUntil(this.destroy$))
        .subscribe(data => {
          this.statusDeleting = data;
          if (data) {
            this.disabled = true;
            this.showForDelete = false;
          } else {
            this.isClose.next(true);
            createToaster(
              'Successfully deleted!',
              'success',
              positionType.BOTTOM
            );
            setTimeout(() => {
              this.router.navigate(['bazaar/job&services/services']);
            }, 0);
          }
        });
    }
  }

  getFormsControls(): FormArray {
    return <FormArray>this.serviceForm.controls['images'];
  }

  close(e) {
    e ? (this.showConfirmModal = true) : null;
  }

  discardChanges(e) {
    if (e) {
      this.isClose.next(true);
      this.showConfirmModal = false;
    }
  }

  closeModal(e) {
    if (e) {
      this.showConfirmModal = false;
      this.showForDelete = false;
    }
  }

  addMapLink(e) {
    e.valueChanges.subscribe(async () => {
      const location = e.get('googleMapLink')?.value
      const { country } = await this.mapService.getAdDress(location);

      let currentCountry = countries.find(item => item.name === country);

      this.serviceForm.get('location.googleMapLink')?.patchValue(location);
      this.serviceForm.get('location.country').patchValue(currentCountry);
    });
  }

  private listenStatusChanges() {
    this.servicesStore
      .selectLoading()
      .pipe(takeUntil(this.destroy$))
      .subscribe(loading => {
        loading
          ? this.loader.then(data => data.present())
          : this.loader.then(data => data.dismiss());
      });
  }

  private getUser() {
    const { uid } = this.authService.isUserAuth();
    this.authService
      .getUserByUid(uid)
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user)
      )
      .subscribe(user => (this.user = user));
  }
}
