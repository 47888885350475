import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PodsComponent } from './pods.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from 'src/app/shared/shared.module';
import { PodsRoutingModule } from '../pods-routing.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { EditPodComponent } from '../edit-pod/edit-pod.component';
import { RequestModalComponent } from '../pod-details/confirm-modal/confirm-modal.component';
import { PodDetailsComponent } from '../pod-details/pod-details.component';
import { QuestionModalComponent } from '../pod-details/question-modal/question-modal.component';
import { EventsModule } from 'src/app/events/events.module';
import { PodItemComponent } from '../pod-item/pod-item.component';

@NgModule({
  declarations: [
    PodsComponent,
    PodDetailsComponent,
    QuestionModalComponent,
    RequestModalComponent,
    EditPodComponent,
    PodItemComponent,
  ],
  imports: [
    SharedModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
    PodsRoutingModule,
    PipesModule,
    EventsModule,
  ],
  exports: [PodItemComponent],
})
export class PodsPageModule {}
