import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-event-participants-item',
  templateUrl: './event-participants-item.component.html',
  styleUrls: ['./event-participants-item.component.scss'],
})
export class EventParticipantsItemComponent {
  @Input() user;
  constructor() {}
}
