import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { shopConfirmModalData } from '../bazaar';
import { ShopStore } from './shop-store';
import { ViewWillEnter } from '@ionic/angular';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormService } from 'src/app/shared/components/form/form.service';
import {
  createToaster,
  positionType,
} from 'src/app/shared/components/modals/toaster';
import { Clipboard } from '@capacitor/clipboard';
import { Subject, filter, takeUntil } from 'rxjs';
import { AdmobService } from 'src/app/shared/adv/admob.service';
import { UserService } from 'src/app/auth/user.service';
import { IUser } from 'src/app/auth/auth.models';
import { environment } from 'src/environments/environment';
import { ImagesService } from 'src/app/services/images.service';

@Component({
  selector: 'app-shops',
  templateUrl: './shops.component.html',
  styleUrls: ['./shops.component.scss'],
})
export class ShopsComponent implements OnInit, ViewWillEnter, OnDestroy {
  @ViewChild('modal') modal;

  environment = environment;

  private destroy$ = new Subject<void>();
  toolbarData = {
    arrowRouter: '/bazaar',
    title: 'Shops',
  };
  confirmModalData = shopConfirmModalData;
  showConfirmModal = false;

  shops$ = this.shopStore.selectItems();

  isPremium = false;
  isAdmin = false;
  isMobile = this.admobService.isMobile;
  isShowPage = false;

  form: FormGroup;
  link: string;

  currentUser: IUser;

  constructor(
    private shopStore: ShopStore,
    private fb: FormBuilder,
    private fs: FormService,
    private imagesService: ImagesService,
    private userService: UserService,
    private admobService: AdmobService
  ) {
    
  }

  ngOnInit() {
    this.getUser();
    this.form = this.fb.group({ image: [null] });
    this.imagesService.imageUrls.subscribe(value => {
      value?.length ? (this.link = value[0]) : '';
    });

    this.shopStore.getItems({});
  }

  ionViewWillEnter() {
    this.isShowPage = true;
    if (this.currentUser !== this.userService.user) {
      this.ngOnInit();
    }
    this.admobService.ionViewWillEnter();
  }

  async ionViewWillLeave() {
    this.isShowPage = false;
    await this.admobService.ionViewWillLeave();
  }

  openModal() {
    
  }

  closeModal(e) {
    e ? (this.showConfirmModal = false) : null;
  }

  async copyLink() {
    await Clipboard.write({
      string: this.link,
    }).then(() => {
      createToaster(
        `Photo link copied on clipboard`,
        'success',
        positionType.BOTTOM
      );
      this.form.reset();
    });
  }

  removeImageItem(e, i) {
    if (e) {
      this.imagesService.imageUrls.next([]);
      this.link = '';
    }
  }

  private getUser() {
    this.userService.currentUser$
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user.uid)
      )
      .subscribe(async user => {
        this.currentUser = user;
        this.isPremium = this.userService.isPremium;
        this.isAdmin = this.userService.isAdmin;
        if (!this.isAdmin && this.isMobile && !this.isPremium) {
          this.admobService.showBottomBanner();
        } else {
          await this.admobService.ionViewWillLeave();
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
