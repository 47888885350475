import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommunityComponentRoutingModule } from './community-routing.module';
import { CommunityComponent } from './community.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { CommunityItemComponent } from './community-item/community-item.component';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CommunityComponentRoutingModule,
    SharedModule,
    PipesModule,
    NgxPaginationModule,
  ],
  exports: [CommunityItemComponent],
  declarations: [CommunityComponent, CommunityItemComponent],
})
export class CommunityPageModule {}
