import { FormField, FormFieldTypes } from 'src/app/shared/components/form/form';
import { environment } from 'src/environments/environment';

export const communityFilterData: FormField[] = [
  {
    name: 'country',
    type: FormFieldTypes.SELECT,
    label: 'Country',
    multiple: true,
    required: false,
    icon: 'location',
  },
  {
    name: 'experience',
    type: FormFieldTypes.SELECT,
    label: 'Mermaid Experience Level',
    required: false,
    multiple: true,
    options: ['Newby', 'Regular Hobbyist', 'Semi-Professional', 'Professional'],
    icon: 'trending-up',
  },
  {
    name: 'gender',
    type: FormFieldTypes.SELECT,
    label: 'Gender',
    required: false,
    multiple: true,
    options: ['Male', 'Female', 'Other'],
    icon: 'transgender',
  },
];

export const communityDusFilterData: FormField[] = [
  {
    name: 'country',
    type: FormFieldTypes.SELECT,
    label: 'Country',
    multiple: true,
    required: false,
    icon: 'location',
  },
  {
    name: 'danceStyle',
    type: FormFieldTypes.SELECT,
    label: 'Dance Style',
    required: false,
    multiple: true,
    options: [
      'Salsa',
      'Bachata',
      'Kizomba',
      'African Dances',
      'Zouk',
      'Tango',
      'Ballroom',
      'HipHop',
      'Bellydances',
      'Indian dances',
      'Ballet',
      'American dances',
      'Free & improvised dances',
      'Disco & Soul dances',
      'I want to see everything together'
    ],
    icon: 'trending-up',
  },
  {
    name: 'gender',
    type: FormFieldTypes.SELECT,
    label: 'Gender',
    required: false,
    multiple: true,
    options: ['Male', 'Female', 'Other'],
    icon: 'transgender',
  },
];

export const successModalData = {
  title: 'Thanks for sharing DanceUnitesUs with your DanceFriend!',
  pic: 'some sea animal in company with others',
  description:
    "It's thanks to people like you that we can grow a great community and connect with each other!",
  okBtn: {
    title: 'CONTINUE',
    link: `dancers/community`,
  },
  cancelBtn: {
    title: 'SPLASH MERFRIENDS',
    link: '/',
  },
};
export const confirmModalData = {
  title: environment.app ? 'Invite a DanceFriend!' : 'Invite a Merfriend',
  icon: 'people',
  okBtn: {
    title: 'YES, DISCARD',
  },
  cancelBtn: { title: 'NO, GO BACK' },
};

export const danceStyles = [
  { name: 'Salsa'},
  { name: 'Bachata'},
  { name: 'Kizomba'},
  { name: 'African Dances'},
  { name: 'Zouk'},
  { name: 'Tango'},
  { name: 'Ballroom'},
  { name: 'HipHop'},
  { name: 'Bellydances'},
  { name: 'Indian dances'},
  { name: 'Ballet'},
  { name: 'American dances'},
  { name: 'Free & improvised dances'},
  { name: 'Disco & Soul dances'},
  { name: 'I want to see everything together'},
];

export const Dance = [
  'Salsa',
  'Bachata',
  'Kizomba',
  'African Dances',
  'Zouk',
  'Tango',
  'Ballroom',
  'HipHop',
  'Bellydances',
  'Indian dances',
  'Ballet',
  'American dances',
  'Free & improvised dances',
  'Disco & Soul dances',
  'I want to see everything together'
]
