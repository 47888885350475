import { Pipe, PipeTransform } from '@angular/core';
import { DateService } from '../services/date.service';

@Pipe({
  name: 'isUserHasSuspendedContent',
})
export class IsUserHasSuspendedContentPipe implements PipeTransform {
  constructor(private dateService: DateService) {}
  transform(items: any[] | any, userId: string, isAdmin: boolean): unknown {
    if (isAdmin) {
      return false;
    } else if (items && Array.isArray(items)) {
      const actualItems = this.dateService.getScheduledItems(items, false);

      const item = actualItems.find(
        item =>
          item.suspended &&
          item.creator.id === userId &&
          item.status === 'approved'
      );
      return item;
    } else if (items) {
      const actualItem = this.dateService.getScheduledItems([items], false);

      return (
        !!items.suspended &&
        actualItem.length &&
        userId === items.creator.id &&
        items.status === 'approved'
      );
    } else return null;
  }
}
