import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  confirmDeletingModalData,
  confirmEditingModalData,
  podsFormData,
} from '../pods/pods';
import { IPod } from 'src/app/shared/models/item.models';
import { FormService } from 'src/app/shared/components/form/form.service';
import { BehaviorSubject, filter, Subject, takeUntil } from 'rxjs';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  createToaster,
  positionType,
} from 'src/app/shared/components/modals/toaster';
import { PodStore } from '../pod-store';
import mixpanel from 'mixpanel-browser';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../firebase/auth.service';
import { ImagesService } from 'src/app/services/images.service';
import { showLoading } from 'src/app/shared/components/modals/loading';

@Component({
  selector: 'app-edit-pod',
  templateUrl: './edit-pod.component.html',
  styleUrls: ['./edit-pod.component.scss'],
})
export class EditPodComponent implements OnInit, OnDestroy {
  @Output() isClosePod = new EventEmitter<boolean>(false);
  @ViewChild('podForm') podForm;

  environment = environment;
  item = { icon: 'radio-button-on', title: environment.app === 'danceApp' ? 'Edit a Group' : 'Edit a Pod' };
  podsFormData = podsFormData;
  id = this.activeRoute.snapshot.params.id;
  disabled: boolean;
  newPod: IPod;
  statusDeleting: boolean;
  statusAdding: boolean;
  pod$ = new BehaviorSubject({} as IPod);
  form: FormGroup;
  initialValues: IPod;
  loader = showLoading();

  currentImage: string;
  loadingImage: boolean = false;

  properties;
  confirmModalData = confirmEditingModalData;
  confirmForDelete = confirmDeletingModalData;
  showConfirmModal: boolean = false;
  showForDelete = false;

  private destroy$ = new Subject<void>();

  constructor(
    private formService: FormService,
    private imagesService: ImagesService,
    private podStore: PodStore,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit() {
    mixpanel.init(environment.mixpanelToken, { debug: true });
    const { uid } = this.authService.isUserAuth();
    mixpanel.identify(uid);
    this.podStore.getItemByByValue({
      documentId: this.id,
    });
    this.imagesListen();

    this.listenStatusChanges();
    this.formService.formData$.next(podsFormData);
  }

  ngOnDestroy() {
    this.imagesService.reset();
    this.destroy$.next();
    this.destroy$.complete();
  }

  trackEvent(event) {
    mixpanel.track(event);
  }

  openConfirmModal() {
    this.showConfirmModal = true;
  }

  close(e) {
    e ? (this.showConfirmModal = true) : null;
  }

  discardChanges(e) {
    if (e) {
      this.isClosePod.next(true);
      this.showConfirmModal = false;
    }
  }

  closeModal(e) {
    if (e) {
      this.showConfirmModal = false;
      this.showForDelete = false;
    }
  }

  onFormGroupChangePod(_event) {
    _event.get('name').validator = Validators.compose([
      Validators.required,
      Validators.minLength(7),
    ]);
    _event.get('description').validator = Validators.compose([
      Validators.required,
      Validators.minLength(20),
    ]);
    this.disabled =
      _event.status === 'INVALID' ||
      this.statusAdding ||
      this.loadingImage ||
      this.statusDeleting;

    const arrControl = this.podsFormData.filter(
      field => field.name === 'joinMechanismArr'
    )[0];
    if (_event.get('joinMechanism').value === 'automatic') {
      this.formService.fieldsConditions.next(['automatic']);
      _event.removeControl('approveMembers');
      _event.removeControl('joinMechanismArr');
    }

    _event
      .get('joinMechanism')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value: string) => {
        if (value === 'automatic') {
          this.formService.fieldsConditions.next(['automatic']);
          _event.removeControl('approveMembers');
          _event.removeControl('joinMechanismArr');
        } else {
          this.formService.fieldsConditions.next([]);
          this.formService.addArrayControl(_event, arrControl);
          _event.addControl(
            'approveMembers',
            new FormControl('everyone can approve')
          );
        }
      });
      if (_event.get('googleMapLink').value !== null) {
        _event.patchValue({
          googleMapLink: null,
        });
        this.formService.location$.next({ googleMapLink: null });
      }

    this.podStore
      .selectItem()
      .pipe(
        takeUntil(this.destroy$),
        filter(data => !!data)
      )
      .subscribe(data => {
        this.initialValues = data;
        this.currentImage = data?.image;
        this.imagesService.compressedImages.next([data?.image]);
        this.pod$.next(data);
        if (data?.joinMechanism) {
          _event.patchValue({
            joinMechanism: data?.joinMechanism,
          });
          if (data.joinMechanism !== 'automatic') {
            _event.addControl(
              'approveMembers',
              new FormControl('everyone can approve')
            );
            if (
              data.joinMechanismArr.length > 1 &&
              data.joinMechanism !== 'automatic'
            ) {
              const control = <FormArray>_event.controls['joinMechanismArr'];
              for (let i = 0; i < data.joinMechanismArr.length - 1; i++) {
                const group = new FormGroup({});
                group.addControl('memberQuestion', new FormControl());
                if (control.controls.length < data.joinMechanismArr.length)
                  control.push(group);
              }
            }
          }

          _event.patchValue({
            name: data.name,
            googleMapLink: data.googleMapLink,
            description: data.description,
            country: data.country,
            memberRights: data.memberRights,
            joinMechanism: data.joinMechanism,
            approveMembers: data.approveMembers,
            joinMechanismArr: data.joinMechanismArr,
          });
          this.formService.location$.next({
            googleMapLink: data.googleMapLink,
          });
          this.disabled = _event.status === 'INVALID';
        }
      });

    this.form = _event;

    _event.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.disabled =
        _event.status === 'INVALID' ||
        this.statusAdding ||
        this.loadingImage ||
        this.statusDeleting;
      this.disabled = _event.status === 'INVALID';

      this.newPod = {
        ...value,
        image: this.currentImage,
        creator: this.initialValues.creator,
      };
    });
    _event
      .get('country')
      .valueChanges.pipe(filter(value => value !== this.initialValues.country))
      .subscribe(() => _event.patchValue({ googleMapLink: null }));
  }
  imagesListen() {
    this.imagesService.compressedImages
      .pipe(takeUntil(this.destroy$))
      .subscribe(images => {
        this.currentImage = images[0] || null;
      });

    this.imagesService.imagesLoading
      .pipe(takeUntil(this.destroy$))
      .subscribe(loading => {
        this.loadingImage = loading;
        this.disabled = loading;
      });
  }

  async updatePod() {
    this.podStore.setLoading();
    this.disabled = true;

    if (this.currentImage) {
      await this.podForm.imageLoader.upLoadImages();
      this.imagesService.imageUrls
        .pipe(takeUntil(this.destroy$))
        .subscribe(urls => {
          if (urls && urls.length) {
            this.setNewItem(urls);
          }
        });
    } else {
      this.setNewItem([]);
    }
  }

  setNewItem(urls) {
    this.newPod.image = urls.length ? urls[0] : null;
    const newPod = Object.assign({ ...this.initialValues, ...this.newPod });

    if (newPod.joinMechanism === 'automatic') {
      newPod.memberRequests.forEach(request =>
        newPod.participants.push(request.id)
      );
      newPod.memberRequests = [];
      newPod.joinMechanismArr = [];
    }
    this.podStore.updateProfile(newPod);
    this.podStore
      .selectLoading()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        if (data) {
          this.disabled = true;
          this.statusAdding = true;
        } else {
          this.isClosePod.next(true);
          createToaster(
            'Successfully updated!',
            'success',
            positionType.BOTTOM
          );
          this.disabled = false;
          this.statusAdding = false;
        }
      });
  }

  deletePod(e: boolean) {
    if (e) {
      this.podStore.removeItem(this.id);
      this.podStore
        .selectLoading()
        .pipe(takeUntil(this.destroy$))
        .subscribe(data => {
          this.statusDeleting = !!data;
          if (data) {
            this.disabled = true;
            this.showForDelete = false;
          } else {
            this.isClosePod.next(true);
            createToaster(
              'Successfully deleted!',
              'success',
              positionType.BOTTOM
            );
            setTimeout(() => {
              this.router.navigate(['merfolk/pods']);
            }, 0);
            this.trackEvent('Pod Deleted');
          }
        });
    }
  }

  private listenStatusChanges() {
    this.podStore
      .selectLoading()
      .pipe(takeUntil(this.destroy$))
      .subscribe(loading => {
        loading
          ? this.loader.then(data => data.present())
          : this.loader.then(data => data.dismiss());
      });
  }
}
