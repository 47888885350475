import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  Observable,
  Subject,
  Subscription,
  interval,
  map,
  filter,
  combineLatest,
  BehaviorSubject,
} from 'rxjs';
import { PaymentsStore } from '../payments/payments-store';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { IToolbarData } from '../shared/models/components-data.models';
import { Navigation, SwiperOptions } from 'swiper';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Platform } from '@ionic/angular';
import {
  quoteDus,
  travelingQuotes,
} from '../shared/components/modals/traveling-modal/traveling-quotes';
import mixpanel from 'mixpanel-browser';
import { environment } from '../../environments/environment';
import { UserService } from '../auth/user.service';
import { getAuth } from 'firebase/auth';
import { IUser } from '../auth/auth.models';
import { DateService } from '../services/date.service';
import { SecondHandStore } from '../bazaar/second-hand/second-hand-store';
import { EventsStore } from '../events/events-store';
import { JobsStore } from '../bazaar/jobs-services/jobs/jobs-store';
import { EventsService } from '../events/events.service';
import { JobsService } from '../bazaar/jobs-services/jobs/jobs.service';
import { WonderPush } from '@awesome-cordova-plugins/wonderpush/ngx';
import { PushNotificationsService } from '../services/push-notifications.service';
import { AppService } from '../app.service';

const TOOLBAR_SWIPER: IToolbarData = {
  arrow: true,
  btnSlot: 'end',
  title: 'Notifications',
};
@Component({
  providers: [WonderPush],
  selector: 'app-home',
  templateUrl: 'main-menu.page.html',
  styleUrls: ['main-menu.page.scss'],
})
export class MainMenuPage implements OnDestroy {
  environment = environment;
  authUserId = '';
  config: SwiperOptions;
  balanceInfo;
  creator;
  disableTreasureButton;
  isSubscriptionModalOpen = false;
  isSubscriptionForPearlsModalOpen = false;
  isBuyPearlsModalOpen = false;
  hours = 0;
  minutes = 0;
  seconds = 0;
  isTimerActive = false;
  loading = true;
  modalLoader = false;
  routerTitle: string;
  notifications;
  TOOLBAR_SWIPER = TOOLBAR_SWIPER;
  isOpen = false;
  subscriptionPeriod;
  pearlsNumber;
  subscriptionByPearlsPrice;
  countdown: Observable<any>;
  intervalId;
  timerInfo;
  currentUser;
  properties;
  isUserPremium;
  showTravelingModal = false;
  travelingBg: string;
  random: number;
  routerLink: string[] | null;
  query = window.matchMedia('(max-width: 400px)');

  isMobile = this.platform.platforms().includes('capacitor');
  isIosApp = this.platform.platforms().includes('ios') && this.isMobile;
  userSubscriptionInfo;
  userSubInfo;
  isAdmin = false;
  isPremium: boolean;
  inviters: IUser[];
  premiumNotifications: any[] = [];
  private subscription: Subscription;
  private snapshot: Subscription;
  private balanceInfosnapshot: Subscription;
  private timerInfoSnaphot: Subscription;
  private destroy$ = new Subject<void>();
  private timerSubscription$: Subscription;
  private activeSession = false;
  private inAppNotificationsMustBeHidden;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private fireStore: AngularFirestore,
    private paymentsStore: PaymentsStore,
    private platform: Platform,
    private userService: UserService,
    private dateService: DateService,
    private wonderPush: WonderPush,
    private notificationsService: PushNotificationsService,
    private appService: AppService
  ) {}

  async ionViewDidEnter() {
    mixpanel.init(environment.mixpanelToken, { debug: true });
    const { uid } = getAuth().currentUser;
    this.authUserId = uid;
    this.getUser();

    this.paymentsStore.getItemByByValue({ documentId: this.authUserId });
    if (
      this.activatedRoute.snapshot.queryParams.pearls === 'success' ||
      this.activatedRoute.snapshot.queryParams.subscription === 'success'
    ) {
      if (this.activatedRoute.snapshot.queryParams.pearls === 'success') {
        this.pearlsNumber = this.activatedRoute.snapshot.queryParams.number;
        this.isBuyPearlsModalOpen = true;
      }
      if (this.activatedRoute.snapshot.queryParams.subscription === 'success') {
        this.subscriptionPeriod =
          this.activatedRoute.snapshot.queryParams.period;
        this.isSubscriptionModalOpen = true;
      }
      if (
        this.activatedRoute.snapshot.queryParams.subscription === 'success' &&
        this.activatedRoute.snapshot.queryParams.type === 'free'
      ) {
        this.subscriptionPeriod =
          this.activatedRoute.snapshot.queryParams.period;
        this.subscriptionByPearlsPrice =
          this.activatedRoute.snapshot.queryParams.price;
        this.isSubscriptionForPearlsModalOpen = true;
      }
    } else {
      this.getInfo();
    }
    this.paymentsStore.selectTreasureButtonStatus().subscribe(isOpen => {
      this.disableTreasureButton = isOpen;
    });

    this.trackEvent('Visit Screen', 'screen-name', 'Home');
  }

  ionViewDidLeave() {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.timerSubscription$) {
      this.timerSubscription$.unsubscribe();
    }

    this.loading = false;
    this.authUserId = '';
    this.balanceInfo = null;
    this.paymentsStore.destroy$;
    this.paymentsStore.logOut();
    this.hours = 0;
    this.minutes = 0;
    this.seconds = 0;
    if (this.snapshot) {
      this.snapshot.unsubscribe();
    }
    if (this.balanceInfosnapshot) {
      this.balanceInfosnapshot.unsubscribe();
    }
    if (this.timerInfoSnaphot) {
      this.timerInfoSnaphot.unsubscribe();
    }
  }

  ionViewWillLeave() {
    this.loading = false;
    if (this.timerSubscription$) {
      this.timerSubscription$.unsubscribe();
    }

    this.destroy$.next();
    this.destroy$.complete();
    this.authUserId = '';
    this.balanceInfo = null;
    this.paymentsStore.destroy$;
    this.paymentsStore.logOut();
    this.hours = 0;
    this.minutes = 0;
    this.seconds = 0;
    if (this.snapshot) {
      this.snapshot.unsubscribe();
    }
    if (this.balanceInfosnapshot) {
      this.balanceInfosnapshot.unsubscribe();
    }
    if (this.timerInfoSnaphot) {
      this.timerInfoSnaphot.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    if (this.timerSubscription$) {
      this.timerSubscription$.unsubscribe();
    }

    this.loading = false;
    this.destroy$.next();
    this.destroy$.complete();
    this.authUserId = '';
    this.balanceInfo = null;
    this.paymentsStore.destroy$;
    this.paymentsStore.logOut();
    this.hours = 0;
    this.minutes = 0;
    this.seconds = 0;
    if (this.snapshot) {
      this.snapshot.unsubscribe();
    }
    if (this.balanceInfosnapshot) {
      this.balanceInfosnapshot.unsubscribe();
    }
    if (this.timerInfoSnaphot) {
      this.timerInfoSnaphot.unsubscribe();
    }
  }

  trackEvent(event, parameter, parameterValue) {
    if (parameter === 'screen-name') {
      this.properties = {
        'screen-name': parameterValue,
      };
    }

    mixpanel.track(event, this.properties);
  }

  navigateToChat() {
    if (!this.isPremium && !this.isAdmin) {
      const max = quoteDus.length;
      this.random = Math.round(0 - 0.5 + Math.random() * (max + 1));

      this.appService.travelingData.next({
        travelingRandom: this.random,
        travelingTitle: 'Chat',
        travelingLink: this.currentUser?.discordLink ? null : "['/chat']",
        travelingBg:
          "url('assets/bg/bg-dus/Dancers.webp')  no-repeat center center / cover",
      });
      this.appService.showTravelingModal.next(true);
    } else {
      if (this.currentUser?.discordLink) {
        this.trackEvent('Visit Screen', 'screen-name', 'Discord');
        window.open(environment.discordServerLink, '_blank');
      } else {
        this.trackEvent('Visit Screen', 'screen-name', 'Discord');
        this.router.navigate(['/chat']);
      }
    }
  }

  public startTimer(lastOpen): void {
    const endTime = new Date(lastOpen + 86400000);
    this.countdown = interval(1000).pipe(
      map(() => Math.floor(endTime.getTime() - new Date().getTime()))
    );
    this.timerSubscription$ = this.countdown.subscribe(secondsLeft => {
      if (secondsLeft <= 0) {
        this.isTimerActive = false;
        this.timerSubscription$.unsubscribe();
        this.paymentsStore.updateTreasureButtonStatus(false);
        this.loading = false;
      } else {
        this.isTimerActive = true;
        this.hours = Math.floor(secondsLeft / 3600000);
        this.minutes = Math.floor((secondsLeft - this.hours * 3600000) / 60000);
        this.seconds = Math.floor(
          (secondsLeft - (this.hours * 3600000 + this.minutes * 60000)) / 1000
        );
        this.paymentsStore.updateTreasureButtonStatus(true);
        this.loading = false;
      }
    });
  }

  getInfo() {
    this.listenBalanceInfoDetails();
    this.listenTimerInfoDetails();
    this.getNotification();
    this.router.events.subscribe(event => {
      if (event instanceof RoutesRecognized) {
        this.isOpen = false;
      }
    });
  }

  async getNotification() {
    this.loading = true;
    this.snapshot = this.fireStore
      .collection('notifications')
      .doc(this.authUserId)
      .valueChanges()
      .subscribe(data => {
        this.notifications = (data as any).notifications.reverse();
        if (!!this.notifications.length) {
          this.notifications = this.notifications
            .filter(item => item.type !== 'push')
            .filter(item => this.dateService.checkSuspendedNotification(item))
            .filter(item =>
              this.isPremium
                ? item.type !== 'suspendedAppPremium' &&
                  item.type !== 'suspendedPersonalPremium' &&
                  item.type !== 'suspendedPaidPremium'
                : item
            );
        }

        const invitations = (data as any).notifications.filter(
          not => not.type === 'newInvite'
        );

        if (invitations.length) {
          this.inviters = invitations[0].data?.reduce((accum, props) => {
            if (accum?.length) {
              if (accum?.find(item => item.userId === props.inviterId)) {
                return accum;
              } else {
                return [
                  ...accum,
                  {
                    userId: props.inviterId,
                    username: props.inviterUsername,
                    profilePicture: props.inviterPicture,
                  },
                ];
              }
            } else {
              return [
                {
                  userId: props.inviterId,
                  username: props.inviterUsername,
                  profilePicture: props.inviterPicture,
                },
              ];
            }
          }, []);
        } else {
          this.isOpen = this.notifications.length > 0 ? true : false;
        }

        this.isOpen = this.notifications.length > 0 ? true : false;
        if (this.isOpen) {
          this.config = {
            modules: [Navigation],
            allowTouchMove: false,
            slideNextClass: '.continue__button',
            navigation: {
              nextEl: '.continue__button',
            },
          };
        }
      });
  }

  private listenBalanceInfoDetails() {
    this.balanceInfosnapshot = this.fireStore
      .collection('info')
      .doc(this.authUserId)
      .valueChanges()
      .subscribe((data: any) => {
        this.balanceInfo = data as any;
        this.isAdmin = data?.role === 'admin';
      });
  }

  private listenTimerInfoDetails() {
    this.timerInfoSnaphot = this.fireStore
      .collection('info')
      .doc(this.authUserId)
      .get()
      .subscribe(data => {
        this.timerInfo = data.data() as any;
        if (this.timerInfo.lastTreasureOpening) {
          this.startTimer(this.timerInfo.lastTreasureOpening);
        } else {
          this.isTimerActive = false;
          this.paymentsStore.updateTreasureButtonStatus(false);
          this.loading = false;
        }
      });
  }

  private getUser() {
    this.loading = true;
    this.userService.currentUser$
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user.uid)
      )
      .subscribe(user => {
        this.activeSession = this.currentUser?.uid === user?.uid;
        this.currentUser = user;
        this.isPremium = this.userService.isPremium;
        this.isAdmin = this.userService.isAdmin;
        mixpanel.identify(user.uid);
        this.authUserId = user.uid;
        this.listenBalanceInfoDetails();
        this.loading = false;
      });
  }

  travelTo(router, title, bg) {
    const max = quoteDus.length;
    this.random = Math.round(0 - 0.5 + Math.random() * (max + 1));
    if (!this.isPremium && !this.isAdmin) {
      const max = travelingQuotes.length;
      this.random = Math.round(0 - 0.5 + Math.random() * (max + 1));

      this.appService.travelingData.next({
        travelingRandom: this.random,
        travelingTitle: title,
        travelingLink: router,
        travelingBg: bg,
      });
      this.appService.showTravelingModal.next(true);
    }
    this.router.navigate(router);

    this.trackEvent('Visit Screen', 'screen-name', title);
  }

  closeTravelingModal(e) {
    this.showTravelingModal = !e;
  }
}
