import { FormField, FormFieldTypes } from 'src/app/shared/components/form/form';
import { environment } from 'src/environments/environment';

export const secondHandMenuData: FormField[] = [
  {
    name: 'myContent',
    type: FormFieldTypes.TOGGLE,
    label: 'See my content only',
    required: false,
    icon: 'person',
    line: 'full',
  },
  {
    name: 'category',
    type: FormFieldTypes.SELECT,
    label: 'Category',
    multiple: true,
    required: false,
    line: 'full',
    options: environment.app === 'danceApp' ? [
      'Tops',
      'Bottoms',
      'Complete Outfits',
      'Footwear',
      'Accessories',
      'Training Gear',
      "Men's",
      "Women's",
      'Unisex'
    ] : [
      'MerTails',
      'Fins',
      'Swimming Tops/Bras',
      'Accessories',
      'Apparel',
    ],
    icon: 'grid',
  },
  {
    name: 'priceRange',
    type: FormFieldTypes.SELECT,
    label: 'Price Range',
    multiple: false,
    required: false,
    line: 'full',
    options: ['0-10', '11-50', '51-100', '100+'],
    icon: 'wallet',
  },
  {
    name: 'condition',
    type: FormFieldTypes.SELECT,
    label: 'Condition',
    multiple: false,
    required: false,
    line: 'full',
    options: ['Used - Like New', 'Used - Good', 'Used - Fair'],
    icon: 'pricetag',
  },
  {
    name: 'location',
    type: FormFieldTypes.SELECT,
    label: 'Location',
    multiple: true,
    required: false,
    line: 'full',
    options: [
      'Africa',
      'Asia',
      'Oceania',
      'North America',
      'Europe',
      'South America',
    ],
    icon: 'location',
  },
  {
    name: 'shippingOption',
    type: FormFieldTypes.SELECT,
    label: 'Shipping Option',
    multiple: false,
    required: false,
    line: 'full',
    options: ['worldwide', 'countrywide', 'pick-up only'],
    icon: 'truck',
  },
  {
    name: 'swapOption',
    type: FormFieldTypes.TOGGLE,
    label: 'Swap Option',
    required: false,
    line: 'full',
    icon: 'swap-horizontal',
  },
];

export const newSecondFormData = [
  {
    name: 'name',
    type: FormFieldTypes.TEXT_INPUT,
    label: 'Name*',
    required: true,
    icon: 'person',
  },
  {
    name: 'images',
    type: FormFieldTypes.ARRAY,
    icon: 'image',
    label: 'image',
    children: [
      {
        name: 'image',
        type: FormFieldTypes.FILE,
        label: 'image',
        required: false,
        icon: 'image',
      },
    ],
    template: {
      name: 'image',
      type: FormFieldTypes.FILE,
      label: 'image',
      required: false,
      icon: 'image',
    },
  },

  {
    name: 'country',
    type: FormFieldTypes.SELECT,
    label: 'Country*',
    required: true,
    multiple: false,
    icon: 'location',
  },
  {
    name: 'state',
    type: FormFieldTypes.TEXT_INPUT,
    label: 'State*',
    required: true,
    icon: 'locate',
  },
  {
    name: 'description',
    type: FormFieldTypes.TEXT_AREA,
    label: 'Description',
    required: false,
    icon: 'clipboard',
  },
  {
    name: 'price',
    type: FormFieldTypes.GROUP,
    children: [
      {
        name: 'value',
        type: FormFieldTypes.TEXT_INPUT,
        label: 'Price*',
        required: true,
        lines: 'full',
      },
      {
        name: 'currency',
        type: FormFieldTypes.SELECT,
        label: 'Currency',
        required: true,
        lines: 'none',
        defaultValue: '$',
        options: ['$ - USD', '€ - EUR', '¥ - CHINESE YUAN'],
      },
    ],
    icon: 'wallet',
  },
  {
    name: 'condition',
    type: FormFieldTypes.SELECT,
    label: 'Condition*',
    multiple: false,
    required: true,
    line: 'full',
    options: ['Used - Like New', 'Used - Good', 'Used - Fair'],
    icon: 'pricetag',
  },
  {
    name: 'category',
    type: FormFieldTypes.SELECT,
    label: 'Category*',
    multiple: false,
    required: true,
    line: 'full',
    options: [
      'MerTails',
      'Fins',
      'Swimming Tops/Bras',
      'Accessories',
      'Apparel',
    ],
    icon: 'grid',
  },
  {
    name: 'shippingOption',
    type: FormFieldTypes.SELECT,
    label: 'Shipping Option*',
    multiple: false,
    required: true,
    line: 'full',
    options: ['worldwide', 'countrywide', 'pick-up only'],
    icon: 'truck',
  },
  {
    name: 'swapOption',
    type: FormFieldTypes.TOGGLE,
    label: 'Swap Option',
    required: false,
    line: 'full',
    icon: 'swap-horizontal',
  },
];

export const successSecondModalData = {
  title: 'Thanks for adding content!',
  pic: 'some sea animal with magnifying glass',
  description:
    "You'll get rewarded as soon as your content has been verified by 3 moderators.",
  okBtn: {
    title: 'CONTINUE',
  },
  cancelBtn: {
    title: 'SPLASH MERFRIENDS',
  },
};

export const confirmAddingModalData = {
  title: 'Add a Second Hand Item',
  icon: 'shirt',
  okBtn: {
    title: 'YES, DISCARD',
  },
  cancelBtn: {
    title: 'NO, GO BACK',
  },
};

export const confirmEditingModalData = {
  title: 'Edit a Second Hand Item',
  icon: 'shirt',
  okBtn: {
    title: 'YES, DISCARD',
  },
  cancelBtn: {
    title: 'NO, GO BACK',
  },
};

export const confirmDeletingModalData = {
  title: 'Deleting a Second Hand Item',
  icon: 'shirt',
  okBtn: {
    title: 'YES, DELETE',
  },
  cancelBtn: {
    title: 'NO, KEEP',
  },
};
export interface ISecondHandFilter {
  myContent: boolean;
  category?: string;
  priceRange?: string;
  condition?: string;
  location?: string;
  shippingOption?: string;
  swapOption: boolean;
  creator?: string;
}
export const conditionOptions = [
  'Used - Like New',
  'Used - Good',
  'Used - Fair',
];
export const categoryOptions = environment.app === 'danceApp' ? [
  'Tops',
  'Bottoms',
  'Complete Outfits',
  'Footwear',
  'Accessories',
  'Training Gear',
  "Men's",
  "Women's",
  'Unisex'
] : [
  'MerTails',
  'Fins',
  'Swimming Tops/Bras',
  'Accessories',
  'Apparel',
];
export const shippingOptions = ['worldwide', 'countrywide', 'pick-up only'];
export const formAddTitle = { icon: 'shirt', title: 'Add a Second hand item' };
export const formEditTitle = {
  icon: 'shirt',
  title: 'Edit a Second Hand Item',
};

export const secondHandPremiumModalData =
  "Have a lot things you're no longer using and you'd like to sell them to others or swap for something else? As a premium mer you'll be good to go!";

export const secondHandDiscordModalData = {
  icon: 'shirt',
  title: 'Add a Second Hand Item',
  text: "In order for potential buyers to contact you, you need to join our chat group on Discord first. So let's get you set up.",
};
