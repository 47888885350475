import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-pending-approval-search',
  templateUrl: './pending-approval-search.component.html',
  styleUrls: ['./pending-approval-search.component.scss'],
})
export class PendingApprovalSearchComponent {
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSearchChange = new EventEmitter<string>();
  constructor() {}

  searchItem(event) {
    this.onSearchChange.emit(event.target.value);
  }
}
