import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateAgo',
})
export class DateAgoPipe implements PipeTransform {
  transform(
    value: {
      seconds?: string;
      nanoseconds?: string | number;
      _seconds?: string;
      _nanoseconds?: string | number;
    },
    detail?: string,
    ...args: unknown[]
  ): unknown {
    const seconds = value.seconds
      ? Math.floor((+new Date() - +new Date(+`${+value.seconds}000`)) / 1000)
      : Math.floor((+new Date() - +new Date(+`${+value._seconds}000`)) / 1000);

    if (seconds < 29) return 'Just now';
    const intervals = {
      year: 31536000,
      m: 2592000,
      w: 604800,
      d: 86400,
      h: 3600,
      min: 60,
      s: 1,
    };
    const detailsIntervals = {
      year: 31536000,
      month: 2592000,
      week: 604800,
      day: 86400,
      hour: 3600,
      minute: 60,
      second: 1,
    };
    let counter;
    if (detail) {
      for (const i in detailsIntervals) {
        counter = Math.floor(seconds / detailsIntervals[i]);
        if (counter > 0)
          if (counter === 1) {
            return counter + ' ' + i + ' ago'; // singular (1 day ago)
          } else {
            return counter + ' ' + i + 's ago'; // plural (2 days ago)
          }
      }
    } else {
      for (const i in intervals) {
        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0 && !detail) return counter + ' ' + i + ' ago';
      }
    }
    return value;
  }
}
