import { Injectable } from '@angular/core';
import { CommonComponentStore } from '../shared/component-store/common-component-store';
import { PlanetService } from './planet.service';
import { PlanetItem } from './planet';
import { filter, Observable, take, tap } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PlanetStore extends CommonComponentStore<PlanetItem> {
  constructor(private planetService: PlanetService) {
    super(planetService, {
      createdId: null,
      item: null,
      items: null,
      loading: false,
      showFormModal: false,
      showAddModal: false,
      showEditModal: false,
      showPendingModal: false,
      collectionName: 'planet',
    });
  }

  updateProfile(item) {
    return this.effect((item: Observable<any>) =>
      item.pipe(
        filter(item => !!item),
        tap(() => this.patchState({ loading: true })),
        switchMap(profile =>
          this.selectItem().pipe(
            take(1),
            switchMap(item => this.updateItem(profile, profile))
          )
        )
      )
    )(item);
  }

  selectShowFormModal() {
    // TODO update
    // @ts-ignore
    return this.select(state => state.showFormModal);
  }

  updateShowFormModal(value) {
    // TODO update
    // @ts-ignore
    this.patchState({ showFormModal: value });
  }

  selectShowAddFormModal() {
    // TODO update
    // @ts-ignore
    return this.select(state => state.showAddModal);
  }

  updateShowAddFormModal(value) {
    // TODO update
    // @ts-ignore
    this.patchState({ showAddModal: value });
  }

  selectShowEditFormModal() {
    // TODO update
    // @ts-ignore
    return this.select(state => state.showEditModal);
  }

  updateShowEditFormModal(value) {
    // TODO update
    // @ts-ignore
    this.patchState({ showEditModal: value });
  }

  selectShowPendingModal() {
    // TODO update
    // @ts-ignore
    return this.select(state => state.showPendingModal);
  }

  updateShowPendingModal(value) {
    // TODO update
    // @ts-ignore
    this.patchState({ showPendingModal: value });
  }
}
