import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BannerComponent } from './banner.component';
import { AdsenseModule } from 'ng2-adsense';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [BannerComponent],
  imports: [
    AdsenseModule.forRoot({
      adClient: 'ca-pub-2609549176457465',
      adSlot: 2071585132,
    }),
    CommonModule,
    IonicModule,
  ],
  exports: [BannerComponent],
})
export class BannerModule {}
