import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-lets-go-chat-modal',
  templateUrl: './lets-go-chat-modal.component.html',
  styleUrls: ['./lets-go-chat-modal.component.scss'],
})
export class LetsGoChatModalComponent {
  @Input() discordModalData;
  @Input() isOpenDiscordModal;
  @Output() isOpenDiscordModalChange = new EventEmitter<any>();
  environment = environment;
  constructor(
    private router: Router
    ) {
    }
  navigateToChat() {
    this.isOpenDiscordModalChange.next(false);
    setTimeout(() => {
      this.router.navigate(['/chat']);
    }, 0);
  }
}
