import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ViewDidEnter } from '@ionic/angular';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements AfterViewInit {
  isAdShow = false;
  constructor() {}

  @ViewChild('ins') ins;
  ngAfterViewInit() {
    this.isAdShow = this.ins.nativeElement.getAttribute('data-ad-status');

  }

}
