import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TutorialComponent } from './tutorial.component';
import { RouterModule } from '@angular/router';
import { TutorialService } from './tutorial.service';
import { SwiperDirective } from '../shared/directives/swiper.directive';
import { TutorialFirstPageComponent } from './tutorial-first-page/tutorial-first-page.component';
import { TutorialSecondPageComponent } from './tutorial-second-page/tutorial-second-page.component';
import { TutorialThirdPageComponent } from './tutorial-third-page/tutorial-third-page.component';
import { TutorialFourthPageComponent } from './tutorial-fourth-page/tutorial-fourth-page.component';
import { TutorialRoutingModule } from './tutorial-routing.module';

@NgModule({
  declarations: [
    TutorialComponent,
    TutorialFirstPageComponent,
    TutorialSecondPageComponent,
    TutorialThirdPageComponent,
    TutorialFourthPageComponent
  ],
  imports: [CommonModule, IonicModule, RouterModule, SwiperDirective, TutorialRoutingModule],
  exports: [SwiperDirective],
  providers: [TutorialService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TutorialModule {}
