import { Injectable } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { CrudHelper } from '../../firebase/helpers/crudHelper';
import { CommonService } from '../../shared/component-store/common-service';
import { AuthService } from 'src/app/firebase/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class SecondHandService extends CommonService {
  isOpenForm = new BehaviorSubject(false);
  priceRange = new BehaviorSubject<string>('');
  userId: string;

  constructor(
    private fb: FormBuilder,
    private firebaseHelperService: CrudHelper,
    private authService: AuthService
  ) {
    super(firebaseHelperService);
  }

  addImage(images) {
    let i = images.length;
    i = this.fb.group({
      image: [null],
    });
    images.push(i);
  }

  deleteImage(images: FormArray, i: number) {
    images.removeAt(i);
  }

  getProfile(email: string) {
    return this.firebaseHelperService.searchByField('users', 'email', email);
  }

  getItems({ collectionName, filter }) {
    const queryFn = ref => {
      let query = ref;

      const {
        category,
        condition,
        location,
        shippingOption,
        swapOption,
        status,
        creator,
        myContent,
      } = filter;

      if (category && category.length > 0) {
        query = query.where('category', 'in', category);
      }

      if (condition) {
        query = query.where('condition', '==', condition);
      }

      if (location && location.length > 0) {
        query = query.where('country.region', 'in', location);
      }

      if (shippingOption) {
        query = query.where('shippingOption', '==', shippingOption);
      }

      if (swapOption) {
        query = query.where('swapOption', '==', swapOption);
      }

      if (status) {
        query = query.where('status', '==', status);
      }

      if (creator || myContent) {
        const user = creator || this.authService.isUserAuth().uid;
        query = query.where('creator.id', '==', user);
        query = query.where('status', 'in', [
          'approved',
          'pending',
          'rejected',
          'pending-repeat',
        ]);
      }

      if (!status && !creator && !myContent) {
        query = query
          .where('status', '==', 'approved');
      }

      query = query.orderBy('createdAt', 'desc');

      return query;
    };

    return this.firebaseHelperService.getCollectionItems({
      collectionName,
      queryFn,
    });
  }

  checkFilter(filter) {
    const result =
      filter === false ||
      ((filter.category === null || !filter.category?.length) &&
        (filter.location === null || !filter.location?.length) &&
        (filter.condition === null || !filter.condition?.length) &&
        !filter.myContent &&
        filter.priceRange === null &&
        filter.shippingOption === null &&
        !filter.swapOption);
    return !result;
  }

  getMyItems(collectionName, creator) {
    const queryFn = ref => {
      let query = ref;

      query = query.where('creator.id', '==', creator);

      return query;
    };

    return this.firebaseHelperService.getCollectionItems({
      collectionName,
      queryFn,
    });
  }

  priceRangeFilter(item, range) {
    switch (range) {
      case '100+':
        return +item.price.value > 100;
      case '0-10':
        return +item.price.value <= 10;
      case '11-50':
        return +item.price.value <= 50 && +item.price.value >= 11;
      case '51-100':
        return +item.price.value <= 100 && +item.price.value >= 51;
      default:
        return null;
    }
  }
}
