import { Injectable } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { CrudHelper } from 'src/app/firebase/helpers/crudHelper';
import { CommonService } from 'src/app/shared/component-store/common-service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class CommunityService extends CommonService {
  isOpenForm = new BehaviorSubject(false);
  allUsers$ = new BehaviorSubject([]);

  constructor(
    private fb: FormBuilder,
    private firebaseHelperService: CrudHelper
  ) {
    super(firebaseHelperService);
  }

  addImage(images) {
    let i = images.length;
    i = this.fb.group({
      image: [null],
    });
    images.push(i);
  }

  deleteImage(images: FormArray, i: number) {
    images.removeAt(i);
  }

  getProfile(email: string) {
    return this.firebaseHelperService.searchByField('users', 'email', email);
  }

  getItems({ collectionName, filter }) {
    const queryFn = ref => {
      let query = ref;

      const { country, experience, gender, followers, following, danceStyle } = filter;

      if (country && country.length > 0) {
        query = query.where('country', 'in', country);
      }

      if (gender && gender.length > 0) {
        query = query.where('gender', 'in', gender);
      }

      if(environment.app === 'danceApp') {
        if(danceStyle && danceStyle.length > 0) {
          query = query.where('danceStyle', 'in', danceStyle);
        }
      } else {
        if (experience && experience.length > 0) {
          query = query.where('mermaidExpLvl', 'in', experience);
        }
      }

      if (followers && followers.length > 0) {
        query = query.where('uid', 'in', followers);
      }

      if (following && following.length > 0) {
        query = query.where('uid', 'in', following);
      }
      // TODO check why this method doesn't work
      // query = query.orderBy('username', 'asc');

      return query;
    };

    return this.firebaseHelperService.getCollectionItems({
      collectionName,
      queryFn,
    });
  }

  checkFilter(filter) {
    let result;
    if(environment.app === 'danceApp') {
      result = filter === false ||
      ((filter.danceStyle === null || !filter.danceStyle?.length) &&
        (filter.country === null || !filter.country?.length) &&
        (filter.gender === null || !filter.gender?.length) &&
        !filter.myContent);
    } else {
      result = filter === false ||
      ((filter.experience === null || !filter.experience?.length) &&
        (filter.country === null || !filter.country?.length) &&
        (filter.gender === null || !filter.gender?.length) &&
        !filter.myContent);
    }
    return !result;
  }
}
