export const quotes = [
  {
    quote:
      '“You will love the ocean. It makes you feel small, but not in a bad way. Small because you realize you’re part of something bigger.” ',
    author: 'Lauren Myracle',
    img: './../../assets/mood-pics/dancing_fish_background_watercolor_format.webp',
  },
  {
    quote:
      '“We are like islands in the sea, separate on the surface but connected on the deep.” ',
    author: 'William James',
    img: './../../assets/mood-pics/turtle_watercolor_background.webp',
  },
  {
    quote:
      '“Dance with the waves, move with the sea, let the rhythm of the water set your soul free.” ',
    author: 'Christy Ann Martine',
    img: './../../assets/mood-pics/dolphines_watercolor_background.webp',
  },
  {
    quote:
      '“The sea, once it casts its spell, holds one in its net of wonder forever.” ',
    author: 'Jacques Cousteau',
    img: './../../assets/mood-pics/jellyfish_watercolor_background.webp',
  },
  {
    quote:
      '“If the ocean can calm itself, so can you. We are both salt water mixed with air” ',
    author: 'Nayyirah Waheed',
    img: './../../assets/mood-pics/seahorse_watercolor_background.webp',
  },
  {
    quote:
      '“We ourselves feel what we are doing is just a drop in the ocean. But the ocean would be less because of that missing drop.” ',
    author: 'Mother Teresa',
    img: './../../assets/mood-pics/whale_watercolor_background.webp',
  },
  {
    quote:
      '“The ocean stirs the heart, inspires the imagination and brings eternal joy to the soul.” ',
    author: 'Robert Wylan',
    img: './../../assets/mood-pics/stingray_watercolor_background.webp',
  },
];

export const quoteDus = [
  {
    quote: 'Dance is the hidden language of the soul, speaking in movements louder than words.',
    author: 'Martha Graham',
    img: './../../assets/mood-pics/dancer-5576002_1920_45_11zon.webp'
  },
  {
    quote: 'Each step on the dance floor is a step away from the mundane and into the magic of music.',
    author: 'Unknown',
    img: './../../assets/mood-pics/ease-3134828_1920_42_11zon.webp'
  },
  {
    quote: 'In the rhythm of life, dance is the verse that makes our souls rhyme.',
    author: 'Unknown',
    img: './../../assets/mood-pics/pexels-alex-qian-2304841_35_11zon.webp'
  },
  {
    quote: 'Dancing is like dreaming with your feet—make each step count!',
    author: 'Unknown',
    img: './../../assets/mood-pics/pexels-cottonbro-studio-4737984_29_11zon.webp'
  },
  {
    quote: 'Let the music steal your body; the dance will return it with interest.',
    author: 'Unknown',
    img: './../../assets/mood-pics/pexels-pixabay-39572_17_11zon.webp'
  },
  {
    quote: 'Dance to express, not to impress; let every movement tell your story.',
    author: 'Unknown',
    img: './../../assets/mood-pics/pexels-saeid-anvar-898220_10_11zon.webp'
  },
  {
    quote: 'When in doubt, dance it out! The best conversations between mind and body happen on the dance floor.',
    author: 'Unknown',
    img: './../../assets/mood-pics/pexels-soner-arkan-12943931_9_11zon.webp'
  },
  {
    quote: 'Dancing is the art of getting your feet to whisper and your soul to scream.',
    author: 'Unknown',
    img: './../../assets/mood-pics/agility-1850711_1920_11zon.webp'
  },
  {
    quote: 'Dance is the only art wherein we ourselves are the stuff of which it is made.',
    author: 'Unknown',
    img: './../../assets/mood-pics/ballet-1262613_1920_11zon.webp'
  },
  {
    quote: 'Leap into the beat and let your spirit soar on the wings of dance.',
    author: 'Unknown',
    img: './../../assets/mood-pics/bellydancer-5117920_1920_50_11zon.webp'
  },
  {
    quote: 'Dance is the rhythm of the universe translated into human motion.',
    author: 'Unknown',
    img: './../../assets/mood-pics/couple-4325416_1920_49_11zon.webp'
  },
  {
    quote: 'Beneath every dancer’s movement is a heartbeat syncing with the melody of life.',
    author: 'Unknown',
    img: './../../assets/mood-pics/dance-1940245_1920_47_11zon.webp'
  },
  {
    quote: 'In each twirl, find the freedom that only dancers know.',
    author: 'Unknown',
    img: './../../assets/mood-pics/dance-aerials-4193863_1920_48_11zon.webp'
  },
  {
    quote: "The dance floor is a canvas, and your feet are brushes painting your soul’s desires.",
    author: 'Unknown',
    img: './../../assets/mood-pics/dancer-4516788_1920_46_11zon.webp'
  },
  {
    quote: "Move to the sound of your dreams, and let the dance guide you to who you're meant to become.",
    author: 'Unknown',
    img: './../../assets/mood-pics/dancer-7357488_1920_44_11zon.webp'
  },
  {
    quote: "Dance is the boldest act of self-expression—one beat, one step, one story at a time.",
    author: 'Unknown',
    img: './../../assets/mood-pics/doll-976355_1920_43_11zon.webp'
  },
  {
    quote: "Let your feet do the talking, and express what words cannot capture.",
    author: 'Unknown',
    img: './../../assets/mood-pics/flamenco-1046485_1920_41_11zon.webp'
  },
  {
    quote: "In the echo of ancient rhythms, dance connects us to the eternal pulse of humanity.",
    author: 'Unknown',
    img: './../../assets/mood-pics/freedom-2940655_1920_40_11zon.webp'
  },
  {
    quote: "Every dance is a love affair between the heart and the beat.",
    author: 'Unknown',
    img: './../../assets/mood-pics/hip-hop-2159913_1920_39_11zon.webp'
  },
  {
    quote: "Dance like the stars are watching, and express the universe within you.",
    author: 'Unknown',
    img: './../../assets/mood-pics/holi-2416686_1920_37_11zon.webp'
  },
  {
    quote: "Dance first. Think later. It's the natural order.",
    author: 'Samuel Beckett',
    img: './../../assets/mood-pics/legs-191543_1920_38_11zon.webp'
  },
  {
    quote: "Dancing is creating a sculpture that is visible only for a moment.",
    author: 'Erol Ozan',
    img: './../../assets/mood-pics/pexels-ana-bregantin-1930364_36_11zon.webp'
  },
  {
    quote: "Let us dance in the sun, wearing wild flowers in our hair.",
    author: 'Susan Polis Schutz',
    img: './../../assets/mood-pics/pexels-anastasia-shuraeva-8752654_33_11zon.webp'
  },
  {
    quote: "To watch us dance is to hear our hearts speak.",
    author: 'Hopi Indian Saying',
    img: './../../assets/mood-pics/pexels-anastasia-shuraeva-8935911_34_11zon.webp'
  },
  {
    quote: "Dancers don’t need wings to fly.",
    author: 'Unknown',
    img: './../../assets/mood-pics/pexels-andrew-4226422_32_11zon.webp'
  },
  {
    quote: "Life is short and there will always be dirty dishes, so let's dance.",
    author: 'James Howe',
    img: './../../assets/mood-pics/pexels-anna-tarazevich-6900842_31_11zon.webp'
  },
  {
    quote: "When you dance, your purpose is not to get to a certain place on the floor. It's to enjoy each step along the way.",
    author: 'Wayne Dyer',
    img: './../../assets/mood-pics/pexels-anastasia-shuraeva-8935911_34_11zon.webp'
  },
  {
    quote: "Dance is the timeless interpretation of life.",
    author: 'Shah Asad Rizvi',
    img: './../../assets/mood-pics/pexels-anastasia-shuraeva-8935911_34_11zon.webp'
  },
  {
    quote: "Great dancers are not great because of their technique, they are great because of their passion.",
    author: 'Martha Graham',
    img: './../../assets/mood-pics/pexels-anastasia-shuraeva-8752654_33_11zon.webp'
  },
  {
    quote: "When you dance, the world becomes an ocean, and you are the wave.",
    author: 'Unknown',
    img: './../../assets/mood-pics/pexels-anastasia-shuraeva-8935911_34_11zon.webp'
  },
  {
    quote: "Dance is music made visible.",
    author: 'George Balanchine',
    img: './../../assets/mood-pics/pexels-anna-tarazevich-6900842_31_11zon.webp'
  },
  {
    quote: "Dancers are the athletes of God.",
    author: 'Albert Einstein',
    img: './../../assets/mood-pics/pexels-anastasia-shuraeva-8935911_34_11zon.webp'
  },
  {
    quote: "Every day brings a chance for you to draw in a breath, kick off your shoes, and dance.",
    author: 'Oprah Winfrey',
    img: './../../assets/mood-pics/pexels-anastasia-shuraeva-8935911_34_11zon.webp'
  },
  {
    quote: "Dancing is dreaming with your feet.",
    author: 'Constanze',
    img: './../../assets/mood-pics/pexels-anastasia-shuraeva-8752654_33_11zon.webp'
  },
  {
    quote: "The job of feets is walking, but their hobby is dancing.",
    author: 'Amit Kalantri',
    img: './../../assets/mood-pics/pexels-andrew-4226422_32_11zon.webp'
  },
  {
    quote: "Step into the dance of life, the rhythm is already inside you.",
    author: 'Unknown',
    img: './../../assets/mood-pics/pexels-anastasia-shuraeva-8935911_34_11zon.webp'
  },
  {
    quote: "Dancing through life is easier than you think; just keep the beat and your feet will follow.",
    author: 'Unknown',
    img: './../../assets/mood-pics/pexels-andrew-4226422_32_11zon.webp'
  },
  {
    quote: "A day without dance is a day wasted.",
    author: 'Friedrich Nietzsche',
    img: './../../assets/mood-pics/pexels-anastasia-shuraeva-8935911_34_11zon.webp'
  },
  {
    quote: "The dance is over, the applause subsided, but the joy and feeling will stay with you forever.",
    author: 'W.M. Tory',
    img: './../../assets/mood-pics/pexels-anna-tarazevich-6900842_31_11zon.webp'
  },
  {
    quote: "In life as in dance: Grace glides on blistered feet.",
    author: 'Alice Abrams',
    img: './../../assets/mood-pics/pexels-anastasia-shuraeva-8752654_33_11zon.webp'
  },
  {
    quote: "Dance to the beat of your dreams.",
    author: 'Unknown',
    img: './../../assets/mood-pics/pexels-andrew-4226422_32_11zon.webp'
  },
  {
    quote: "Life is better when you dance like no one's watching.",
    author: 'Unknown',
    img: './../../assets/mood-pics/pexels-anastasia-shuraeva-8935911_34_11zon.webp'
  },
  {
    quote: "There are shortcuts to happiness, and dancing is one of them.",
    author: 'Vicki Baum',
    img: './../../assets/mood-pics/pexels-andrew-4226422_32_11zon.webp'
  },
  {
    quote: "The rhythm of the body, the melody of the mind, and the harmony of the soul create the symphony of life.",
    author: 'B.K.S. Iyengar',
    img: './../../assets/mood-pics/pexels-anastasia-shuraeva-8752654_33_11zon.webp'
  },
  {
    quote: "Your feet can learn the steps, but only your spirit can dance.",
    author: 'Unknown',
    img: './../../assets/mood-pics/pexels-andrew-4226422_32_11zon.webp'
  },
  {
    quote: "Life is a dance from one stage to the next. Enjoy the movement.",
    author: 'Unknown',
    img: './../../assets/mood-pics/pexels-anastasia-shuraeva-8935911_34_11zon.webp'
  },
  {
    quote: "Dancing faces you towards Heaven, whichever direction you turn.",
    author: 'Sweetpea Tyler',
    img: './../../assets/mood-pics/pexels-anastasia-shuraeva-8752654_33_11zon.webp'
  },
  {
    quote: "No matter what, keep dancing.",
    author: 'Unknown',
    img: './../../assets/mood-pics/pexels-andrew-4226422_32_11zon.webp'
  },
  {
    quote: "Let your steps carve a path to where your heart thrives.",
    author: 'Unknown',
    img: './../../assets/mood-pics/pexels-anna-tarazevich-6900842_31_11zon.webp'
  },
  {
    quote: "Dance with your heart, and your feet will follow the melody of your spirit.",
    author: 'Unknown',
    img: './../../assets/mood-pics/pexels-anastasia-shuraeva-8935911_34_11zon.webp'
  },
  {
    quote: "Leap into the dance of life and spin with the joy of the moment.",
    author: 'Unknown',
    img: './../../assets/mood-pics/pexels-andrew-4226422_32_11zon.webp'
  },
  {
    quote: "In the rhythm of life, find your dance, find your pace.",
    author: 'Unknown',
    img: './../../assets/mood-pics/pexels-anna-tarazevich-6900842_31_11zon.webp'
  },
  {
    quote: "Spin gracefully through life as if every moment is a dance floor.",
    author: 'Unknown',
    img: './../../assets/mood-pics/pexels-anna-tarazevich-6900842_31_11zon.webp'
  },
];