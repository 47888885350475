import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rejected',
})
export class RejectedPipe implements PipeTransform {
  transform(value: unknown, user: string): unknown {
    if (Array.isArray(value) && value) {
      const newValue = value.filter(item => {
        return item.status === 'rejected' && item.creator?.id === user;
      });
      return newValue;
    } else return value;
  }
}
