import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-datetime',
  templateUrl: './datetime.component.html',
  styleUrls: ['./datetime.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class DatetimeComponent {
  @Input() date: string;

  constructor() {}
  selectedDate: string = new Date().toISOString();

  change = e => {
    console.log(e);
  };
}
