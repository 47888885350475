import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { filter, Subject, takeUntil } from 'rxjs';
import { FormService } from '../../shared/components/form/form.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../firebase/auth.service';
import { EventsStore } from '../events-store';
import { EventsService } from '../events.service';
import { countries } from '../../auth/sign-up/countries';
import {
  ACCOUNT_EVENT_TYPE_ENUMS,
  categoryOptions,
  confirmDeletingModalData,
  confirmEditEventModalData,
  EVENT_TYPE_ENUMS,
  eventFormData,
  EventItem,
} from '../events';
import {
  createToaster,
  positionType,
} from '../../shared/components/modals/toaster';
import { UserService } from 'src/app/auth/user.service';
import { ImagesService } from 'src/app/services/images.service';
import { showLoading } from 'src/app/shared/components/modals/loading';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-events',
  templateUrl: './edit-events.component.html',
  styleUrls: ['./edit-events.component.scss'],
})
export class EditEventsComponent implements OnInit, OnDestroy {
  @Output() isClose = new EventEmitter<boolean>(false);
  @ViewChild('map') mapRef;
  @ViewChild('eventFormComponent') eventFormComponent;
  environment = environment;
  map;
  isOpen = false;
  isOnline = false;
  currentUser;
  status;
  eventItem;
  loader = showLoading();
  newEventItem: EventItem;
  item = { icon: 'calendar-outline', title: 'Edit an Event' };
  categoryOptions = categoryOptions;
  showForDelete = false;

  eventTypeOptions = [
    {
      name: EVENT_TYPE_ENUMS.EXTERNALLY_HOSTED_AND_PAID,
    },
    {
      name: EVENT_TYPE_ENUMS.EXTERNALLY_HOSTED_AND_FREE,
    },
    {
      name: EVENT_TYPE_ENUMS.INTERNALLY_HOSTED_AND_PAID,
    },
    {
      name: EVENT_TYPE_ENUMS.INTERNALLY_HOSTED_AND_FREE,
    },
  ];

  id: string = this.activatedRouter.snapshot.params.id;
  showSuccessModal = false;
  paymentLinkLabel =
    "*If you don't add a payment link, a member that buys your ticket will message you via a private Discord message to pay you privately.";
  form: FormGroup;
  countries = countries;
  showConfirmModal = false;
  confirmModalData = confirmEditEventModalData;
  eventForm: FormGroup;
  imagesControls = 1;
  meetingLink = false;
  businessName = false;
  businessWebsiteURL = false;
  concreteAddress = false;
  googleMapsLink = false;
  externalEventLink = true;
  paymentLink = false;
  priceContainer = true;
  paymentLinkLabelVisible = false;
  loadingImage = false;
  addingBtnDisabled = true;
  disabled = true;
  statusDeleting = false;
  statusAdding = false;
  currentImage: string;
  imagesArr: string[];
  imageField = eventFormData.filter(i => i.type === 'ARRAY');
  postingEventOptions = [
    ACCOUNT_EVENT_TYPE_ENUMS.MY_MEMBER_ACCOUNT,
    ACCOUNT_EVENT_TYPE_ENUMS.MY_BUSINESS_ACCOUNT,
  ];
  confirmForDelete = confirmDeletingModalData;
  today = new Date().toISOString();

  private destroy$ = new Subject<void>();

  constructor(
    private formService: FormService,
    private imagesService: ImagesService,
    private router: Router,
    private userService: UserService,
    private activatedRouter: ActivatedRoute,
    private eventsService: EventsService,
    private eventsStore: EventsStore
  ) {}

  ngOnInit() {
    this.listenStatusChanges();
    this.eventsStore
      .selectId()
      .pipe(filter(id => !!id))
      .subscribe(id => {
        this.isClose.next(true);
        this.eventsStore.updateShowFormModal(false);
        this.eventsService.isOpenForm.next(true);
      });

    this.initialDataListener();
    this.listenItemId();
    this.getUser();

    this.eventsService.isOpenForm.subscribe(
      isOpen => (this.showSuccessModal = isOpen)
    );
  }

  ngOnDestroy() {
    this.formService.itemGallery.next([]);
    this.destroy$.next();
    this.destroy$.complete();
  }

  initialDataListener() {
    this.eventsStore
      .selectItem()
      .pipe(
        filter(item => !!item),
        takeUntil(this.destroy$)
      )
      .subscribe(data => {
        this.eventItem = data;
      });
  }

  close(e) {
    e ? (this.showConfirmModal = true) : null;
  }

  discardChanges(e) {
    if (e) {
      this.isClose.next(true);
      this.showConfirmModal = false;
    }
  }

  closeModal(e) {
    if (e) {
      this.showConfirmModal = false;
    }
  }

  async updateItem() {
    if (this.eventForm) {
      this.eventsStore.setLoading();
      await this.eventFormComponent.imageLoader.upLoadImages();
      this.imagesService.imageUrls
        .pipe(
          filter(data => !!data),
          takeUntil(this.destroy$)
        )
        .subscribe(urls => {
          if (urls.length) {
            this.disabled = true;
            this.newEventItem = this.eventForm.value;
            this.newEventItem.images = [...urls].filter(image => !!image);
            (this.newEventItem.creator = this.eventItem?.creator),
              (this.newEventItem.startDate = this.eventForm.get('startDate')
                .value
                ? new Date(this.eventForm.get('startDate').value)
                : null),
              (this.newEventItem.endDate = this.eventForm.get('endDate').value
                ? new Date(this.eventForm.get('endDate').value)
                : null),
              (this.newEventItem.price =
                Object.keys(this.eventForm?.value.price).length === 0
                  ? { value: 0 }
                  : this.eventForm?.value.price),
              (this.newEventItem.location = this.newEventItem.online
                ? Object.assign({
                    ...this.newEventItem.location,
                    country: { region: 'online' },
                  })
                : this.newEventItem?.location),
              this.formService.itemGallery.next([]);
            this.eventsStore.updateProfile(this.newEventItem);
            this.eventsStore
              .selectLoading()
              .pipe(takeUntil(this.destroy$))
              .subscribe(data => {
                if (data) {
                  this.disabled = true;
                  this.addingBtnDisabled = true;
                  this.statusAdding = true;
                } else {
                  this.isClose.next(true);
                  createToaster(
                    'Successfully updated!',
                    'success',
                    positionType.BOTTOM
                  );
                  // this.disabled = false;
                  this.addingBtnDisabled = false;
                  this.statusAdding = false;
                }
              });
          }
        });
    }
  }

  removeItem(e) {
    if (e) {
      this.eventsStore.removeItem(this.id);
      this.eventsStore
        .selectLoading()
        .pipe(takeUntil(this.destroy$))
        .subscribe(data => {
          this.statusDeleting = data;
          if (data) {
            this.disabled = true;
            this.showForDelete = false;
          } else {
            this.isClose.next(true);
            createToaster(
              'Successfully deleted!',
              'success',
              positionType.BOTTOM
            );
            setTimeout(() => {
              this.router.navigate(['/events']);
            }, 0);
          }
        });
    }
  }

  listenItemId() {
    this.eventsStore
      .selectId()
      .pipe(filter(id => !!id))
      .subscribe(id => {
        this.isClose.next(true);
        this.eventsStore.updateShowFormModal(false);
        this.eventsService.isOpenForm.next(true);
        this.eventsStore.updateShowPendingModal(true);
        setTimeout(() => {
          this.eventsStore.patchState({ createdId: null });
          this.eventsStore.getItemByByValue({ documentId: id });
          this.router.navigate(['/events', id]);
        }, 0);
      });
  }
  private getUser() {
    this.userService.currentUser$
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user.uid)
      )
      .subscribe(user => {
        this.currentUser = user;
      });
  }

  private listenStatusChanges() {
    this.eventsStore
      .selectLoading()
      .pipe(takeUntil(this.destroy$))
      .subscribe(loading => {
        loading
          ? this.loader.then(data => data.present())
          : this.loader.then(data => data.dismiss());
      });
  }
}
