import { Component } from '@angular/core';

@Component({
  selector: 'app-pods',
  templateUrl: './pods.component.html',
  styleUrls: ['./pods.component.scss'],
})
export class PodsComponent {
  contentId = 'podsMenu';

  constructor() {}
}
