import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlagPipe } from './flag-pipe';
import { IonicModule } from '@ionic/angular';
import { CountryPipe } from './country-pipe.pipe';
import { PendingPipe } from './pending.pipe';
import { JoinedPipe } from './joined.pipe';
import { RejectedPipe } from './rejected.pipe';
import { ApprovedPipe } from './approved.pipe';
import { GetImagePipe } from './get-image.pipe';
import { NotAvailablePipe } from './not-available.pipe';
import { SlicerPipe } from './slicer.pipe';
import { PaginatorPipe } from './paginator.pipe';
import { PlanetApprovedPipe } from './planet-approved.pipe';
import { WorkTypeConverterPipe } from './work-type-converter.pipe';
import { DateAgoPipe } from './date-ago.pipe';
import { PendingEventPipe } from './pending-event.pipe';
import { EventApprovedPipe } from './event-approved.pipe';
import { SortPipe } from './sort.pipe';
import { UtcPipe } from './utc.pipe';
import { InvitationPipe } from './invitation.pipe';
import { DeniedPipe } from './denied.pipe';
import { InviteRequestsPipe } from './invite-requests.pipe';
import { NumberPipe } from './number.pipe';
import { SuspendedPipe } from './suspended.pipe';
import { IsUserHasSuspendedContentPipe } from './is-user-has-suspended-content.pipe';
import { WeekofPipe } from './weekof.pipe';
@NgModule({
  declarations: [
    FlagPipe,
    CountryPipe,
    PendingPipe,
    PendingEventPipe,
    JoinedPipe,
    RejectedPipe,
    ApprovedPipe,
    GetImagePipe,
    NotAvailablePipe,
    SlicerPipe,
    PaginatorPipe,
    PlanetApprovedPipe,
    WorkTypeConverterPipe,
    DateAgoPipe,
    EventApprovedPipe,
    SortPipe,
    UtcPipe,
    InvitationPipe,
    DeniedPipe,
    InviteRequestsPipe,
    NumberPipe,
    SuspendedPipe,
    IsUserHasSuspendedContentPipe,
    WeekofPipe
  ],
  imports: [CommonModule, IonicModule],
  exports: [
    FlagPipe,
    CountryPipe,
    PendingPipe,
    JoinedPipe,
    RejectedPipe,
    ApprovedPipe,
    GetImagePipe,
    NotAvailablePipe,
    SlicerPipe,
    PaginatorPipe,
    PlanetApprovedPipe,
    WorkTypeConverterPipe,
    DateAgoPipe,
    PendingEventPipe,
    EventApprovedPipe,
    SortPipe,
    UtcPipe,
    InvitationPipe,
    DeniedPipe,
    InviteRequestsPipe,
    NumberPipe,
    SuspendedPipe,
    IsUserHasSuspendedContentPipe,
    WeekofPipe
  ],
})
export class PipesModule {}
