import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IonModal } from '@ionic/angular';

@Component({
  selector: 'app-reject-msg-modal',
  templateUrl: './reject-msg-modal.component.html',
  styleUrls: ['./reject-msg-modal.component.scss'],
})
export class RejectMsgModalComponent implements OnInit {
  @Input() isRejectMsgModalOpen: boolean;

  @Output() ok: EventEmitter<boolean | string> = new EventEmitter();
  @Output() cancel: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('modal') modal: IonModal;
  form: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.form = this.fb.group({
      message: [null],
    });
  }

  handleOk() {
    this.ok.next(this.form.get('message').value);
    this.form.reset();
    this.modal.dismiss(null, 'ok');
  }

  handleCancel() {
    this.cancel.next(true);
    this.form.reset();
    this.modal.dismiss(null, 'cancel');
  }
}
