import { FormField, FormFieldTypes } from 'src/app/shared/components/form/form';

export const jobsMenuData: FormField[] = [
  {
    name: 'myContent',
    type: FormFieldTypes.TOGGLE,
    label: 'See my content only',
    required: false,
    icon: 'person',
    line: 'full',
  },
  {
    name: 'location',
    type: FormFieldTypes.SELECT,
    label: 'Location',
    multiple: true,
    required: false,
    line: 'full',
    icon: 'location',
    options: [
      'online',
      'Africa',
      'Asia',
      'Oceania',
      'North America',
      'Europe',
      'South America',
    ],
  },
  {
    name: 'type',
    type: FormFieldTypes.SELECT,
    label: 'Type',
    multiple: true,
    required: false,
    line: 'full',
    icon: 'reorder-three-outline',
    options: ['part-time', 'full-time', 'per hour', 'gig based'],
  },
];

export interface IJobsFilter {
  myContent: boolean;
  location?: string;
  type?: string;
  creator: string;
}

export const imageField = {
  name: 'images',
  type: FormFieldTypes.ARRAY,
  icon: 'image',
  label: 'image',
  children: [
    {
      name: 'image',
      type: FormFieldTypes.FILE,
      label: 'image',
      required: false,
      icon: 'image',
    },
  ],
  template: {
    name: 'image',
    type: FormFieldTypes.FILE,
    label: 'image',
    required: false,
    icon: 'image',
  },
};

export const jobPremiumModalData =
  "You've got great services to offer to other mers? \n \n As a premium mer you'll be good to go! Go and check out our packages!";

export const jobDiscordModalData = {
  icon: 'pricetag',
  title: 'Offer your Services',
  text: 'In order for potential clients to contact you, you need to join our chat group on Discord first.',
};

export const confirmAddingModalData = {
  title: 'Offer your Services',
  icon: 'pricetag',
  okBtn: {
    title: 'YES, DISCARD',
  },
  cancelBtn: {
    title: 'NO, GO BACK',
  },
};

export const successServicesModalData = {
  title: 'Thanks for adding content!',
  pic: 'some sea animal with magnifying glass',
  description:
    "You'll get rewarded as soon as your content has been verified by 3 moderators.",
  okBtn: {
    title: 'CONTINUE',
  },
  cancelBtn: {
    title: 'SPLASH MERFRIENDS',
  },
};

export const confirmEditingModalData = {
  title: 'Editing Service Offer',
  icon: 'pricetag',
  okBtn: {
    title: 'YES, DISCARD',
  },
  cancelBtn: {
    title: 'NO, GO BACK',
  },
};

export const confirmDeletingModalData = {
  title: 'Deleting Service Offer?',
  icon: 'pricetag',
  okBtn: {
    title: 'YES, DELETE',
  },
  cancelBtn: {
    title: 'NO, KEEP',
  },
};
