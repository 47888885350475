import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { IToolbarData } from '../../shared/models/components-data.models';
import { filter, combineLatest, Subject, switchMap, takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../firebase/auth.service';
import { PlanetStore } from '../planet-store';
import { map } from 'rxjs/operators';
import { Navigation, Pagination, SwiperOptions } from 'swiper';
import { successPlanetModalData } from '../planet';
import { environment } from '../../../environments/environment';
import mixpanel from 'mixpanel-browser';
import { getTimeUntilNextSunday } from '../planet';
import { getFunctions, httpsCallable } from 'firebase/functions';

const TOOLBAR_DATA: IToolbarData = {
  arrowRouter: '/',
  btnSlot: 'end',
  title: environment.app === 'danceApp' ? 'Stage' : 'Planet',
};

const TOOLBAR_SWIPER: IToolbarData = {
  arrowRouter: '',
  btnSlot: 'end',
  title: 'Daily Challenge Pictures',
};

@Component({
  selector: 'app-planet-details',
  templateUrl: './planet-details.component.html',
  styleUrls: ['./planet-details.component.scss'],
})
export class PlanetDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('col') col: ElementRef;
  isOpenFlagModal: boolean = false;
  galleryUrls: string[];
  isImgLoaded: boolean = false;
  config: SwiperOptions;
  TOOLBAR_DATA = TOOLBAR_DATA;
  TOOLBAR_SWIPER = TOOLBAR_SWIPER;
  currentUser;
  currentUserID;
  isContentFlaggedModal: boolean = false;
  planetItem;
  experiences;
  items;
  creator;
  showAddExperienceModal;
  isOpen = false;
  showSuccessModal = false;
  successModalData = successPlanetModalData;
  isOpenFormModal = false;
  isOpenAddModal;
  isOpenEditModal;
  environment = environment;
  id = this.activeRouter.snapshot.params.id;
  showStatusModal: boolean = false;
  itemStatus;
  timer;
  properties;
  flagData;

  isTodaysChallenge = false;
  private destroy$ = new Subject<void>();

  constructor(
    private planetStore: PlanetStore,
    private activeRouter: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
  ) {
    setInterval(() => {
      let time = getTimeUntilNextSunday();
      this.timer = time;
    }, 1000);
  }

  ngOnInit() {
    mixpanel.init(environment.mixpanelToken, { debug: true });
    const { uid } = this.authService.isUserAuth();
    mixpanel.identify(uid);
    this.getUser();

    if (this.id) {
      this.planetStore.getItemByByValue({
        documentId: this.id,
      });
      this.listenOlderPlanetDetails();
    } else {
      this.planetStore.getItems({});
      this.listenTodayPlanetDetails();
    }

    this.planetStore.selectShowFormModal().subscribe(isOpen => {
      this.isOpenFormModal = isOpen;
    });

    this.planetStore.selectShowAddFormModal().subscribe(isOpen => {
      this.isOpenAddModal = isOpen;
    });

    this.planetStore.selectShowEditFormModal().subscribe(isOpen => {
      this.isOpenEditModal = isOpen;
    });

    this.planetStore.selectShowPendingModal().subscribe(isOpen => {
      this.showSuccessModal = isOpen;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.planetItem = null;
    this.id = null;
  }

  trackEvent(event, parameter, parameterValue) {
    if (parameter === 'screen-name') {
      this.properties = {
        'screen-name': parameterValue,
      };
    }

    mixpanel.track(event, this.properties);
  }

  openStatusModal(status?) {
    this.showStatusModal = !this.showStatusModal;
    if (status) this.itemStatus = status;
  }

  toggleGallery(i?) {
    this.isOpen = !this.isOpen;
    this.config = {
      modules: [Pagination, Navigation],
      loop: true,
      pagination: { clickable: true, dynamicBullets: true },
      initialSlide: i,
      injectStyles: [
        `
          .swiper-pagination-bullet {
            width:14px;
            height:14px
          }`,
      ],
    };
  }
  handleOk(e) {
    e ? this.planetStore.updateShowPendingModal(false) : null;
  }

  handleCancel(e) {
    e ? this.planetStore.updateShowPendingModal(false) : null;
  }

  openModal() {
    this.planetStore.updateShowFormModal(true);
  }

  closeModal(e) {
    e ? this.planetStore.updateShowFormModal(false) : null;
  }

  openAddModal() {
    this.planetStore.updateShowAddFormModal(true);
  }

  closeAddModal(e) {
    e ? this.planetStore.updateShowAddFormModal(false) : null;
  }

  openEditModal() {
    this.planetStore.updateShowEditFormModal(true);
  }

  closeEditModal(e) {
    e ? this.planetStore.updateShowEditFormModal(false) : null;
  }

  openAddExperienceModal() {
    this.showAddExperienceModal = true;
  }

  navToProfile(item) {
    this.isOpen = false;
    this.router.navigate(['/profile/', item.creator.id]);
  }

  private listenOlderPlanetDetails() {
    this.planetStore
      .selectItem()
      .pipe(
        takeUntil(this.destroy$),
        filter(item => !!item),
        switchMap(item => {
          if (this.id) {
            this.planetItem = item;
            this.experiences = item?.experiences.filter(item => !this.currentUser?.blockList?.includes(item?.creator?.uid));
          }
          this.isTodaysChallenge = false;
          if (this.experiences.length >= 1) {
            let experiencesImages = [];
            this.experiences.forEach(experience => {
              experiencesImages.push(experience.image);
            });
            const participants$ = this.experiences.map(item =>
              this.authService.getUserByUid(item.creator.id)
            );

            combineLatest(participants$)
              .pipe(filter(part => !!part))
              .subscribe((participants: any[]) => {
                this.experiences.map(item => {
                  const newCreator = participants.find(
                    participant => participant.id === item.creator.id
                  );

                  newCreator ? (item.creator = newCreator) : 'null';
                });
              });
            this.galleryUrls = experiencesImages.map(pic => pic);
          }
          const participants$ = this.experiences.map(item =>
            this.authService.getUserByUid(item.creator.id)
          );

          combineLatest(participants$)
            .pipe(filter(part => !!part))
            .subscribe((participants: any[]) => {
              this.experiences.map(item => {
                const newCreator = participants.find(
                  participant => participant.id === item.creator.id
                );

                newCreator ? (item.creator = newCreator) : 'null';
              });
            });
          return this.authService.getUserByUid(item.creator.id);
        })
      )
      .subscribe(user => {
        this.creator = user;
      });
  }

  private listenTodayPlanetDetails() {
    combineLatest([this.planetStore.selectItems()])
      .pipe(
        takeUntil(this.destroy$),
        filter(items => !!items),
        map(([items]) => {
          return (
            items &&
            (items.find(item => {
              let actualDate = new Date();
              actualDate.setHours(3);
              actualDate.setMinutes(0);
              actualDate.setSeconds(0);
              if (item.challengeDate) {
                return (
                  item.challengeDate.seconds ===
                  Math.floor(actualDate.getTime() / 1000)
                );
              }
            }) ||
              items.find(item => item.challengeDate !== null))
          );
        })
      )
      .subscribe(item => {
        if (!this.id) {
          this.planetItem = item;
          this.experiences = item?.experiences.filter(item => !this.currentUser.blockList?.includes(item.creator.uid));
          this.isTodaysChallenge = true;
          // this.planetStore.getItemByByValue({
          //   documentId: item.id,
          // });
        }
        if (this.experiences?.length >= 1) {
          let experiencesImages = [];
          this.experiences.forEach(experience => {
            experiencesImages.push(experience.image);
          });

          const participants$ = this.experiences.map(item =>
            this.authService.getUserByUid(item.creator.id)
          );

          combineLatest(participants$)
            .pipe(filter(part => !!part))
            .subscribe((participants: any[]) => {
              this.experiences.map(item => {
                const newCreator = participants.find(
                  participant => participant.id === item.creator.id
                );

                newCreator ? (item.creator = newCreator) : 'null';
              });
            });

          this.galleryUrls = experiencesImages.map(pic => pic);
        }
      });
  }

  async flagContent(slide, type) {
    slide.itemId = this.planetItem.id;
    if(type === 'challengeExperience') {
      slide.type = 'challengeExperience';
    } else if(type === 'planet') {
      slide.type = 'planet';
    }
    

    this.flagData = slide;
    const functions = getFunctions();
    const isContentFlagged = httpsCallable(functions, 'isContentFlagged');
    await isContentFlagged({
      itemId: slide.itemId,
      url: this.flagData.image,
      type: this.flagData.type
    }).then(res => {
      if(res.data === true) {
        this.isContentFlaggedModal = true;
      } else {
        this.isOpenFlagModal = true;
      }
    });
  }

  private getUser() {
    const { uid } = this.authService.isUserAuth();

    this.authService
      .getUserByUid(uid)
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user)
      )
      .subscribe(user => {
        this.currentUser = user;
        this.currentUserID = user.id;
      });
  }

  onImgLoad() {
    this.isImgLoaded = true;
  }
}
