import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-bio',
  templateUrl: './bio.component.html',
})
export class BioComponent {
  @Input() bio: string;

  fullBio = false;
  constructor() {}

  showFullBio() {
    this.fullBio = true;
  }
  showLessBio() {
    this.fullBio = false;
  }
}
