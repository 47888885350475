import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'weekof',
})
export class WeekofPipe implements PipeTransform {
  transform(date: any, ...args: unknown[]): unknown {
    const challengeDate = date.seconds
      ? date.seconds * 1000
      : date._seconds * 1000;
    const dayOfWeek = new Date(challengeDate).getDay();
    const daysToSubtract = (dayOfWeek + 7) % 7;
    const previousSunday =
      new Date(challengeDate).getTime() - daysToSubtract * 24 * 60 * 60 * 1000;

    return previousSunday;
  }
}