import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-services-search',
  templateUrl: './services-search.component.html',
  styleUrls: ['./services-search.component.scss'],
})
export class ServicesSearchComponent {
  @Input() color;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSearchChange = new EventEmitter<string>();

  toolbarData = {
    arrowRouter: '/bazaar',
    title: 'Services',
  };

  constructor() {}

  searchItem(event) {
    this.onSearchChange.emit(event.target.value);
  }
}
