import { Injectable } from '@angular/core';
import { Timestamp } from 'firebase/firestore';
@Injectable({
  providedIn: 'root',
})
export class DateService {
  constructor() {}

  setMidNight = () => {
    const now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setMinutes(0);
    return now;
  };

  getTimestamp = () => {
    return new Date().getTime();
  };

  setNow = () => {
    const now = new Date();
    return now;
  };

  get midNight() {
    return this.setMidNight();
  }

  get now() {
    return this.setNow();
  }

  getToday = date => {
    return date.seconds
      ? new Date(date.seconds * 1000).getDate()
      : new Date(date._seconds * 1000).getDate();
  };

  formatDate = (date): string | null => {
    if (date?._seconds) return new Date(date?._seconds * 1000).toISOString();
    if (date?.seconds) return new Date(date?.seconds * 1000).toISOString();
    if (date && !date?.seconds && !date?._seconds)
      return new Date(date).toISOString();
    return null;
  };

  checkSameDate(startDate, endDate) {
    if (startDate && endDate) {
      const startDay = this.getToday(startDate);
      const endDay = this.getToday(endDate);
      const period = startDate.seconds
        ? (endDate.seconds - startDate.seconds) / 3600
        : (endDate._seconds - startDate._seconds) / 3600;
      const isLessThanDay = period < 24;
      return startDay === endDay && isLessThanDay;
    }
  }

  getScheduledItems = (items: any[], myContent: boolean) => {
    if (items && items.length && !myContent) {
      return items.filter(item => {
        if (item.endDate?._seconds)
          return item.endDate._seconds >= this.now.getTime() / 1000;
        if (item.endDate) {
          return item.endDate.seconds >= this.now.getTime() / 1000;
        } else if (
          item.completionDate?.endDate ||
          item.completionDate?._endDate
        ) {
          return item.completionDate?.endDate._seconds
            ? item.completionDate.endDate.seconds >=
                this.midNight.getTime() / 1000
            : item.completionDate.endDate._seconds >=
                this.midNight.getTime() / 1000;
        } else {
          return item;
        }
      });
    } else return items;
  };

  checkSuspendedNotification(notification) {
    if (notification.suspendedUntil) {
      const now = this.getTimestamp();
      return now - notification.suspendedUntil.seconds * 1000 > 86400000;
    } else return true;
  }
}
