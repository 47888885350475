import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/compat/storage';

@Injectable({
  providedIn: 'root',
})
export class StorageHelper {
  uploadPercent: Observable<number>;
  profileUrl: Observable<string | null>;

  constructor(private storage: AngularFireStorage) {}

  async uploadPicture(event) {
    const file = event;
    const name = file.name.replace('(', '').replace(')', '').replace(' ', '');
    const filePath = `${Date.now()}-${name}`;
    const fileRef = this.storage.ref(filePath);
    const task = fileRef.put(file);
    this.uploadPercent = task.percentageChanges();

    return {
      filePath,
      loading: this.uploadPercent,
      task,
      fileRef,
    };
  }

  async deletePicture(fileUrl) {
    return this.storage.storage.refFromURL(fileUrl).delete();
  }

  downloadPicture(filePath) {
    const ref = this.storage.ref(filePath);
    this.profileUrl = ref.getDownloadURL();
    return this.profileUrl;
  }

  uploadImageBase64(
    imageBase64: string,
    path: string,
    filename: string
  ): Observable<string> {
    const filePath = `${path ? path + '/' : ''}${filename}`;
    const storageRef = this.storage.ref(filePath);

    const subject = new Subject<string>();
    const base64 = StorageHelper.getBase64PartWithoutMetaInfo(imageBase64);

    storageRef
      .putString(base64, 'base64', { contentType: 'image/png' })
      .task.then(() => {
        storageRef.getDownloadURL().subscribe(downloadUrl => {
          subject.next(downloadUrl);
          subject.complete();
        });
      });

    return subject;
  }

  private static getBase64PartWithoutMetaInfo(base64) {
    return base64.split(',')[1];
  }
}
