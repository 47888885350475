import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { CrudHelper } from './helpers/crudHelper';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  constructor(
    private fireStore: AngularFirestore,
    private crudHelper: CrudHelper
  ) {}

  showSuccessModal$ = new BehaviorSubject<boolean>(false);

  getEvents = () => {
    return this.crudHelper.getHelper({ collectionName: 'events' });
  };

  setEvent = async event => {
    return await this.crudHelper
      .setHelper({
        collectionName: 'events',
        data: event,
      })
      .then(() => {
        this.showSuccessModal$.next(true);
      });
  };

  updateEvent = async (event, id) => {
    return await this.crudHelper.updateItem({
      data: event,
      id,
      collectionName: 'events',
    });
  };

  deleteEvent = async eventId => {
    return await this.crudHelper.deleteHelper({
      collectionName: 'events',
      id: eventId,
    });
  };

  getEventBySearch = async (start, end) => {
    return await this.crudHelper.searchHelper({
      collectionName: 'events',
      searchField: 'title',
      limit: 50,
      start,
      end,
    });
  };

  getEventsByFilter = filters => {
    const { creatorId, categories, price, location, date } = filters;

    return this.fireStore
      .collection('events', ref => {
        const allProps = location && price && categories && creatorId;

        const location_price_categories = location && price && categories;
        const location_price_creatorId = location && price && creatorId;
        const location_creatorId_categories =
          location && creatorId && categories;
        const price_creatorId_categories = price && creatorId && categories;

        const location_price = location && price;
        const location_categories = location && categories;
        const location_creatorId = location && creatorId;
        const price_categories = price && categories;
        const price_creatorId = price && creatorId;
        const categories_creatorId = categories && creatorId;

        switch (true) {
          case !!allProps:
            return ref
              .where('categories', 'array-contains-any', categories)
              .where('price.value', '>=', price.min)
              .where('price.value', '<=', price.max)
              .where('price.currency', '==', price.currency)
              .where('location', '==', location)
              .where('creatorId', '==', creatorId);
          case !!location_price_categories:
            return ref
              .where('categories', 'array-contains-any', categories)
              .where('price.value', '>=', price.min)
              .where('price.value', '<=', price.max)
              .where('price.currency', '==', price.currency)
              .where('location', '==', location);
          case !!location_price_creatorId:
            return ref
              .where('price.value', '>=', price.min)
              .where('price.value', '<=', price.max)
              .where('price.currency', '==', price.currency)
              .where('location', '==', location)
              .where('creatorId', '==', creatorId);
          case !!location_creatorId_categories:
            return ref
              .where('categories', 'array-contains-any', categories)
              .where('location', '==', location)
              .where('creatorId', '==', creatorId);
          case !!price_creatorId_categories:
            return ref
              .where('price.value', '>=', price.min)
              .where('price.value', '<=', price.max)
              .where('price.currency', '==', price.currency)
              .where('categories', 'array-contains-any', categories)
              .where('creatorId', '==', creatorId);
          case !!location_price:
            return ref
              .where('price.value', '>=', price.min)
              .where('price.value', '<=', price.max)
              .where('price.currency', '==', price.currency)
              .where('location', '==', location);
          case !!location_categories:
            return ref
              .where('location', '==', location)
              .where('categories', 'array-contains-any', categories);
          case !!location_creatorId:
            return ref
              .where('location', '==', location)
              .where('creatorId', '==', creatorId);

          case !!price_categories:
            return ref
              .where('price.value', '>=', price.min)
              .where('price.value', '<=', price.max)
              .where('price.currency', '==', price.currency)
              .where('categories', 'array-contains-any', categories);
          case !!price_creatorId:
            return ref
              .where('price.value', '>=', price.min)
              .where('price.value', '<=', price.max)
              .where('price.currency', '==', price.currency)
              .where('creatorId', '==', creatorId);
          case !!categories_creatorId:
            return ref
              .where('categories', 'array-contains-any', categories)
              .where('creatorId', '==', creatorId);
          case !!categories:
            return ref.where('categories', 'array-contains-any', categories);
          case !!categories:
            return ref.where('categories', 'array-contains-any', categories);
          case !!price:
            return ref
              .where('price.value', '>=', price.min)
              .where('price.value', '<=', price.max)
              .where('price.currency', '==', price.currency);
          case !!creatorId:
            return ref.where('creatorId', '==', creatorId);
        }
      })
      .snapshotChanges()
      .pipe(
        map(events => {
          return events.map(a =>
            Object.assign(
              {
                id: a.payload.doc.id,
              },
              a.payload.doc.data()
            )
          );
        }),
        distinctUntilChanged((prev, curr) => {
          return (
            JSON.stringify(prev).split('').sort().join('') ===
            JSON.stringify(curr).split('').sort().join('')
          );
        })
      );
  };
}
