// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[1].use[0]!../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[1].use[1]!../../../../../node_modules/flag-icons/css/flag-icons.min.css");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".item-style {\n  --padding-start: 0px;\n}\n\n.fi {\n  margin-right: 3px;\n}", "",{"version":3,"sources":["webpack://./src/app/merfolk/pods/pod-item/pod-item.component.scss","webpack://./../../Grow%20Business%20LTD/danceuniteus/src/app/merfolk/pods/pod-item/pod-item.component.scss"],"names":[],"mappings":"AAEA;EACE,oBAAA;ACAF;;ADGA;EACE,iBAAA;ACAF","sourcesContent":["@import \"flag-icons/css/flag-icons.min.css\";\r\n\r\n.item-style {\r\n  --padding-start: 0px;\r\n}\r\n\r\n.fi {\r\n  margin-right: 3px;\r\n}","@import \"flag-icons/css/flag-icons.min.css\";\n.item-style {\n  --padding-start: 0px;\n}\n\n.fi {\n  margin-right: 3px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
