import { Injectable } from '@angular/core';
import { CommonComponentStore } from './../../shared/component-store/common-component-store';
import { IPod } from 'src/app/shared/models/item.models';
import { PodService } from './pods.service';

@Injectable({
  providedIn: 'root',
})
export class PodStore extends CommonComponentStore<IPod> {
  constructor(podService: PodService) {
    super(podService, {
      createdId: null,
      item: null,
      items: null,
      loading: false,
      showPodFormModal: false,
      showPodPendingModal: false,
      showInviteModal: false,
      collectionName: 'pods',
      podsEvents: null,
    });
  }

  selectShowFormModal() {
    // TODO update
    // @ts-ignore
    return this.select(state => state.showPodFormModal);
  }

  updateShowFormModal(value) {
    // TODO update
    // @ts-ignore
    this.patchState({ showPodFormModal: value });
  }

  selectShowPendingModal() {
    // TODO update
    // @ts-ignore
    return this.select(state => state.showPodPendingModal);
  }

  updateInviteModal(value) {
    // TODO update
    // @ts-ignore
    this.patchState({ showInviteModal: value });
  }

  selectInviteModal() {
    // TODO update
    // @ts-ignore
    return this.select(state => state.showInviteModal);
  }

  updateShowPendingModal(value) {
    // TODO update
    // @ts-ignore
    this.patchState({ showPodPendingModal: value });
  }
}
