import { Component, OnDestroy, OnInit } from '@angular/core';
import { PodStore } from '../merfolk/pods/pod-store';
import { BehaviorSubject, Subject, combineLatest, map, takeUntil } from 'rxjs';
import { showLoading } from '../shared/components/modals/loading';
import { IPod } from '../shared/models/item.models';
import { JobItem, SecondHandItem } from '../bazaar/bazaar';
import { SecondHandStore } from '../bazaar/second-hand/second-hand-store';
import { JobsStore } from '../bazaar/jobs-services/jobs/jobs-store';
import { ServicesStore } from '../bazaar/jobs-services/services/services.store';
import { EventsStore } from '../events/events-store';
import { PoolsStore } from '../merfolk/pools/pools.store';
import { PlanetStore } from '../planet/planet-store';

@Component({
  selector: 'app-pending-approval',
  templateUrl: './pending-approval.page.html',
  styleUrls: ['./pending-approval.page.scss'],
})
export class PendingApprovalPage implements OnInit, OnDestroy {
  searchString: BehaviorSubject<string> = new BehaviorSubject<string>('');

  secondHandItems: SecondHandItem[];
  pods: IPod[];
  jobs: JobItem[];
  services;
  events;
  pools;
  planet;

  isShChipActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isPodsChipActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );
  isPoolsChipActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );
  isJobsChipActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );
  isOffersChipActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );
  isEventsChipActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );
  isPlanetChipActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );

  status;
  loader = showLoading();
  userId;

  private destroy$ = new Subject<void>();

  constructor(
    private podStore: PodStore,
    private secondHandStore: SecondHandStore,
    private jobStore: JobsStore,
    private servicesStore: ServicesStore,
    private eventsStore: EventsStore,
    private poolsStore: PoolsStore,
    private planetStore: PlanetStore
  ) {}

  ngOnInit() {
    this.podStore.getItems({ status: 'pending' });
    this.secondHandStore.getItems({ status: 'pending' });
    this.jobStore.getItems({ status: 'pending' });
    this.servicesStore.getItems({ status: 'pending' });
    this.eventsStore.getItems({ status: 'pending' });
    this.listenEventsChanges();
    this.poolsStore.getItems({ status: 'pending' });
    this.planetStore.getItems({ status: 'pending' });
    this.listenPodsChanges();
    this.listenSecondHandChanges();
    this.listenJobsChanges();
    this.listenServicesChanges();

    this.listenPoolsChanges();
    this.listenPlanetChanges();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSearchChange(searchString) {
    this.searchString.next(searchString);
  }

  toggleChip(e) {
    switch (e.target.id) {
      case 'pods':
        this.isPodsChipActive.next(!this.isPodsChipActive.value);
        break;
      case 'pools':
        this.isPoolsChipActive.next(!this.isPoolsChipActive.value);
        break;
      case 'sh':
        this.isShChipActive.next(!this.isShChipActive.value);
        break;
      case 'jobs':
        this.isJobsChipActive.next(!this.isJobsChipActive.value);
        break;
      case 'offers':
        this.isOffersChipActive.next(!this.isOffersChipActive.value);
        break;
      case 'events':
        this.isEventsChipActive.next(!this.isEventsChipActive.value);
        break;
      case 'planet':
        this.isPlanetChipActive.next(!this.isPlanetChipActive.value);
        break;
      default:
        break;
    }
  }

  private listenPodsChanges() {
    combineLatest([
      this.podStore.selectItems(),
      this.searchString,
      this.isPodsChipActive,
    ])
      .pipe(
        takeUntil(this.destroy$),
        map(([items, searchString, isActive]) => {
          return (
            isActive &&
            items &&
            items.filter(item => item.name.toLowerCase().includes(searchString))
          );
        })
      )
      .subscribe(items => {
        this.pods = items;
      });
  }

  private listenPoolsChanges() {
    combineLatest([
      this.poolsStore.selectItems(),
      this.searchString,
      this.isPoolsChipActive,
    ])
      .pipe(
        takeUntil(this.destroy$),
        map(([items, searchString, isActive]) => {
          return (
            isActive &&
            items &&
            items.filter(item => item.name.toLowerCase().includes(searchString))
          );
        })
      )
      .subscribe(items => {
        this.pools = items;
      });
  }

  private listenPlanetChanges() {
    combineLatest([
      this.planetStore.selectItems(),
      this.searchString,
      this.isPlanetChipActive,
    ])
      .pipe(
        takeUntil(this.destroy$),
        map(([items, searchString, isActive]) => {
          return (
            isActive &&
            items &&
            items.filter(item =>
              item.title.toLowerCase().includes(searchString)
            )
          );
        })
      )
      .subscribe(items => {
        this.planet = items;
      });
  }

  private listenServicesChanges() {
    combineLatest([
      this.servicesStore.selectItems(),
      this.searchString,
      this.isOffersChipActive,
    ])
      .pipe(
        takeUntil(this.destroy$),
        map(([items, searchString, isActive]) => {
          return (
            isActive &&
            items &&
            items.filter(item =>
              item.title.toLowerCase().includes(searchString)
            )
          );
        })
      )
      .subscribe(items => {
        this.services = items;
      });
  }

  private listenEventsChanges() {
    combineLatest([
      this.eventsStore.selectItems(),
      this.searchString,
      this.isEventsChipActive,
    ])
      .pipe(
        takeUntil(this.destroy$),
        map(([items, searchString, isActive]) => {
          return (
            isActive &&
            items &&
            items.filter(item =>
              item.title.toLowerCase().includes(searchString)
            )
          );
        })
      )
      .subscribe(items => {
        this.events = items;
      });
  }

  private listenSecondHandChanges() {
    combineLatest([
      this.secondHandStore.selectItems(),
      this.searchString,
      this.isShChipActive,
    ])
      .pipe(
        takeUntil(this.destroy$),
        map(([items, searchString, isActive]) => {
          return (
            isActive &&
            items &&
            items.filter(item => item.name.toLowerCase().includes(searchString))
          );
        })
      )
      .subscribe(items => {
        this.secondHandItems = items;
      });
  }

  private listenJobsChanges() {
    combineLatest([
      this.jobStore.selectItems(),
      this.searchString,
      this.isJobsChipActive,
    ])
      .pipe(
        takeUntil(this.destroy$),
        map(([items, searchString, isActive]) => {
          return (
            isActive &&
            items &&
            items.filter(item =>
              item.title.toLowerCase().includes(searchString)
            )
          );
        })
      )
      .subscribe(items => {
        this.jobs = items;
      });
  }
}
