import { Component, OnDestroy, OnInit } from '@angular/core';
import { eventFormData, IEventFilter } from '../events';
import { Subject, filter, takeUntil } from 'rxjs';
import { FormService } from '../../shared/components/form/form.service';
import { EventsStore } from '../events-store';
import { UserService } from 'src/app/auth/user.service';

@Component({
  selector: 'app-event-filter',
  templateUrl: './event-filter.component.html',
  styleUrls: ['./event-filter.component.scss'],
})
export class EventFilterComponent implements OnInit, OnDestroy {
  toolbarData = {
    arrowRouter: '/events',
    title: 'Events',
  };
  currentUser;
  eventFormData = eventFormData;
  color = 'dark';
  myContent: boolean = false;
  country: string | null = null;
  user: string;
  creator;
  menuInitialData;
  private destroy$ = new Subject<void>();

  constructor(
    private formService: FormService,
    private eventStore: EventsStore,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.formService.formData$.next(eventFormData);
    this.listenFormChanges();
    this.getUser();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onFormGroupChange(_e) {
    _e.patchValue(this.menuInitialData);
  }

  private getUser() {
    this.userService.currentUser$
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user.uid)
      )
      .subscribe(user => {
        this.currentUser = user;
      });
  }

  private listenFormChanges() {
    this.formService.eventMenuData$
      .pipe(
        takeUntil(this.destroy$),
        filter(data => !!data)
      )
      .subscribe((filterData: IEventFilter) => {
        if (filterData.myContent) {
          // @ts-ignore
          filterData.creator = this.currentUser?.id;
        }
        this.eventStore.getItems(filterData);

        if (filterData) {
          this.menuInitialData = filterData;
          this.color = 'success';
        } else {
          this.color = 'dark';
        }
      });
  }
}
