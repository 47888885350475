import { Injectable } from '@angular/core';
import { CrudHelper } from '../firebase/helpers/crudHelper';
export interface IAppConfig {
  isAppPremium: boolean;
  test: string;
}

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  constructor(private crudHelper: CrudHelper) {}

  getConfig = () => {
    return this.crudHelper.getCollectionItems({
      collectionName: 'configurations',
      queryFn: null,
    });
  };
}
