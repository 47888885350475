import { Injectable } from '@angular/core';
import { IBalanceInfo, IUser } from './auth.models';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth } from 'firebase/auth';
import {
  map,
  catchError,
  filter,
  BehaviorSubject,
  switchMap,
  combineLatest,
} from 'rxjs';
import { AppConfigService } from '../services/config.service';
import { WonderPush } from '@awesome-cordova-plugins/wonderpush/ngx';
import { PushNotificationsService } from '../services/push-notifications.service';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  initialUser: IUser = {
    id: null,
    uid: null,
    email: null,
    username: null,
    discordLink: null,
    discordUserName: null,
    password: null,
    image: null,
    country: null,
    city: null,
    postalCode: null,
    birthday: null,
    galleryVisibility: null,
    gender: 'Other',
    googleMapLink: null,
    mermaidExpLvl: null,
    mersonaName: null,
    profilePicture: null,
    isMailingEnabled: true,
    isPersonalPremium: null,
    platform: null,
  };
  user: IUser;
  currentUser$ = new BehaviorSubject<IUser>(this.initialUser);
  isPremium: boolean;
  isAdmin: boolean;
  isAppPremium: boolean;
  isPaidPremium: boolean;
  isPersonalPremium: boolean;
  balanceInfo: IBalanceInfo;
  userSubscriptionInfo;

  constructor(
    private fireStore: AngularFirestore,
    private configService: AppConfigService,
    private wonderPush: WonderPush,
    private platform: Platform,
    private notificationsService: PushNotificationsService
  ) {}

  setUser = async () => {
    getAuth().onAuthStateChanged(async currentUser => {
      if (currentUser) {
        this.getSubscriptionInfo(currentUser);
        this.getUserByUid(currentUser.uid)
          .pipe(
            map(async (user: IUser) => {
              this.user = Object.assign({
                ...this.initialUser,
                ...user,
              });
            }),
            switchMap((user: any) =>
              combineLatest([
                this.listenBalanceInfoDetails(currentUser.uid),
                this.configService.getConfig(),
              ]).pipe(
                filter(
                  ([user, appPremium]) => !!user && appPremium !== undefined
                )
              )
            )
          )
          .subscribe(async ([info, config]) => {
            this.balanceInfo = info as any;
            this.isAdmin = this.balanceInfo?.role === 'admin';
            this.setIsUserPremium(config);
            this.currentUser$.next(this.user);
            this.platform.ready().then(() => {
              if (this.platform.is('capacitor')) {
                this.wonderPush.setUserConsent(true);
                this.wonderPush.setUserId(this.user.uid);
                this.wonderPush.subscribeToNotifications();
                this.notificationsService.getUserProperties(
                  this.wonderPush,
                  'capacitor'
                );
              } else {
                this.notificationsService.initWonderPush(this.user.uid);
              }
            });
          });
      }
    });
  };

  getUserByUid = (uid: string) => {
    return this.fireStore
      .collection('users', ref => ref.where('uid', '==', uid))
      .snapshotChanges()
      .pipe(
        filter(user => !!user),
        map(user => {
          return Object.assign(
            { id: user[0].payload.doc.id },
            user[0].payload.doc.data()
          );
        }),

        catchError(async e => {
          console.log(`User with uid ${uid} not found or not exist`);
          return { id: '' };
        })
      );
  };

  deleteProfile = async () => {
    this.wonderPush.clearAllData();
    return new Promise(async (resolve, reject) => {
      const functions = getFunctions();
      const isDeleted = httpsCallable(functions, 'deleteUser');
      return isDeleted().then(data => {
        data ? resolve(data) : reject('Firebase error');
      });
    });
  };


  async getSubscriptionInfo(currentUser) {
    const decodedToken = await currentUser.getIdTokenResult();
    const premium = decodedToken.claims.stripeRole === 'premium';
    await this.fireStore
      .collection('users')
      .doc(currentUser.uid)
      .collection('subscriptions')
      .ref.where('status', 'in', ['trialing', 'active'])

      .onSnapshot(async snapshot => {
        // if (premium) {
        //   const doc = snapshot.docs[0];
        //   this.userSubscriptionInfo = doc?.data();
        // } else {
        //   this.userSubscriptionInfo = false;
        // }
        this.isPaidPremium = !!snapshot.docs[0]?.data();
      });
  }

  listenBalanceInfoDetails(uid) {
    return this.fireStore.collection('info').doc(uid).valueChanges();
  }

  isUserAuth() {
    return getAuth().currentUser;
  }

  setIsUserPremium = async config => {
    this.isAppPremium = config[0].isAppPremium;
    this.isPersonalPremium = this.user.isPersonalPremium;
    this.isPremium =
      this.isPersonalPremium || this.isAppPremium || this.isPaidPremium;
  };
}
