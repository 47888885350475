export const environment = {
  app: 'danceApp',
  production: false,
  api: '/api',
  hostUrl: 'http://localhost:4200/',
  firebaseConfig: {
    apiKey: "AIzaSyCDevqkWOstZBHz9u80bFa8SeWfbEv1M9k",
    authDomain: "danceunitesus-production.firebaseapp.com",
    projectId: "danceunitesus-production",
    storageBucket: "danceunitesus-production.appspot.com",
    messagingSenderId: "84473643272",
    appId: "1:84473643272:web:ee25588285a806c5442cbd",
    measurementId: "G-8D49CE6YH1"
  },
  wonderPushWebKey: "af631c21596f96cf39c6335a6a90a1e6f25ce7631773af06d98271a8d24a9f3e",
  wonderpushIos: {
    clientSecret:
      'e0108ff8443647c27a69426613b7ca5be7bd295cfee344cc71580a52d3be45f7',
    clientId: 'd22bfecf9b0ed12909d981a9a66d7aad82cf499a',
  },
  googleMapKey: 'AIzaSyDEvI_b9iqwj_YzueWIhYf3QJPyxQis5YQ',
  routers: {},
  payment: {
    stripePublickKey:
      'pk_test_51PDbhaI7FIQNTjpUVEA9T8LknIAkFu3blWDsUdY72It8SCT00SZc9glJsXnwtKJtSqBbEeo2j8sj8zSS1rQswnYl00LZsR4smP',
    PERLS_MODE: 'payment',
    SUBSCRIPTION_MODE: 'subscription',
    EIGHT_PEARLS: {
      PRODUCT_ID: 'prod_Q3tEWA0LJGdBmK',
      PRICE_ID: 'price_1PDlS0I7FIQNTjpUvInCkZij',
      pearls: 8,
    },
    FORTY_TWO_PEARLS: {
      PRODUCT_ID: 'prod_Q3tEWbCLZdP9QC',
      PRICE_ID: 'price_1PDlSLI7FIQNTjpUBM7hnrgD',
      pearls: 42,
    },
    NINETY_PEARLS: {
      PRODUCT_ID: 'prod_Q3tFNzpQNkk5P9',
      PRICE_ID: 'price_1PDlSwI7FIQNTjpUNib75fGM',
      pearls: 90,
    },
    TWO_HUNDRES_PEARLS: {
      PRODUCT_ID: 'prod_Q3tFJMXlV9IZXG',
      PRICE_ID: 'price_1PDlTII7FIQNTjpU3NWVpysK',
      pearls: 200,
    },
    FIVE_HUNDRED_AND_FORTY_PEARLS: {
      PRODUCT_ID: 'prod_Q3tGnTqaYdkzUl',
      PRICE_ID: 'price_1PDlTdI7FIQNTjpUxBKwD8M6',
      pearls: 540,
    },
    ONE_THOUSAND_TWO_HUNDRED_PEARLS: {
      PRODUCT_ID: 'prod_Q3t4hpX15HwmLg',
      PRICE_ID: 'price_1PDlHyI7FIQNTjpUFrhMHnUP',
      pearls: 1200,
    },
    ONE_MONTH_SUBSCRIPTION: {
      PRODUCT_ID: 'prod_Q3t8ustyx2HdN4',
      PRICE_ID: 'price_1PDlN5I7FIQNTjpU9XbNg07N',
      period: '1 Month',
    },
    THREE_MONTHS_SUBSCRIPTION: {
      PRODUCT_ID: 'prod_Q3t8ustyx2HdN4',
      PRICE_ID: 'price_1PDlNkI7FIQNTjpUixnbYJtA',
      period: '3 Months',
    },
    ONE_YEAR_SUBSCRIPTION: {
      PRODUCT_ID: 'prod_Q3t8ustyx2HdN4',
      PRICE_ID: 'price_1PDlO4I7FIQNTjpUJmllmUGl',
      period: '1 Year',
    },
  },
  mixpanelToken: '54bb9ad531e8ef4477d7859975f130d0',
  discordServerId: '1219036408447238176',
  discordServerInviteLink: 'https://discord.gg/XmqMPvn6ue',
  pipeDreamUrl: 'https://eojykymstdmqyi1.m.pipedream.net',
  discordServerLink: 'https://discord.com/channels/1219036408447238176',
};