import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { filter, Subject, takeUntil } from 'rxjs';
import { AuthService } from '../../firebase/auth.service';
import mixpanel from 'mixpanel-browser';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-planet-item',
  templateUrl: './planet-item.component.html',
  styleUrls: ['./planet-item.component.scss'],
})
export class PlanetItemComponent implements OnInit, OnDestroy {
  @Input() items;
  @Input() status;
  @Input() planetItem;
  @Input() divider: {
    title: string;
    color: string;
    icon?: string;
    flag?: boolean;
  };
  user;
  properties;
  showStatusModal: boolean = false;
  itemStatus;
  today = { seconds: new Date().getTime() / 1000 };

  private destroy$ = new Subject<void>();

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    mixpanel.init(environment.mixpanelToken, { debug: true });
    const { uid } = this.authService.isUserAuth();
    mixpanel.identify(uid);
    this.getUser();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  trackEvent(event, parameter, parameterValue) {
    if (parameter === 'screen-name') {
      this.properties = {
        'screen-name': parameterValue,
      };
    }

    mixpanel.track(event, this.properties);
  }

  setDefaultImg(e: HTMLImageElement) {
    e.src = './../../../assets/pic.svg';
  }

  openStatusModal(status?) {
    this.showStatusModal = !this.showStatusModal;
    if (status) this.itemStatus = status;
  }

  private getUser() {
    const { uid } = this.authService.isUserAuth();

    this.authService
      .getUserByUid(uid)
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user)
      )
      .subscribe(user => (this.user = user));
  }
}
