import { Component, OnDestroy, OnInit } from '@angular/core';
import { IPlanetFilter, planetFormData } from '../planet';
import { FormService } from '../../shared/components/form/form.service';
import { PlanetStore } from '../planet-store';
import { AuthService } from '../../firebase/auth.service';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-planet-filter',
  templateUrl: './planet-filter.component.html',
  styleUrls: ['./planet-filter.component.scss'],
})
export class PlanetFilterComponent implements OnInit, OnDestroy {
  toolbarData = {
    arrowRouter: '/stage/challenges',
    title: 'Planet',
  };
  currentUser;
  planetFormData = planetFormData;
  color = 'dark';
  myContent: boolean = false;
  menuInitialData;
  private destroy$ = new Subject<void>();
  constructor(
    private formService: FormService,
    private planetStore: PlanetStore,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.formService.formData$.next(planetFormData);
    this.listenFormChanges();
    this.getUser();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onFormGroupChange(_e) {
    _e.patchValue(this.menuInitialData);
  }

  private getUser() {
    const { uid } = this.authService.isUserAuth();

    this.authService
      .getUserByUid(uid)
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user)
      )
      .subscribe(user => {
        this.currentUser = user;
        this.listenFormChanges();
      });
  }

  private listenFormChanges() {
    this.formService.planetMenuData$
      .pipe(
        takeUntil(this.destroy$),
        filter(data => !!data)
      )
      .subscribe((filterData: IPlanetFilter) => {
        if (filterData.myContent) {
          // @ts-ignore
          filterData.creator = this.currentUser.id;
        }
        this.planetStore.getItems(filterData);

        if (filterData) {
          this.menuInitialData = filterData;
        }
      });
  }

}
