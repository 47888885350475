import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ShopsComponent } from './shops/shops.component';
import { BazaarMenuComponent } from './bazaar-menu/bazaar-menu.component';
import { SecondHandComponent } from './second-hand/second-hand.component';
import { AddSecondHandItemComponent } from './second-hand/add-second-hand-item/add-second-hand-item.component';
import { EditSecondHandItemComponent } from './second-hand/edit-second-hand-item/edit-second-hand-item.component';
import { SecondHandDetailsComponent } from './second-hand/second-hand-details/second-hand-details.component';

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild([
      {
        path: '',
        component: BazaarMenuComponent,
      },

      {
        path: 'shops',
        component: null,
        children: [
          {
            path: '',
            component: ShopsComponent,
          },
        ],
      },
      {
        path: 'second-hand',
        component: null,
        children: [
          {
            path: '',
            component: SecondHandComponent,
          },
          {
            path: 'add',
            component: AddSecondHandItemComponent,
          },
          {
            path: 'edit/:id',
            component: EditSecondHandItemComponent,
          },
          {
            path: ':id',
            component: SecondHandDetailsComponent,
          },
        ],
      },
      {
        path: 'job&services',

        loadChildren: () =>
          import('./jobs-services/jobs-services.module').then(
            m => m.JobsServicesModule
          ),
      },
    ]),
  ],
  exports: [RouterModule],
})
export class BazaarRoutingModule {}
