export enum positionType {
  TOP = 'top',
  BOTTOM = 'bottom',
  MIDDLE = 'middle',
}

import { ToastController } from '@ionic/angular';

export const createToaster = (
  message: string,
  color: string,
  position: positionType
) => {
  const controller = new ToastController();
  controller
    .create({
      message,
      color: color,
      position: position,
      duration: 3000,
      buttons: [
        {
          side: 'start',
          icon: 'close',
          role: 'cancel',
        },
      ],
    })
    .then(t => {
      t.present();
    });
};
