import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ProfileComponent } from './profile.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';
import { redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { FollowTabsComponent } from './follow-tabs/follow-tabs.component';
import { FollowersListComponent } from './followers-list/followers-list.component';
import { FollowingListComponent } from './following-list/following-list.component';
import { NotificationSettingsComponent } from './notification-settings/notification-settings.component';
import { BlockListComponent } from './block-list/block-list.component';

const redirectUnauthorizedToLogin = () =>
  redirectUnauthorizedTo(['auth/login']);

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '',
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectUnauthorizedToLogin },
        children: [
          {
            path: ':id',
            pathMatch: 'full',
            component: ProfileComponent,
          },
          {
            path: ':id/follow',
            component: FollowTabsComponent,
            children: [
              {
                path: 'followers',
                component: FollowersListComponent,
              },
              {
                path: 'following',
                component: FollowingListComponent,
              },
              {
                path: 'blockList',
                component: BlockListComponent,
              },
            ],
          },
          {
            path: 'edit/:id',

            component: null,
            children: [
              {
                path: '',
                component: EditProfileComponent,
              },
              {
                path: 'notification_settings',
                component: NotificationSettingsComponent,
              },
            ],
          },
        ],
      },
    ]),
  ],
  exports: [RouterModule],
})
export class ProfileRoutingModule {}
