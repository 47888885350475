import { Component, OnInit } from '@angular/core';
import { Subject, filter, takeUntil } from 'rxjs';
import { UserService } from 'src/app/auth/user.service';
import { settings } from './notifications-settings-data';
import { ProfileStore } from '../store/profile-store';
import { IUser } from 'src/app/auth/auth.models';
import { PushNotificationsService } from 'src/app/services/push-notifications.service';
import { Platform } from '@ionic/angular';
import { WonderPush } from '@awesome-cordova-plugins/wonderpush/ngx';

@Component({
  selector: 'app-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss'],
})
export class NotificationSettingsComponent implements OnInit {
  constructor(
    private userService: UserService,
    private profileStore: ProfileStore,
    private wonderPushService: PushNotificationsService,
    private platform: Platform,
    private wonderPush: WonderPush
  ) {}
  user;
  settings = settings;
  loading = false;
  pushNotifications = this.wonderPushService.userProperties$;
  private destroy$ = new Subject<void>();

  ngOnInit() {
    this.getUser();
    this.listenStatusChanges();
  }

  private getUser() {
    this.userService.currentUser$
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user.uid)
      )
      .subscribe((user: IUser) => {
        this.user = user;

        this.profileStore.getItemByByValue({ uid: user.uid });
      });
  }

  updateNotificationSettings(e) {
    if (this.platform.is('capacitor')) {
      let userProperties = this.wonderPushService.userProperties$.value;
      if (userProperties.includes(e.title)) {
        userProperties = userProperties.filter(item => item !== e.title);
      } else {
        userProperties.push(e.title);
      }
      this.wonderPushService.userProperties$.next(userProperties);
      this.wonderPush.putProperties({
        string_merfolkTopics: userProperties,
      });
    } else {
      this.wonderPushService.updateUsersProperties(e);
    }
  }

  private listenStatusChanges() {
    // TODO if need a loading but it works not perfect
    // this.profileStore.selectLoading().subscribe(loading => {
    //   if (this.loading !== loading) {
    //     console.log(loading);
    //     this.loading = loading;
    //   }
    // });
  }
}
