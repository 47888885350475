export const settings = [
  {
    title: 'Merfolk',
    text: 'Updates only posted pods and pools in your area ore someone interacting with your pod',
    mailingBtn: true,
    pushBtn: true,
  },
  {
    title: 'Events',
    text: "Never miss a MerCalendar events & get reminders for events you've registered for",
    mailingBtn: true,
    pushBtn: true,
  },
  {
    title: 'Daily Challenges',
    text: 'Daily Challenges reminders & updates when others participated in the same ones as you to see their experiences',
    mailingBtn: true,
    pushBtn: true,
  },
  {
    title: 'Bazaar',
    text: 'Updates on shops, specials deals, second-hand items and service exchange content',
    mailingBtn: true,
  },
  {
    title: 'Comments',
    text: 'Comments on your posts and replies to your comments',
    pushBtn: true,
  },
  {
    title: 'Likes',
    text: 'Likes received foe any of your posted content',
    pushBtn: true,
  },
  {
    title: 'News, updates & tutorials',
    text: "To help you stay up-to-date on what's going on in the mermaiding world & MerMapp",
    mailingBtn: true,
  },
  {
    title: 'User Research',
    text: 'Take a part in beta testing or participate in (sometimes paid) user research',
    mailingBtn: true,
  },
];

export interface INotifyData {
  title: string;
  type: string;
}
