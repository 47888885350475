import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { EMPTY, from, Observable, Subject } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import {
  and,
  collection,
  getCountFromServer,
  getFirestore,
  query,
  where,
} from 'firebase/firestore';

@Injectable({
  providedIn: 'root',
})
export class CrudHelper {
  constructor(private fireStore: AngularFirestore) {}

  getHelper = ({ collectionName }) => {
    return this.fireStore
      .collection(collectionName)
      .snapshotChanges()
      .pipe(
        map(events => {
          return events.map(a =>
            Object.assign(
              {
                id: a.payload.doc.id,
              },
              a.payload.doc.data()
            )
          );
        }),
        distinctUntilChanged((prev, curr) => {
          return (
            JSON.stringify(prev).split('').sort().join('') ===
            JSON.stringify(curr).split('').sort().join('')
          );
        })
      );
  };

  createDocument = ({ collectionName, data }) => {
    const resultSubject = new Subject<string>();
    this.fireStore
      .collection(collectionName)
      .add(data)
      .then((data: any) => {
        resultSubject.next(data.id);
      })
      .catch(error => resultSubject.error(error));

    return resultSubject;
  };

  setDocument = (itemId, { collectionName, data }) => {
    const resultSubject = new Subject<string>();

    this.fireStore
      .collection(collectionName)
      .doc(itemId)
      .set(data)
      .then((data: any) => {
        resultSubject.next(data.id);
      })
      .catch(error => resultSubject.error(error));

    return resultSubject;
  };

  setHelper = ({ collectionName, data }) => {
    return this.fireStore
      .collection(collectionName)
      .add(data)
      .then((data: any) => {
        return data.id;
      });
  };

  getById = ({ id, collection }): Observable<any> => {
    return this.fireStore
      .collection(collection, ref => ref.where('id', '==', id))
      .snapshotChanges()
      .pipe(
        map(doc => {
          return Object.assign(
            {
              id: doc[0].payload.doc.id,
            },
            doc[0].payload.doc.data()
          );
        })
      );
  };

  updateItem = ({ data, id, collectionName }) => {
    return from(
      this.fireStore
        .collection(collectionName)
        .doc(id)
        .update(data)
        .finally(() => {
          console.log('Successfully updated');
        })
        .catch(error => {
          console.log(error);
        })
    );
  };

  deleteHelper = ({ collectionName, id }) => {
    const resultSubject = new Subject<boolean>();

    this.fireStore
      .collection(collectionName)
      .doc(id)
      .delete()
      .finally(() => {
        resultSubject.next(true);
      })
      .catch(error => EMPTY);
    return resultSubject;
  };

  searchByField(collectionName, searchFieldName, searchValue) {
    return this.fireStore
      .collection(collectionName, ref =>
        ref.where(searchFieldName, '==', searchValue).limit(1)
      )
      .valueChanges({ idField: 'id' });
  }

  searchHelper = async ({ collectionName, searchField, limit, start, end }) => {
    return this.fireStore
      .collection(collectionName, ref =>
        ref.limit(limit).orderBy(searchField).startAt(start).endAt(end)
      )
      .snapshotChanges()
      .pipe(
        map(data => {
          return data.map(item =>
            Object.assign(
              {
                id: item.payload.doc.id,
              },
              item.payload.doc.data()
            )
          );
        }),
        distinctUntilChanged((prev, curr) => {
          return (
            JSON.stringify(prev).split('').sort().join('') ===
            JSON.stringify(curr).split('').sort().join('')
          );
        })
      );
  };

  getCollectionItems = ({ collectionName, queryFn }) => {
    return this.fireStore
      .collection(collectionName, ref => {
        if (queryFn) {
          return queryFn(ref);
        }
        return ref;
      })

      .snapshotChanges()
      .pipe(
        map(data => {
          return data.map(item => {

            return Object.assign(
              {
                id: item.payload.doc.id,
              },
              item.payload.doc.data()
            );
          });
        }),

        distinctUntilChanged((prev, curr) => {
          return (
            JSON.stringify(prev).split('').sort().join('') ===
            JSON.stringify(curr).split('').sort().join('')
          );
        })
      );
  };

  getPreviewItems = ({
    collectionName,
    userId,
    limit,
    forbidden,
  }): Observable<any> => {
    const queryFn = ref => {
      let query = ref;
      if (forbidden) {
        query = query
          .where('creator.id', '==', userId)
          .where('status', '==', 'approved');
        return query;
      } else {
        return (query = query.where('creator.id', '==', userId));
      }
    };

    return this.fireStore
      .collection(collectionName, ref =>
        queryFn(ref).limit(limit).orderBy('createdAt', 'desc')
      )
      .snapshotChanges()
      .pipe(
        map(data => {
          return data.map(item =>
            Object.assign(
              {
                id: item.payload.doc.id,
              },
              item.payload.doc.data()
            )
          );
        }),
        distinctUntilChanged((prev, curr) => {
          return (
            JSON.stringify(prev).split('').sort().join('') ===
            JSON.stringify(curr).split('').sort().join('')
          );
        })
      );
  };

  getPreviewEvents = ({ limit, podId }): Observable<any> => {
    const queryFn = ref => {
      let query = ref;

      query = query
        .where('podName', '==', podId)
        .where('status', '==', 'approved');
      return query;
    };

    return this.fireStore
      .collection('events', ref =>
        queryFn(ref).limit(limit).orderBy('createdAt', 'desc')
      )
      .snapshotChanges()
      .pipe(
        map(data => {
          return data.map(item =>
            Object.assign(
              {
                id: item.payload.doc.id,
              },
              item.payload.doc.data()
            )
          );
        }),
        distinctUntilChanged((prev, curr) => {
          return (
            JSON.stringify(prev).split('').sort().join('') ===
            JSON.stringify(curr).split('').sort().join('')
          );
        })
      );
  };

  getFirstPaginatedItems = ({
    collectionName,
    userId,
    limit,
    forbidden,
  }): Observable<any> => {
    const queryFn = ref => {
      let query = ref;
      if (forbidden) {
        query = query
          .where('creator.id', '==', userId)
          .where('status', '==', 'approved');
        return query;
      } else {
        return (query = query.where('creator.id', '==', userId));
      }
    };

    return this.fireStore
      .collection(collectionName, ref =>
        queryFn(ref).orderBy('createdAt', 'desc').limit(limit)
      )
      .snapshotChanges();
  };

  getFirstPagePaginatedItems = ({
    collectionName,
    queryFn,
    limit,
    sortBy,
  }): Observable<any> => {
    return this.fireStore
      .collection(collectionName, ref => {
        if (queryFn) {
          return queryFn(ref).orderBy(sortBy).limit(limit);
        }
        return ref.orderBy(sortBy).limit(limit);
      })
      .snapshotChanges();
  };

  getNextPaginatedItems = ({
    collectionName,
    userId,
    limit,
    lastInResponse,
    forbidden,
  }): Observable<any> => {
    const queryFn = ref => {
      let query = ref;
      if (forbidden) {
        query = query
          .where('creator.id', '==', userId)
          .where('status', '==', 'approved');
        return query;
      } else {
        return (query = query.where('creator.id', '==', userId));
      }
    };

    return this.fireStore
      .collection(collectionName, ref =>
        queryFn(ref)
          .orderBy('createdAt', 'desc')
          .startAfter(lastInResponse)
          .limit(limit)
      )
      .snapshotChanges();
  };

  getPrevPaginatedItems = ({
    collectionName,
    userId,
    limit,
    prevFirstInResponse,
    forbidden,
  }): Observable<any> => {
    const queryFn = ref => {
      let query = ref;
      if (forbidden) {
        query = query
          .where('creator.id', '==', userId)
          .where('status', '==', 'approved');
        return query;
      } else {
        return (query = query.where('creator.id', '==', userId));
      }
    };

    return this.fireStore
      .collection(collectionName, ref =>
        queryFn(ref)
          .orderBy('createdAt', 'desc')
          .endBefore(prevFirstInResponse)
          .limitToLast(limit)
      )
      .snapshotChanges();
  };

  getLastPaginatedItems = ({
    collectionName,
    userId,
    limit,
    forbidden,
  }): Observable<any> => {
    const queryFn = ref => {
      let query = ref;
      if (forbidden) {
        query = query
          .where('creator.id', '==', userId)
          .where('status', '==', 'approved');
        return query;
      } else {
        return (query = query.where('creator.id', '==', userId));
      }
    };

    return this.fireStore
      .collection(collectionName, ref =>
        queryFn(ref).orderBy('createdAt', 'desc').limitToLast(limit)
      )
      .snapshotChanges();
  };

  getCountItems = ({ collectionName, userId, forbidden }) => {
    const collectionRef = collection(getFirestore(), collectionName);
    if (forbidden) {
      const snapshot = query(
        collectionRef,
        and(
          where('status', '==', 'approved'),
          where('creator.id', '==', userId)
        )
      );
      return from(getCountFromServer(snapshot)).pipe(
        map(items => items.data().count)
      );
    } else {
      const snapshot = query(collectionRef, where('creator.id', '==', userId));
      return from(getCountFromServer(snapshot)).pipe(
        map(items => items.data().count)
      );
    }
  };
}
