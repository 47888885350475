import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ISlide, TutorialService } from 'src/app/tutorial/tutorial.service';
import { environment } from 'src/environments/environment';
import SwiperCore, { Navigation, Swiper, SwiperOptions } from 'swiper';
Swiper.use([Navigation]);

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TutorialComponent implements OnInit {
  slides$ = new BehaviorSubject<ISlide[]>([]);
  config: SwiperOptions;
  isOpen = true;
  environment = environment;

  constructor(private service: TutorialService, private router: Router) {}

  ngOnInit(): void {
    this.slides$.next(this.service.tutorialData);
    this.config = {
      modules: [Navigation],
      pagination: { clickable: true, dynamicBullets: false },
      injectStyles: [
        `
        .swiper-button-next::after,
        .swiper-button-prev::after {
          color: #F72585;
        }`,
      ],
    };
    
    this.isOpen = true;
  }

  toHome() {
    this.isOpen = false;
    setTimeout(() => {
      this.router.navigate(['/mood'], { replaceUrl: true });
    }, 0);
  }
}
