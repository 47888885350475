import { Component, OnInit } from '@angular/core';
import { IUser } from '../auth.models';
import { UserService } from '../user.service';
import { Subject, filter, takeUntil } from 'rxjs';
import { ProfileStore } from 'src/app/profile/store/profile-store';
import { MapService } from 'src/app/services/map.service';
import { Router } from '@angular/router';
import mixpanel from 'mixpanel-browser';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-choose-style',
  templateUrl: './choose-style.component.html',
  styleUrls: ['./choose-style.component.scss'],
})
export class ChooseStyleComponent implements OnInit {
  stylesList = [
    'Salsa',
    'Bachata',
    'Kizomba',
    'African Dances',
    'Zouk',
    'Tango',
    'Ballroom',
    'HipHop',
    'Bellydances',
    'Indian dances',
    'Ballet',
    'American dances',
    'Free & improvised dances',
    'Disco & Soul dances',
    'I want to see everything together'
  ]

  selectedOption: string;
  currentUser: IUser;
  user;
  authUserId;
  private destroy$ = new Subject<void>();

  constructor(private userService: UserService, private router: Router, private profileStore: ProfileStore, private mapService: MapService,) { }

  private getUser() {
    this.userService.currentUser$
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user.uid)
      )
      .subscribe(user => {
        this.user = user;
        this.authUserId = user.id;
      });
  }

  updateUser() {
    const newUser = {
      ...this.user,
      favouriteStyle: this.selectedOption
    };

    try {
      this.profileStore.updateProfile(newUser);

      mixpanel.init(environment.mixpanelToken, { debug: true });
      mixpanel.identify(this.authUserId);

      mixpanel.people.set({
        'Preferred Dancing Style': this.selectedOption
      });
    } finally {
      this.router.navigate(['/tutorial/1'], { replaceUrl: true });
    }
  }

  ngOnInit() {
    this.getUser();
  }

}
