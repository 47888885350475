import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { JobsServicesComponent } from './jobs-services.component';
import { JobsComponent } from './jobs/jobs.component';
import { JobsDetailsComponent } from './jobs/jobs-details/jobs-details.component';
import { ServicesComponent } from './services/services.component';
import { ServicesDetailsComponent } from './services/services-details/services-details.component';

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild([
      {
        path: '',
        component: JobsServicesComponent,
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: '/bazaar/job&services/jobs',
          },
          {
            path: 'jobs',
            component: JobsComponent,
          },
          {
            path: 'jobs/:id',
            component: JobsDetailsComponent,
          },
          {
            path: 'services',
            component: ServicesComponent,
          },
          {
            path: 'services/:id',
            pathMatch: 'full',
            component: ServicesDetailsComponent,
          },
        ],
      },
    ]),
  ],
  exports: [RouterModule],
})
export class JobsServicesRoutingModule {}
