import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inviteRequests',
})
export class InviteRequestsPipe implements PipeTransform {
  transform(value: unknown, userId: string): unknown {
    if (Array.isArray(value) && value) {
      const newValue = value.filter(item => {
        return (
          item.memberRequests &&
          item.memberRequests.length &&
          item.memberRequests.find(request => request.id === userId) &&
          item.status === 'approved'
        );
      });
      return newValue;
    } else return value;
  }
}
