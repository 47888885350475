import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormService } from '../../shared/components/form/form.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { eventFormData } from '../../events/events';
import { PlanetService } from '../planet.service';
import { PlanetStore } from '../planet-store';
import { filter, Subject, takeUntil } from 'rxjs';
import { AuthService } from '../../firebase/auth.service';
import { environment } from '../../../environments/environment';
import mixpanel from 'mixpanel-browser';
import { Router } from '@angular/router';
import { ActivityFeedStore } from '../../activity-feed/activity-feed-store';
import { ImagesService } from 'src/app/services/images.service';
import { showLoading } from 'src/app/shared/components/modals/loading';

@Component({
  selector: 'app-add-experience',
  templateUrl: './add-experience.component.html',
  styleUrls: ['./add-experience.component.scss'],
})
export class AddExperienceComponent implements OnInit, OnDestroy {
  @Input() showAddExperienceModal;
  @Input() planetItem;
  @Input() feedItem;
  @Input() planetItemId: string;
  @Input() isTodaysChallenge;
  @Output() showAddExperienceModalChange = new EventEmitter<any>();
  @ViewChild('imageLoader') imageLoader;
  addingBtnDisabled = true;
  imageField = eventFormData.filter(i => i.type === 'ARRAY');
  imagesArr: string[];
  user;
  addExperienceForm: FormGroup;
  form: FormGroup;
  imagesControls = 1;
  properties;
  environment = environment;
  loader = showLoading();
  private destroy$ = new Subject<void>();
  constructor(
    private formService: FormService,
    private fb: FormBuilder,
    private imagesService: ImagesService,
    private router: Router,
    private planetService: PlanetService,
    private planetStore: PlanetStore,
    private authService: AuthService,
    private activityFeed: ActivityFeedStore
  ) {}

  ngOnInit() {
    mixpanel.init(environment.mixpanelToken, { debug: true });
    const { uid } = this.authService.isUserAuth();
    mixpanel.identify(uid);
    this.planetItem.id = this.planetItemId;
    this.getUser();
    this.statusListen();
    this.addExperienceForm = this.fb.group({
      images: this.fb.array([
        {
          0: this.fb.group({
            image: [''],
          }),
        },
      ]),
      experience: ['', Validators.required],
      creator: [''],
    });

    this.imagesService.compressedImages
      .pipe(takeUntil(this.destroy$))
      .subscribe(images => {
        this.imagesArr = images;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.imagesService.reset();
  }

  trackEvent(event, parameter, parameterValue) {
    if (parameter === 'challenge-title') {
      this.properties = {
        'challenge-title': parameterValue,
      };
    }

    mixpanel.track(event, this.properties);
  }

  getFormsControls(): FormArray {
    return <FormArray>this.addExperienceForm.controls['images'];
  }

  async addPlanetItem() {
    this.planetStore.setLoading();
    await this.imageLoader.upLoadImages();
    this.imagesService.imageUrls
      .pipe(
        takeUntil(this.destroy$),
        filter(data => !!data?.length)
      )
      .subscribe(urls => {
        this.addExperienceForm.get('creator').setValue({
          id: this.user.uid,
          image: this.user.profilePicture,
          username: this.user.username,
        });
        let actualExperience = {
          image: urls[0],
          creator: {
            id: this.user.uid,
            image: this.user.profilePicture,
            username: this.user.username,
          },
          description: this.addExperienceForm.value.experience,
        };

        const newExperience = Object.assign({
          ...this.planetItem,
          experiences: this.planetItem?.experiences?.length
            ? [...this.planetItem.experiences, actualExperience]
            : [actualExperience],
          participants: [...this.planetItem.participants, this.user.uid],
        });
        this.formService.itemGallery.next([]);

        try {
          if (this.feedItem) {
            this.planetStore.updateProfile(newExperience);

            this.feedItem.item = newExperience;

            this.activityFeed.updateProfile(this.feedItem);
          } else {
            this.planetStore.updateProfile(newExperience);
          }

          this.trackEvent(
            'Daily Challenge',
            'challenge-title',
            this.planetItem.title
          );
        } finally {
          setTimeout(() => {
            this.planetStore.patchState({ createdId: null });
            this.planetStore.getItemByByValue({
              documentId: this.planetItem?.id,
            });
            if (this.isTodaysChallenge) {
              this.showAddExperienceModalChange.next(false);
            } else {
              this.router.navigate([
                '/stage/challenges/',
                this.planetItem?.id,
              ]);
              this.showAddExperienceModalChange.next(false);
            }
          }, 0);
        }
      });
  }

  private getUser() {
    const { uid } = this.authService.isUserAuth();

    this.authService
      .getUserByUid(uid)
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user)
      )
      .subscribe(user => (this.user = user));
  }

  statusListen() {
    this.planetStore
      .selectLoading()
      .pipe(takeUntil(this.destroy$))
      .subscribe(loading => {
        loading
          ? this.loader.then(data => data.present())
          : this.loader.then(data => data.dismiss());
      });
  }
}
