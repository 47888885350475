import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class JobsServicesService {
  public urlChanged = new Subject();
  isUserHasSuspendedContent$ = new BehaviorSubject<boolean>(false);

  constructor(private location: Location) {
    location.onUrlChange(url => {
      this.urlChanged.next({ url });
    });
  }
}
