import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AdminPage } from './admin.page';
import { PendingApprovalPage } from '../pending-approval/pending-approval.page';
import { MailingComponent } from './mailing/mailing.component';
import { UserGeneratedContentComponent } from './user-generated-content/user-generated-content.component';

const routes: Routes = [
  {
    path: '',
    component: AdminPage,

    children: [
      {
        path: '',
        redirectTo: 'pending-approval',
        pathMatch: 'full',
      },
      {
        path: 'pending-approval',
        component: PendingApprovalPage,
      },
      {
        path: 'mailing-list',
        component: MailingComponent,
      },
      {
        path: 'user-generated-content',
        component: UserGeneratedContentComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminPageRoutingModule {}
