import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { SecondHandItem } from '../../bazaar';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  Subject,
  takeUntil,
} from 'rxjs';
import { FormService } from 'src/app/shared/components/form/form.service';
import {
  categoryOptions,
  conditionOptions,
  confirmDeletingModalData,
  confirmEditingModalData,
  formEditTitle,
  shippingOptions,
} from '../second-hand';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SecondHandStore } from '../second-hand-store';
import { SecondHandService } from '../second-hand.service';
import { countries } from 'src/app/auth/sign-up/countries';
import {
  createToaster,
  positionType,
} from 'src/app/shared/components/modals/toaster';
import { showLoading } from 'src/app/shared/components/modals/loading';
import { ImagesService } from 'src/app/services/images.service';
@Component({
  selector: 'app-edit-second-hand-item',
  templateUrl: './edit-second-hand-item.component.html',
  styleUrls: ['./edit-second-hand-item.component.scss'],
})
export class EditSecondHandItemComponent implements OnInit, OnDestroy {
  @Output() isClose = new EventEmitter<boolean>(false);
  @ViewChild('imageLoader') imageLoader;

  conditionOptions = conditionOptions;
  categoryOptions = categoryOptions;
  shippingOptions = shippingOptions;
  countries = countries;
  formTitle = formEditTitle;

  secondHandItem;
  newItem: SecondHandItem;
  id: string = this.activatedRouter.snapshot.params.id;

  secondHandForm: FormGroup;
  formIsReset = new BehaviorSubject(false);
  loader = showLoading();

  isOpen = true;
  showSuccessModal = false;
  successModalData;
  confirmModalData = confirmEditingModalData;
  confirmForDelete = confirmDeletingModalData;
  showConfirmModal = false;
  showForDelete = false;

  addingBtnDisabled = true;
  disabled = true;
  statusDeleting: boolean;
  statusAdding: boolean;

  imagesControls = 1;
  currentImage: string;
  loadingImage: boolean = false;
  imagesArr: string[];

  private destroy$ = new Subject<void>();

  constructor(
    private formService: FormService,
    private secondHandService: SecondHandService,
    private secondHandStore: SecondHandStore,
    private fb: FormBuilder,
    private imagesService: ImagesService,
    private activatedRouter: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.secondHandForm = this.fb.group({
      images: this.fb.array([
        {
          0: this.fb.group({
            image: [null],
          }),
        },
      ]),
      name: ['', [Validators.required, Validators.minLength(7)]],
      brand: [''],
      condition: ['', [Validators.required]],
      category: ['', [Validators.required]],
      swapOption: [null],
      description: ['', [Validators.required, , Validators.minLength(20)]],
      price: this.fb.group({
        value: [null, [Validators.required]],
        currency: ['USD'],
      }),
      country: [null, [Validators.required]],
      state: [null, [Validators.required]],
      shippingOption: [null, [Validators.required]],
    });

    this.initialDataListener();
    this.listenStatusChanges();

    this.secondHandStore.getItemByByValue({
      documentId: this.activatedRouter.snapshot.params.id,
    });
  }

  ngOnDestroy() {
    this.imagesService.reset();
    this.destroy$.next();
    this.destroy$.complete();
  }

  getFormsControls(): FormArray {
    return <FormArray>this.secondHandForm.controls['images'];
  }

  close(e: boolean) {
    e ? (this.showConfirmModal = true) : null;
  }

  discardChanges(e: boolean) {
    if (e) {
      this.isClose.next(true);
      this.showConfirmModal = false;
    }
  }

  closeModal(e: boolean) {
    if (e) {
      this.showConfirmModal = false;
      this.showForDelete = false;
    }
  }

  async updateItem() {
    this.secondHandStore.setLoading();
    await this.imageLoader.upLoadImages();
    this.imagesService.imageUrls
      .pipe(
        filter(data => !!data),
        takeUntil(this.destroy$)
      )
      .subscribe(urls => {
        if (urls.length && urls.length === this.imagesArr.length) {
          this.disabled = true;
          this.newItem.images = [...urls].filter(image => !!image);
          this.secondHandStore.updateProfile(this.newItem);
          this.secondHandStore
            .selectLoading()
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
              if (data) {
                this.disabled = true;
                this.addingBtnDisabled = true;
                this.statusAdding = true;
              } else {
                this.isClose.next(true);
                createToaster(
                  'Successfully updated!',
                  'success',
                  positionType.BOTTOM
                );
                this.disabled = false;
                this.addingBtnDisabled = false;
                this.statusAdding = false;
              }
            });
        }
      });
  }

  removeItem(e: boolean) {
    if (e) {
      this.secondHandStore.removeItem(this.id);
      this.secondHandStore
        .selectLoading()
        .pipe(takeUntil(this.destroy$))
        .subscribe(data => {
          this.statusDeleting = data;
          if (data) {
            this.disabled = true;
            this.showForDelete = false;
          } else {
            this.isClose.next(true);
            createToaster(
              'Successfully deleted!',
              'success',
              positionType.BOTTOM
            );
            setTimeout(() => {
              this.router.navigate(['bazaar/second-hand']);
            }, 0);
          }
        });
    }
  }

  initialDataListener() {
    this.secondHandStore
      .selectItem()
      .pipe(
        filter(item => !!item),
        takeUntil(this.destroy$)
      )
      .subscribe(data => {
        this.secondHandForm.patchValue({
          name: data.name,
          images: data.images,
          brand: data.brand,
          description: data.description,
          category: data.category,
          price: data.price,
          condition: data.condition,
          shippingOption: data.shippingOption,
          country: data.country,
          swapOption: data.swapOption,
          state: data.state,
        });
        this.secondHandItem = data;
        this.imagesService.imageUrls.next(data.images);
        this.imagesService.compressedImages.next(data.images);
      });

    this.imagesService.imagesLoading.subscribe(loading => {
      this.loadingImage = loading;
      this.disabled =
        loading ||
        this.secondHandForm.status === 'INVALID' ||
        !this.imagesArr?.length;
    });
    this.imagesService.compressedImages
      .pipe(takeUntil(this.destroy$))
      .subscribe(images => {
        this.imagesArr = images;
        this.addingBtnDisabled = !!(images.length < this.imagesControls);
      });

    combineLatest([
      this.secondHandForm.valueChanges,
      this.imagesService.compressedImages,
      this.imagesService.imagesLoading,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([value, images, loading]) => {
        this.imagesArr = images;
        this.loadingImage = loading;
        this.disabled =
          this.secondHandForm.status === 'INVALID' ||
          this.loadingImage ||
          !this.imagesArr.length;
        this.newItem = {
          ...value,
          id: this.id,
          creator: this.secondHandItem.creator,
        };
      });
  }

  private listenStatusChanges() {
    this.secondHandStore
      .selectLoading()
      .pipe(takeUntil(this.destroy$))
      .subscribe(loading => {
        loading
          ? this.loader.then(data => data.present())
          : this.loader.then(data => data.dismiss());
      });
  }
}
