import { Injectable } from '@angular/core';
import {
  getAuth,
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';

import { getFunctions, httpsCallable } from 'firebase/functions';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { CrudHelper } from './helpers/crudHelper';
import { map, catchError } from 'rxjs/operators';
import { MapService } from '../services/map.service';
import { User } from '../shared/models/user.model';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ProfileStore } from '../profile/store/profile-store';
import { countries } from '../auth/sign-up/countries';
import { environment } from '../../environments/environment';
import mixpanel from 'mixpanel-browser';
import { Capacitor } from '@capacitor/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  properties;
  isUserPremium;
  private apiUrl = 'https://api.mixpanel.com';
  constructor(
    private fireStore: AngularFirestore,
    private profileStore: ProfileStore,
    private crudHelper: CrudHelper,
    private mapService: MapService,
    private router: Router,
    private http: HttpClient
  ) {}

  async getCustomClaimRole() {
    await getAuth().currentUser.getIdToken(true);
    const decodedToken = await getAuth().currentUser.getIdTokenResult();
    this.isUserPremium = decodedToken.claims.stripeRole === 'premium';
    return decodedToken.claims.stripeRole === 'premium';
  }

  trackEvent(event, userCredential, user, country) {
    this.properties = {
      id: userCredential.user.uid,
      country: country,
      region: country.region,
      city: user.address.city,
      email: user.email,
      'marketing-accept': user.isMailingEnabled,
      'premium-active': false,
    };

    mixpanel.track(event, this.properties);
  }

  getCurrentUser() {
    return getAuth().currentUser;
  }

  isUserAuth() {
    return getAuth().currentUser;
  }

  inviteMerfriend = async (recipientEmail, message, sender) => {
    return new Promise(async (resolve, reject) => {
      const functions = getFunctions();
      const sendMail = httpsCallable(functions, 'sendMail');
      return sendMail({ recipientEmail, message, sender }).then(data => {
        data ? resolve(data) : reject('Firebase error');
      });
    });
  };

  signUp = async user => {
    const country = countries.find(
      country => country.name === user.address.country
    );
    const location = this.mapService.createCoordinates(
      user.address.googleMapLink
    );

    return new Promise(async (resolve, reject) => {
      const auth = getAuth();
      const functions = getFunctions();

      const isUsernameTaken = httpsCallable(functions, 'isUsernameTaken');

      isUsernameTaken({ username: user.username })
        .then(({ data }) => {
          data
            ? reject('auth/username-already-exist')
            : createUserWithEmailAndPassword(auth, user.email, user.password)
                .then(async userCredential => {
                  resolve(
                    this.fireStore.doc(`users/${userCredential.user.uid}`).set({
                      uid: userCredential.user.uid,
                      email: user.email,
                      username: user.username,
                      country,
                      city: null,
                      postalCode: null,
                      location,
                      profilePicture: null,
                      gender: 'Other',
                      mermaidExpLvl: null,
                      mersonaName: null,
                      galleryVisibility: true,
                      isMailingEnabled: user.isMailingEnabled,
                      platform: Capacitor.getPlatform(),
                    })
                  );
                  mixpanel.init(environment.mixpanelToken, { debug: true });
                  mixpanel.identify(userCredential.user.uid);
                  this.trackEvent('New User', userCredential, user, country);

                  mixpanel.people.set({
                    $region: country.region,
                    $city: user.address.city,
                    $email: user.email,
                    $country: country,
                    $createdAt: new Date(),
                    'marketing-accept': user.isMailingEnabled,
                    'premium-active': false,
                  });
                })
                .catch(error => reject(error.code));
        })
        .catch(error => reject(error.code));
    });
  };

  signIn = async ({ email, password, rememberMe }) => {
    return new Promise(async (resolve, reject) => {
      const auth = getAuth();
      const sessionType = rememberMe
        ? browserLocalPersistence
        : browserSessionPersistence;

      setPersistence(auth, sessionType).then(() => {
        signInWithEmailAndPassword(auth, email, password)
          .then(userCredential => {
            resolve(userCredential);
          })
          .catch(error => reject(error.code));
      });
    });
  };

  signOut = async () => {
    signOut(getAuth())
      .then(() => {
        this.router.navigate(['/'], { replaceUrl: true });
        this.profileStore.logOut();

        console.log('successfully sign out');
      })
      .catch(error => Promise.reject(error.code));
  };

  deleteProfile = async () => {
    return new Promise(async (resolve, reject) => {
      const functions = getFunctions();
      const isDeleted = httpsCallable(functions, 'deleteUser');
      return isDeleted().then(data => {
        data ? resolve(data) : reject('Firebase error');
      });
    });
  };

  sendResetEmail({ email }) {
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        console.log('Password reset email sent');
      })
      .catch(error => {
        console.log(error.message);
      });
  }

  getUsers = () => {
    return this.crudHelper.getHelper({ collectionName: 'users' });
  };

  getUserById = (id: string): Observable<User> => {
    return this.fireStore
      .collection('users', ref => ref.where('id', '==', id))
      .snapshotChanges()
      .pipe(
        map(user => {
          const userData = user[0].payload.doc.data() as User;
          return {
            id: user[0].payload.doc.id,
            ...userData,
          };
        })
      );
  };

  getUserByUid = uid => {
    return this.fireStore
      .collection('users', ref => ref.where('uid', '==', uid))
      .snapshotChanges()
      .pipe(
        map(user => {
          return Object.assign(
            {
              id: user[0].payload.doc.id,
            },
            user[0].payload.doc.data()
          );
        }),
        catchError(async e => {
          console.log(`User with uid ${uid} not found or not exist`);
          return { id: '' };
        })
      );
  };

  getUserBySearch = async (start, end) => {
    return await this.crudHelper.searchHelper({
      collectionName: 'users',
      searchField: 'username',
      limit: 50,
      start,
      end,
    });
  };
}
