import { Injectable } from '@angular/core';
import { CommonService } from '../shared/component-store/common-service';
import { CrudHelper } from '../firebase/helpers/crudHelper';
import { FormBuilder } from '@angular/forms';
import { AuthService } from '../firebase/auth.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable()
export class PaymentsService extends CommonService {
  constructor(
    private firebaseHelperService: CrudHelper,
    private fireStore: AngularFirestore
  ) {
    super(firebaseHelperService);
  }

  getItems({ collectionName }) {
    const queryFn = ref => {
      let query = ref;

      return query;
    };

    return this.firebaseHelperService.getCollectionItems({
      collectionName,
      queryFn,
    });
  }

  listenBalanceInfoDetails(id) {
    return this.fireStore.collection('info').doc(id).valueChanges();
  }
}
