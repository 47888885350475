import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pending',
})
export class PendingPipe implements PipeTransform {
  transform(value: unknown, user: string) {
    if (Array.isArray(value) && value) {
      const newValue = value.filter(item => {
        return (
          (item.status === 'pending' || item.status === 'pending-repeat') &&
          item.creator.id === user
        );
      });
      return newValue;
    } else return value;
  }
}
