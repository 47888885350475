import { Injectable } from '@angular/core';
import { CommonComponentStore } from '../shared/component-store/common-component-store';
import { PaymentsService } from './payments.service';
import { InfoItem } from './payments';
@Injectable()
export class PaymentsStore extends CommonComponentStore<InfoItem> {
  constructor(paymentsService: PaymentsService) {
    super(paymentsService, {
      collectionName: 'info',
      isOpenTreasureButtonDisabled: false,
      balanceInfo: null,
      item: null,
    });
  }

  logOut() {
    this.patchState(null);
    this.patchState({ item: null });
  }

  selectTreasureButtonStatus() {
    // TODO update
    // @ts-ignore
    return this.select(state => state.isOpenTreasureButtonDisabled);
  }

  selectBalanceInfo() {
    // TODO update
    // @ts-ignore
    return this.select(state => state.balanceInfo);
  }

  updateTreasureButtonStatus(value) {
    // TODO update
    // @ts-ignore
    this.patchState({ isOpenTreasureButtonDisabled: value });
  }

  updateBalanceInfo(value) {
    // TODO update
    // @ts-ignore
    this.patchState({ balanceInfo: value });
  }
}
