import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PodDetailsComponent } from './pod-details/pod-details.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: ':id',
        component: PodDetailsComponent,
      },
      {
        path: '',
        redirectTo: 'pod/:id',
        pathMatch: 'full',
      },
    ]),
  ],
  exports: [RouterModule],
})
export class PodsRoutingModule {}
