import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Location } from '@angular/common';
import { PushNotificationsService } from './services/push-notifications.service';
import { environment } from 'src/environments/environment';
import { AdmobService } from './shared/adv/admob.service';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  showTravelingModal = new BehaviorSubject<boolean>(false);
  travelingData = new BehaviorSubject<{
    travelingRandom: number;
    travelingTitle: string | null;
    travelingLink: string;
    travelingBg: string;
  }>({
    travelingRandom: null,
    travelingTitle: '',
    travelingLink: '',
    travelingBg: '',
  });
  constructor(
    private location: Location,
    private notificationsService: PushNotificationsService,
    private admobService: AdmobService
  ) {}
  appBackground = new BehaviorSubject(
    environment.app === 'danceApp'
      ? "url('./assets/bg/home-regular.webp') no-repeat center center / cover"
      : "url('./assets/bg/home.webp') no-repeat center center / cover"
  );
  currentBg =
    environment.app === 'danceApp'
      ? "url('./assets/bg/home-regular.webp') no-repeat center center / cover"
      : "url('./assets/bg/home.webp') no-repeat center center / cover";
  homeBg =
    environment.app === 'danceApp'
      ? "url('./assets/bg/home-regular.webp') no-repeat center center / cover"
      : "url('./assets/bg/home.webp') no-repeat center center / cover";
  bazarBg =
    environment.app === 'danceApp'
      ? "url('./assets/bg/bg-dus/bazaar.webp') no-repeat center center / cover"
      : "url('./assets/bg/home.webp') no-repeat center center / cover";
  eventsBg =
    environment.app === 'danceApp'
      ? "url('./assets/bg/bg-dus/Events.webp') no-repeat center center / cover"
      : "url('./assets/bg/home.webp') no-repeat center center / cover";
  mediaBg =
    environment.app === 'danceApp'
      ? "url('./assets/bg/bg-dus/Media-FA.webp') no-repeat center center / cover"
      : "url('./assets/bg/home.webp') no-repeat center center / cover";
  merfolkBg =
    environment.app === 'danceApp'
      ? "url('./assets/bg/bg-dus/Dancers.webp') no-repeat center center / cover"
      : "url('./assets/bg/home.webp') no-repeat center center / cover";
  planetBg =
    environment.app === 'danceApp'
      ? "url('./assets/bg/bg-dus/Stage.webp') no-repeat center center / cover"
      : "url('./assets/bg/home.webp') no-repeat center center / cover";
  profileBg =
    environment.app === 'danceApp'
      ? "url('./assets/bg/bg-dus/Profile.webp') no-repeat center center / cover"
      : "url('./assets/bg/home.webp') no-repeat center center / cover";
  premiumBg =
    environment.app === 'danceApp'
      ? "url('./assets/bg/bg-dus/premium-main-page.webp') no-repeat center center / cover"
      : "url('./assets/bg/home.webp') no-repeat center center / cover";

  detectUrl() {
    this.location.onUrlChange(url => {
      if (url.includes('/bazaar')) {
        if (this.currentBg !== this.bazarBg) {
          this.appBackground.next(this.bazarBg);
          this.currentBg = this.bazarBg;
        }
      } else if (url.includes('/events')) {
        if (this.currentBg !== this.eventsBg) {
          this.appBackground.next(this.eventsBg);
          this.currentBg = this.eventsBg;
        }
      } else if (url.includes('/media')) {
        if (this.currentBg !== this.mediaBg) {
          this.appBackground.next(this.mediaBg);
          this.currentBg = this.mediaBg;
        }
      } else if (url.includes('/dancers') || url.includes('/chat')) {
        if (this.currentBg !== this.merfolkBg) {
          this.appBackground.next(this.merfolkBg);
          this.currentBg = this.merfolkBg;
        }
      } else if (url.includes('/stage')) {
        if (this.currentBg !== this.planetBg) {
          this.appBackground.next(this.planetBg);
          this.currentBg = this.planetBg;
        }
      } else if (url.includes('/profile')) {
        if (this.currentBg !== this.profileBg) {
          this.appBackground.next(this.profileBg);
          this.currentBg = this.profileBg;
        }
      } else if (url.includes('/payments')) {
        if (this.currentBg !== this.premiumBg) {
          this.appBackground.next(this.premiumBg);
          this.currentBg = this.premiumBg;
        }
      } else {
        if (this.currentBg !== this.homeBg) {
          this.appBackground.next(this.homeBg);
          this.currentBg = this.homeBg;
        }
      }
    });
  }
}
