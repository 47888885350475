import { environment } from 'src/environments/environment';
import {
  AdOptions,
  BannerAdOptions,
  BannerAdPosition,
  BannerAdSize,
  RewardAdOptions,
} from '@capacitor-community/admob';

const isDevMode = !environment.production;

export const bannerBottomOptions: BannerAdOptions = {
  adId: 'ca-app-pub-2609549176457465/9907778325',
  adSize: BannerAdSize.ADAPTIVE_BANNER,
  position: BannerAdPosition.BOTTOM_CENTER,
  isTesting: isDevMode,
};

export const treasureOpeningOptions: RewardAdOptions = {
  adId: 'ca-app-pub-2609549176457465/3875793809',
  isTesting: isDevMode,
};

export const shellConvertingOptions: RewardAdOptions = {
  adId: 'ca-app-pub-2609549176457465/2184036596',
  isTesting: isDevMode,
};

export const leavingAppInterstitialOptions: AdOptions = {
  adId: 'ca-app-pub-2609549176457465/8594696658',
  isTesting: isDevMode,
};

export const insideIosAppTravelingOptions: BannerAdOptions = {
  adId: 'ca-app-pub-2609549176457465/4588225778',
  adSize: BannerAdSize.MEDIUM_RECTANGLE,
  position: BannerAdPosition.CENTER,
  isTesting: isDevMode,
  margin: 150,
};

export const insideAppTravelingOptions: BannerAdOptions = {
  adId: 'ca-app-pub-2609549176457465/4588225778',
  adSize: BannerAdSize.MEDIUM_RECTANGLE,
  position: BannerAdPosition.CENTER,
  isTesting: isDevMode,
};
