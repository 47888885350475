import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-jobs-search',
  templateUrl: './jobs-search.component.html',
  styleUrls: ['./jobs-search.component.scss'],
})
export class JobsSearchComponent {
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSearchChange = new EventEmitter<string>();
  @Input() color;

  toolbarData = {
    arrowRouter: '/bazaar',
    title: 'Jobs',
  };

  constructor() {}

  searchItem(event) {
    this.onSearchChange.emit(event.target.value);
  }
}
