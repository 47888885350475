import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { CrudHelper } from '../firebase/helpers/crudHelper';
import { CommonService } from '../shared/component-store/common-service';
import { FormService } from '../shared/components/form/form.service';
import { BehaviorSubject } from 'rxjs';

export const confirmModalData = {
  title: 'Update password',
  icon: 'key',
  okBtn: {
    title: 'YES, DISCARD',
  },
  cancelBtn: { title: 'NO, GO BACK' },
};
export const confirmForDelete = {
  title: 'Delete Profile',
  icon: 'person',
  okBtn: {
    title: 'YES, DELETE',
  },
  cancelBtn: { title: 'No, DISCARD' },
};

@Injectable({ providedIn: 'root' })
export class ProfileService extends CommonService {
  constructor(
    private fireStore: AngularFirestore,
    private firebaseHelperService: CrudHelper,
    private formService: FormService
  ) {
    super(firebaseHelperService);
  }

  currentFollowingPage = new BehaviorSubject(0);

  getProfileGallery(userId: string) {
    return this.fireStore
      .collection('gallery', ref => ref.where('userId', '==', userId))
      .valueChanges({ idField: 'id' });
  }

  updateGallery(id: string, data) {
    return this.firebaseHelperService.updateItem({
      id,
      data,
      collectionName: 'gallery',
    });
  }

  setFilterData(userId, route) {
    this.formService.formInitialValues.next({ myContent: false });
    switch (route) {
      case 'offers':
        this.formService.jobsMenuData$.next({
          myContent: true,
          creator: userId,
        });
        break;
      case 'searchers':
        this.formService.servicesMenuData$.next({
          myContent: true,
          creator: userId,
          date: null,
          startDate: null,
          endDate: null,
        });
        break;
      case 'second-hand':
        this.formService.secondHandMenuData$.next({
          myContent: true,
          creator: userId,
          swapOption: null,
        });
        break;
      case 'pods':
        this.formService.podMenuData$.next({
          myContent: true,
          country: null,
          approval: null,
          creator: userId,
        });
        break;
      case 'pools':
        this.formService.poolMenuData$.next({
          country: null,
          dogsAllowed: null,
          finsAllowed: null,
          freeEntry: null,
          myContent: true,
          naturePools: null,
          creator: userId,
        });
        break;
      case 'events':
        this.formService.eventMenuData$.next({
          myContent: true,
          creator: userId,
        });
        break;

      default:
        break;
    }
  }

  resetFilter() {
    this.formService.secondHandMenuData$.next(false);
    this.formService.eventMenuData$.next(false);
    this.formService.servicesMenuData$.next(false);
    this.formService.jobsMenuData$.next(false);
    this.formService.podMenuData$.next(false);
    this.formService.poolMenuData$.next(false);
  }
}
