import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PendingApprovalPageRoutingModule } from './pending-approval-routing.module';
import { PendingApprovalPage } from './pending-approval.page';
import { SharedModule } from '../shared/shared.module';
import { PendingApprovalSearchComponent } from './pending-approval-search/pending-approval-search.component';
import { PendingApprovalService } from './pending-approval.service';
import { PodService } from '../merfolk/pods/pods.service';
import { PodStore } from '../merfolk/pods/pod-store';
import { PendingApprovalItemComponent } from './pending-approval-item/pending-approval-item.component';
import { PipesModule } from '../pipes/pipes.module';
import { SecondHandService } from '../bazaar/second-hand/second-hand.service';
import { SecondHandStore } from '../bazaar/second-hand/second-hand-store';
import { ControlsBtnComponent } from './controls-btn/controls-btn.component';
import { JobsStore } from '../bazaar/jobs-services/jobs/jobs-store';
import { JobsService } from '../bazaar/jobs-services/jobs/jobs.service';
import { ServicesStore } from '../bazaar/jobs-services/services/services.store';
import { ServicesService } from '../bazaar/jobs-services/services/services.service';
import { EventsStore } from '../events/events-store';
import { EventsService } from '../events/events.service';
import { PoolsStore } from '../merfolk/pools/pools.store';
import { PoolsService } from '../merfolk/pools/pools.service';
import { PlanetStore } from '../planet/planet-store';
import { PlanetService } from '../planet/planet.service';
import { PlanetModule } from '../planet/planet.module';
import { RejectMsgModalComponent } from './reject-msg-modal/reject-msg-modal.component';
import { BazaarModule } from '../bazaar/bazaar.module';
import { JobsServicesModule } from '../bazaar/jobs-services/jobs-services.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    PendingApprovalPageRoutingModule,
    SharedModule,
    PipesModule,
    PlanetModule,
    BazaarModule,
    JobsServicesModule,
  ],
  declarations: [
    PendingApprovalPage,
    PendingApprovalSearchComponent,
    PendingApprovalItemComponent,
    ControlsBtnComponent,
    RejectMsgModalComponent,
  ],
  providers: [
    PendingApprovalService,
    PodService,
    PodStore,
    PoolsStore,
    PoolsService,
    PlanetStore,
    PlanetService,
    EventsStore,
    EventsService,
    SecondHandService,
    SecondHandStore,
    JobsStore,
    JobsService,
    ServicesStore,
    ServicesService,
  ],
})
export class PendingApprovalPageModule {}
