import { Component } from '@angular/core';

@Component({
  selector: 'app-interstitial',
  templateUrl: './interstitial.component.html',
  styleUrls: ['./interstitial.component.scss'],
})
export class InterstitialComponent {
  constructor() {}

  adWidth = window.matchMedia('(min-width: 768px)') ? '336px' : '300px';
}
