import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'suspended',
})
export class SuspendedPipe implements PipeTransform {
  transform(value: unknown): unknown {
    if (Array.isArray(value) && value) {
      const newValue = value.filter(item => !item.suspended);
      return newValue;
    } else return value;
  }
}
