import { Injectable } from '@angular/core';
import { CommonComponentStore } from '../shared/component-store/common-component-store';
import { EventItem } from './events';
import { EventsService } from './events.service';
import { catchError, switchMap } from 'rxjs/operators';
import { tapResponse } from '@ngrx/component-store';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, combineLatest } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EventsStore extends CommonComponentStore<EventItem> {
  constructor(private eventsService: EventsService) {
    super(eventsService, {
      createdId: null,
      item: null,
      items: null,
      loading: false,
      showFormModal: false,
      showPendingModal: false,
      collectionName: 'events',
    });
  }

  selectShowFormModal() {
    // TODO update
    // @ts-ignore
    return this.select(state => state.showFormModal);
  }

  updateShowFormModal(value) {
    // TODO update
    // @ts-ignore
    this.patchState({ showFormModal: value });
  }

  selectShowPendingModal() {
    // TODO update
    // @ts-ignore
    return this.select(state => state.showPendingModal);
  }

  updateShowPendingModal(value) {
    // TODO update
    // @ts-ignore
    this.patchState({ showPendingModal: value });
  }

  getPreviewEvents({ limit, podId }) {
    return this.effect((data: Observable<any>) => {
      return combineLatest([
        data,
        this.select(state => state.collectionName),
      ]).pipe(
        switchMap(([data, collectionName]) => {
          this.patchState({ loading: true });

          return this.eventsService
            .getPreviewEvents(data.limit, data.podId)
            .pipe(
              tapResponse(
                (items: any) => {
                  this.patchState({ items: items, loading: false });
                },
                (error: HttpErrorResponse) => {
                  this.patchState({ loading: false });
                  console.log(error);
                }
              ),
              catchError(async err => console.log(err))
            );
        })
      );
    })({ limit, podId });
  }
}
