import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  Subject,
  takeUntil,
} from 'rxjs';
import { AuthService } from 'src/app/firebase/auth.service';
import { showLoading } from 'src/app/shared/components/modals/loading';
import { map, switchMap } from 'rxjs/operators';
import { ServicesStore } from './services.store';
import { FormService } from 'src/app/shared/components/form/form.service';
import { ServicesService } from './services.service';
import {
  IServicesFilter,
  serviceDiscordModalData,
  servicePremiumModalData,
} from './services';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { PaymentsStore } from 'src/app/payments/payments-store';
import { Platform } from '@ionic/angular';
import { DateService } from 'src/app/services/date.service';
import { UserService } from 'src/app/auth/user.service';
import { CrudHelper } from 'src/app/firebase/helpers/crudHelper';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
})
export class ServicesComponent implements OnInit, OnDestroy {
  searchString: BehaviorSubject<string> = new BehaviorSubject<string>('');

  status;
  loader = showLoading();

  myContent: boolean = false;
  userId: string;
  creator;
  items;
  filterOptions;
  startDate;
  endDate;
  isOpenFormModal = false;
  filter;

  currentTime: Date = new Date();

  isPremium: boolean;
  isOpenDiscordModal = false;
  premiumModalData = servicePremiumModalData;
  serviceDiscordModalData = serviceDiscordModalData;
  isUserPremiumModalOpen = false;
  currentUser;
  infoSnapshot;
  userInfo;
  color = 'dark';
  today = new Date().getTime() / 1000;

  isMobile = this.platform.platforms().includes('capacitor');
  isIosApp = this.platform.platforms().includes('ios') && this.isMobile;

  isAdmin = false;

  private destroy$ = new Subject<void>();

  constructor(
    private servicesStore: ServicesStore,
    private servicesService: ServicesService,
    private dateService: DateService,
    private fs: FormService,
    private fireStore: AngularFirestore,
    private authService: AuthService,
    private paymentsStore: PaymentsStore,
    private platform: Platform,
    private userService: UserService,
    private crudHelper: CrudHelper
  ) {}

  async ngOnInit() {
    this.getUser();
    this.listenInfoDetails();
    this.listenItemsChanges();
    this.listenStatusChanges();
    this.listenFilter();

    this.servicesStore.selectShowFormModal().subscribe(isOpen => {
      this.isOpenFormModal = isOpen;
    });
  }

  ionViewWillEnter() {
    if (this.userId !== this.authService.getCurrentUser().uid) {
      this.ngOnInit();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.infoSnapshot) {
      this.infoSnapshot.unsubscribe();
    }
    this.fs.formInitialValues.next({ myContent: null });
    this.fs.servicesMenuData$.next(false);
  }

  openModal() {
    if (!this.currentUser.discordLink) {
      this.isOpenDiscordModal = true;
    } else {
      this.servicesStore.updateShowFormModal(true);
    }
  }

  closeModal(e) {
    e ? this.servicesStore.updateShowFormModal(false) : null;
  }

  onSearchChange(searchString) {
    this.searchString.next(searchString);
  }

  private listenFilter() {
    this.fs.servicesMenuData$.subscribe((data: IServicesFilter) => {
      if (data) {
        this.startDate = new Date(data.startDate).getTime() / 1000;
      }

      data.endDate
        ? (this.endDate = new Date(data.endDate).getTime() / 1000)
        : null;

      const initialData = this.servicesService.checkFilter(data);
      if (initialData) {
        this.color = 'success';
      } else {
        this.color = 'dark';
      }
      this.filter = data;
      this.myContent = (data as IServicesFilter).myContent;
      this.servicesStore.getItems(data);
    });
  }

  private listenStatusChanges() {
    this.servicesStore
      .selectLoading()
      .pipe(takeUntil(this.destroy$))
      .subscribe(loading => {
        loading
          ? this.loader.then(data => data.present())
          : this.loader.then(data => data.dismiss());
      });
  }

  private listenInfoDetails() {
    const { uid } = this.authService.isUserAuth();
    this.infoSnapshot = this.fireStore
      .collection('info')
      .doc(uid)
      .get()
      .subscribe(data => {
        this.userInfo = data.data() as any;
      });
  }

  private listenItemsChanges() {
    combineLatest([
      this.servicesStore.selectItems(),
      this.searchString,
      this.fs.selectedEndDate,
      this.fs.selectedStartDate,
    ])
      .pipe(
        takeUntil(this.destroy$),
        map(([items, searchString]) => {
          return (
            items &&
            items.filter(item =>
              item.title.toLowerCase().includes(searchString.toLowerCase())
            )
          );
        }),
        switchMap((items: any[]) => {
          if (!items?.length) return (this.items = []);
          const users = this.crudHelper.getCollectionItems({
            collectionName: 'users',
            queryFn: null,
          });
          return users.pipe(
            filter(users => !!users),
            map(users => {
              return items.map(item => {
                const user = users.find(user => user.id === item.creator.id);
                if (user) return Object.assign({ ...item, creator: user });
                return item;
              });
            })
          );
        })
      )
      .subscribe(items => {
        this.items = this.dateService.getScheduledItems(items, this.myContent).filter(item => !this.currentUser?.blockList?.includes(item.creator.id));
      });
  }

  private getUser() {
    this.userService.currentUser$
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user.uid)
      )
      .subscribe(user => {
        this.currentUser = user;
        this.isPremium = this.userService.isPremium;
        this.isAdmin = this.userService.isAdmin;
        this.userId = user.uid;
        this.userInfo = this.userService.balanceInfo;
      });
  }
}
