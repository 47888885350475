import { Injectable } from '@angular/core';
import { CommunityService } from './community.service';
import { CommonComponentStore } from './../../shared/component-store/common-component-store';
import { IUser } from 'src/app/auth/auth.models';

@Injectable({ providedIn: 'root' })
export class CommunityStore extends CommonComponentStore<IUser> {
  constructor(communityService: CommunityService) {
    super(communityService, {
      createdId: null,
      item: null,
      items: null,
      loading: false,
      showFormModal: false,
      showPendingModal: false,
      collectionName: 'users',
    });
  }

  selectShowFormModal() {
    // TODO update
    // @ts-ignore
    return this.select(state => state.showFormModal);
  }

  updateShowFormModal(value) {
    // TODO update
    // @ts-ignore
    this.patchState({ showFormModal: value });
  }

  selectShowPendingModal() {
    // TODO update
    // @ts-ignore
    return this.select(state => state.showPendingModal);
  }

  updateShowPendingModal(value) {
    // TODO update
    // @ts-ignore
    this.patchState({ showPendingModal: value });
  }
}
