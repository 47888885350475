export const travelingQuotes = [
  {
    quote:
      '“I have a new respect for the mermaids… because it’s very tough what they do.”',
    author: 'Gaz Coombes',
  },

  {
    quote:
      '“The ocean makes me feel really small and it makes me put my whole life into perspective.”',
    author: 'Beyoncé Knowles',
  },
  {
    quote:
      '“She was born to be free, let her run wild in her own way and you will never lose her.”',
    author: 'Nikki Rowe',
  },
  {
    quote: '“She loved mysteries so much that she became one.”',
    author: 'Unknown',
  },
  {
    quote:
      '“The sea does not reward those who are too anxious, too greedy, or too impatient. One should lie empty, open, choiceless as a beach–waiting for a gift from the sea.”',
    author: 'Anne Morrow Lindbergh',
  },
  {
    quote: '“The ocean is a mighty harmonist”',
    author: 'William Wordsworth',
  },
  {
    quote:
      '“The ocean is a place of skin, rich outer membranes hiding thick juicy insides, laden with the soup of being”',
    author: 'Vernor Vinge',
  },
  {
    quote: '“She is a mermaid on dry land and the world is her ocean”',
    author: 'J. Iron Word',
  },
  {
    quote:
      '“The sea beckoned to me, and all reality was lost-swept away in the entrancing song of the tide.”',
    author: 'Meredith T. Taylor',
  },
  {
    quote:
      '“If you are curious you might never come home, like all the men who now live with mermaids at the bottom of the sea.”',
    author: 'Jeanette Winterson',
  },
  {
    quote:
      '“Mermaid tears were treasure, meant to be guarded close to one’s heart. They brought wishes of true love and kept you safe.”',
    author: 'Kerry Lonsdale',
  },
  {
    quote: '“Where there’s water, we find mermaids.”',
    author: 'Mira Grant',
  },
  {
    quote:
      'The difference between a Miracle and a Fact is exactly the difference between a mermaid and a seal.”',
    author: 'Mark Twain',
  },
  {
    quote:
      '“The sea, once it casts its spell, holds one in its net of wonder forever.” ',
    author: 'Jacques Yves Cousteau',
  },
  {
    quote:
      '“You are not a drop in the ocean. You are the entire ocean in a drop.”',
    author: 'Rumi',
  },
  {
    quote:
      '“She is a mermaid, but approach her with caution. Her mind swims at a depth most would drown in.”',
    author: 'J. Iron Word',
  },
  {
    quote:
      '“The ocean is everything I want to be. Beautiful, mysterious, wild, and free.”',
    author: 'Unknown',
  },
  {
    quote:
      '“I must be a mermaid, Rango. I have no fear of depths and a great fear of shallow living.”',
    author: 'Anaïs Nin',
  },
  {
    quote: '“The cure for anything is salt water: sweat, tears or the sea.”',
    author: 'Isak Dinesen',
  },
  {
    quote:
      '“The ocean stirs the heart, inspires the imagination and brings eternal joy to the soul.”',
    author: 'Robert Wyland',
  },
  {
    quote: '“Mermaids don’t lose sleep over the opinion of shrimp.”',
    author: 'Unknown',
  },
  {
    quote:
      '“You can either see yourself as a wave in the ocean or you can see yourself as the ocean.”',
    author: 'Oprah Winfrey1',
  },
  {
    quote: '“Wild and free, just like the sea.”',
    author: 'Unknown',
  },
  {
    quote:
      '“The sea is emotion incarnate. It loves, hates, and weeps. It defies all attempts to capture it with words and rejects all shackles. No matter what you say about it, there is always that which you can’t.”',
    author: 'Christopher Paolini',
  },
  {
    quote:
      '“The ocean is more ancient than the mountains and freighted with the memories and the dreams of Time.”',
    author: 'H.P. Lovecraft',
  },
  {
    quote: '“The voice of the sea speaks to the soul.”',
    author: 'Kate Chopin',
  },
  {
    quote:
      '“The ocean has a life of its own. Its tides, whirlpools, currents and eddies are a testament to its conflicting emotions.”',
    author: 'Anthony T. Hincks',
  },
  {
    quote: '“The sea is as near as we come to another world.”',
    author: 'Anne Stevenson',
  },
  {
    quote:
      '“You must not lose faith in humanity. Humanity is like an ocean; if a few drops of the ocean are dirty, the ocean does not become dirty.”',
    author: 'Mahatma Gandhi',
  },
  {
    quote:
      '“The sea is everything. It covers seven tenths of the terrestrial globe. Its breath is pure and healthy. It is an immense desert, where man is never lonely, for he feels life stirring on all sides.”',
    author: 'Jules Verne',
  },
  {
    quote: '“The sea lives in every one of us.”',
    author: 'Robert Wyland',
  },
  {
    quote: '“I am not afraid of storms for I am learning how to sail my ship.”',
    author: 'Louisa May Alcott',
  },
  {
    quote: '“We dream in colors borrowed from the sea.”',
    author: 'Unknown',
  },
  {
    quote:
      '“The ocean is a central image. It is the symbolism of a great journey.”',
    author: 'Enya',
  },
  {
    quote:
      '“I wonder if the ocean smells different on the other side of the world.”',
    author: 'J.A. Redmerski',
  },
  {
    quote: '“The waves of the sea help me get back to me.”',
    author: 'Jill Davis',
  },
  {
    quote:
      '“There is nothing more enticing, disenchanting, and enslaving than the life at sea.”',
    author: 'Joseph Conrad',
  },
  {
    quote: '“I want to be wild, beautiful and free just like the sea.”',
    author: 'Unknown',
  },
  {
    quote:
      '“The ocean was magical to her, its depths and mysteries were boundless, its call irresistible.” ',
    author: 'Jeff Mariotte',
  },
  {
    quote:
      '“In one drop of water are found all the secrets of all the oceans; in one aspect of You are found all the aspects of existence.”',
    author: 'Kahlil Gibran',
  },
  {
    quote:
      '“You never really know what’s coming. A small wave, or maybe a big one. All you can really do is hope that when it comes, you can surf over it, instead of drown in its monstrosity.”',
    author: 'Alysha Speer',
  },
  {
    quote:
      '“To me, the sea is like a person–like a child that I’ve known a long time. It sounds crazy, I know, but when I swim in the sea I talk to it. I never feel alone when I’m out there.”',
    author: 'Gertrude Ederle',
  },
  {
    quote: '“The ocean was my first love story.”',
    author: 'Michelle K.',
  },
  {
    quote: '“If there is magic on this planet, it is contained in water.”',
    author: 'Loren Eiseley',
  },
  {
    quote: '“Live in the sunshine, swim in the sea, drink in the wild air.”',
    author: 'Ralph Waldo Emerson',
  },
  {
    quote:
      '“We know only too well that what we are doing is nothing more than a drop in the ocean. But if the drop were not there, the ocean would be missing something.”',
    author: 'Mother Teresa',
  },
  {
    quote: '“I need vitamin SEA”',
    author: 'Unknown',
  },
  {
    quote:
      '“You can’t cross the sea merely by standing and staring at the water” ',
    author: 'Rabindranath Tagore',
  },
  {
    quote:
      '“The ocean has always been a salve to my soul…the best thing for a cut or abrasion was to go swimming in salt water. Later down the road of life, I made the discovery that salt water was also good for the mental abrasions one inevitably acquires on land.”',
    author: 'Jimmy Buffett',
  },
  {
    quote:
      '“I’m always happy when I’m surrounded by water, I think I’m a Mermaid or I was a mermaid.”',
    author: 'Beyoncé Knowles-Carter',
  },
  {
    quote: '“I believe in mermaids because they believe in me.”',
    author: 'Unknown',
  },
  {
    quote: '“The ocean is a poem without words.”',
    author: 'Horace',
  },
  {
    quote: '“The sea is a desert of waves, A wilderness of water.”',
    author: 'Langston Hughes',
  },
  {
    quote: '“The sea is not a bargain basement.”',
    author: 'Jacques Yves Cousteau',
  },
  {
    quote:
      '“The sea is only the embodiment of a supernatural and wonderful existence.”',
    author: 'Jules Verne',
  },
  {
    quote: '“The sea is as near as we come to another world.”',
    author: 'Anne Stevenson',
  },
  {
    quote:
      '“The sea is everything. It covers seven tenths of the terrestrial globe. Its breath is pure and healthy. It is an immense desert, where man is never lonely, for he feels life stirring on all sides.”',
    author: 'Jules Verne',
  },
  {
    quote: '“The sea is a big blue heart that beats in sync with mine.”',
    author: 'Unknown',
  },
  {
    quote:
      '“The sea is my brother, my sister, my mother, my father. The sea is my home.”',
    author: 'Unknown',
  },
  {
    quote:
      '“The sea is a symphony of sounds, colors, and movements. It is a source of inspiration, wonder, and joy.”',
    author: 'Unknown',
  },
  {
    quote:
      '“The sea is a mirror of the sky. It reflects the mood, the beauty, and the mystery of the heavens.”',
    author: 'Unknown',
  },
  {
    quote:
      '“The sea is a place of mystery and magic, where anything can happen and dreams can come true.”',
    author: 'Unknown',
  },
  {
    quote:
      '“The sea is a friend that never betrays you. It listens to your secrets, comforts your sorrows, and celebrates your joys.”',
    author: 'Unknown',
  },
  {
    quote:
      '“The sea is a teacher that never stops teaching. It shows you the power of nature, the value of patience, and the importance of respect.”',
    author: 'Unknown',
  },
  {
    quote:
      '“The sea is a lover that never leaves you. It kisses your skin, caresses your hair, and embraces your soul.”',
    author: 'Unknown',
  },
  {
    quote:
      '“The sea is a healer that never fails you. It cleanses your wounds, soothes your pain, and restores your balance.”',
    author: 'Unknown',
  },
  {
    quote:
      '“The sea is a playground that never bores you. It offers you adventure, challenge, and fun. It invites you to explore, discover, and enjoy.”',
    author: 'Unknown',
  },
  {
    quote:
      '“The sea is a canvas that never runs out of paint. It paints you pictures of sunrise and sunset, storm and calm, moonlight and starlight. It paints you with colors of blue and green, gold and silver, purple and pink.”',
    author: 'Unknown',
  },
  {
    quote:
      '“The sea is a song that never ends. It sings you melodies of waves and wind, rain and thunder, dolphins and whales. It sings you with words of love and peace, hope and faith, gratitude and joy.”',
    author: 'Unknown',
  },
];


export const quoteDus = [
  {
    quote: 'Dance is the hidden language of the soul, speaking in movements louder than words.',
    author: 'Martha Graham',
    img: './../../assets/mood-pics/dancer-5576002_1920_45_11zon.webp'
  },
  {
    quote: 'Each step on the dance floor is a step away from the mundane and into the magic of music.',
    author: 'Unknown',
    img: './../../assets/mood-pics/ease-3134828_1920_42_11zon.webp'
  },
  {
    quote: 'In the rhythm of life, dance is the verse that makes our souls rhyme.',
    author: 'Unknown',
    img: './../../assets/mood-pics/pexels-alex-qian-2304841_35_11zon.webp'
  },
  {
    quote: 'Dancing is like dreaming with your feet—make each step count!',
    author: 'Unknown',
    img: './../../assets/mood-pics/pexels-cottonbro-studio-4737984_29_11zon.webp'
  },
  {
    quote: 'Let the music steal your body; the dance will return it with interest.',
    author: 'Unknown',
    img: './../../assets/mood-pics/pexels-pixabay-39572_17_11zon.webp'
  },
  {
    quote: 'Dance to express, not to impress; let every movement tell your story.',
    author: 'Unknown',
    img: './../../assets/mood-pics/pexels-saeid-anvar-898220_10_11zon.webp'
  },
  {
    quote: 'When in doubt, dance it out! The best conversations between mind and body happen on the dance floor.',
    author: 'Unknown',
    img: './../../assets/mood-pics/pexels-soner-arkan-12943931_9_11zon.webp'
  },
  {
    quote: 'Dancing is the art of getting your feet to whisper and your soul to scream.',
    author: 'Unknown',
    img: './../../assets/mood-pics/agility-1850711_1920_11zon.webp'
  },
  {
    quote: 'Dance is the only art wherein we ourselves are the stuff of which it is made.',
    author: 'Unknown',
    img: './../../assets/mood-pics/ballet-1262613_1920_11zon.webp'
  },
  {
    quote: 'Leap into the beat and let your spirit soar on the wings of dance.',
    author: 'Unknown',
    img: './../../assets/mood-pics/bellydancer-5117920_1920_50_11zon.webp'
  },
  {
    quote: 'Dance is the rhythm of the universe translated into human motion.',
    author: 'Unknown',
    img: './../../assets/mood-pics/couple-4325416_1920_49_11zon.webp'
  },
  {
    quote: 'Beneath every dancer’s movement is a heartbeat syncing with the melody of life.',
    author: 'Unknown',
    img: './../../assets/mood-pics/dance-1940245_1920_47_11zon.webp'
  },
  {
    quote: 'In each twirl, find the freedom that only dancers know.',
    author: 'Unknown',
    img: './../../assets/mood-pics/dance-aerials-4193863_1920_48_11zon.webp'
  },
  {
    quote: "The dance floor is a canvas, and your feet are brushes painting your soul’s desires.",
    author: 'Unknown',
    img: './../../assets/mood-pics/dancer-4516788_1920_46_11zon.webp'
  },
  {
    quote: "Move to the sound of your dreams, and let the dance guide you to who you're meant to become.",
    author: 'Unknown',
    img: './../../assets/mood-pics/dancer-7357488_1920_44_11zon.webp'
  },
  {
    quote: "Dance is the boldest act of self-expression—one beat, one step, one story at a time.",
    author: 'Unknown',
    img: './../../assets/mood-pics/doll-976355_1920_43_11zon.webp'
  },
  {
    quote: "Let your feet do the talking, and express what words cannot capture.",
    author: 'Unknown',
    img: './../../assets/mood-pics/flamenco-1046485_1920_41_11zon.webp'
  },
  {
    quote: "In the echo of ancient rhythms, dance connects us to the eternal pulse of humanity.",
    author: 'Unknown',
    img: './../../assets/mood-pics/freedom-2940655_1920_40_11zon.webp'
  },
  {
    quote: "Every dance is a love affair between the heart and the beat.",
    author: 'Unknown',
    img: './../../assets/mood-pics/hip-hop-2159913_1920_39_11zon.webp'
  },
  {
    quote: "Dance like the stars are watching, and express the universe within you.",
    author: 'Unknown',
    img: './../../assets/mood-pics/holi-2416686_1920_37_11zon.webp'
  },
  {
    quote: "Dance first. Think later. It's the natural order.",
    author: 'Samuel Beckett',
    img: './../../assets/mood-pics/legs-191543_1920_38_11zon.webp'
  },
  {
    quote: "Dancing is creating a sculpture that is visible only for a moment.",
    author: 'Erol Ozan',
    img: './../../assets/mood-pics/pexels-ana-bregantin-1930364_36_11zon.webp'
  },
  {
    quote: "Let us dance in the sun, wearing wild flowers in our hair.",
    author: 'Susan Polis Schutz',
    img: './../../assets/mood-pics/pexels-anastasia-shuraeva-8752654_33_11zon.webp'
  },
  {
    quote: "To watch us dance is to hear our hearts speak.",
    author: 'Hopi Indian Saying',
    img: './../../assets/mood-pics/pexels-anastasia-shuraeva-8935911_34_11zon.webp'
  },
  {
    quote: "Dancers don’t need wings to fly.",
    author: 'Unknown',
    img: './../../assets/mood-pics/pexels-andrew-4226422_32_11zon.webp'
  },
  {
    quote: "Life is short and there will always be dirty dishes, so let's dance.",
    author: 'James Howe',
    img: './../../assets/mood-pics/pexels-anna-tarazevich-6900842_31_11zon.webp'
  },
  {
    quote: "When you dance, your purpose is not to get to a certain place on the floor. It's to enjoy each step along the way.",
    author: 'Wayne Dyer',
    img: './../../assets/mood-pics/pexels-anastasia-shuraeva-8935911_34_11zon.webp'
  },
  {
    quote: "Dance is the timeless interpretation of life.",
    author: 'Shah Asad Rizvi',
    img: './../../assets/mood-pics/pexels-anastasia-shuraeva-8935911_34_11zon.webp'
  },
  {
    quote: "Great dancers are not great because of their technique, they are great because of their passion.",
    author: 'Martha Graham',
    img: './../../assets/mood-pics/pexels-anastasia-shuraeva-8752654_33_11zon.webp'
  },
  {
    quote: "When you dance, the world becomes an ocean, and you are the wave.",
    author: 'Unknown',
    img: './../../assets/mood-pics/pexels-anastasia-shuraeva-8935911_34_11zon.webp'
  },
  {
    quote: "Dance is music made visible.",
    author: 'George Balanchine',
    img: './../../assets/mood-pics/pexels-anna-tarazevich-6900842_31_11zon.webp'
  },
  {
    quote: "Dancers are the athletes of God.",
    author: 'Albert Einstein',
    img: './../../assets/mood-pics/pexels-anastasia-shuraeva-8935911_34_11zon.webp'
  },
  {
    quote: "Every day brings a chance for you to draw in a breath, kick off your shoes, and dance.",
    author: 'Oprah Winfrey',
    img: './../../assets/mood-pics/pexels-anastasia-shuraeva-8935911_34_11zon.webp'
  },
  {
    quote: "Dancing is dreaming with your feet.",
    author: 'Constanze',
    img: './../../assets/mood-pics/pexels-anastasia-shuraeva-8752654_33_11zon.webp'
  },
  {
    quote: "The job of feets is walking, but their hobby is dancing.",
    author: 'Amit Kalantri',
    img: './../../assets/mood-pics/pexels-andrew-4226422_32_11zon.webp'
  },
  {
    quote: "Step into the dance of life, the rhythm is already inside you.",
    author: 'Unknown',
    img: './../../assets/mood-pics/pexels-anastasia-shuraeva-8935911_34_11zon.webp'
  },
  {
    quote: "Dancing through life is easier than you think; just keep the beat and your feet will follow.",
    author: 'Unknown',
    img: './../../assets/mood-pics/pexels-andrew-4226422_32_11zon.webp'
  },
  {
    quote: "A day without dance is a day wasted.",
    author: 'Friedrich Nietzsche',
    img: './../../assets/mood-pics/pexels-anastasia-shuraeva-8935911_34_11zon.webp'
  },
  {
    quote: "The dance is over, the applause subsided, but the joy and feeling will stay with you forever.",
    author: 'W.M. Tory',
    img: './../../assets/mood-pics/pexels-anna-tarazevich-6900842_31_11zon.webp'
  },
  {
    quote: "In life as in dance: Grace glides on blistered feet.",
    author: 'Alice Abrams',
    img: './../../assets/mood-pics/pexels-anastasia-shuraeva-8752654_33_11zon.webp'
  },
  {
    quote: "Dance to the beat of your dreams.",
    author: 'Unknown',
    img: './../../assets/mood-pics/pexels-andrew-4226422_32_11zon.webp'
  },
  {
    quote: "Life is better when you dance like no one's watching.",
    author: 'Unknown',
    img: './../../assets/mood-pics/pexels-anastasia-shuraeva-8935911_34_11zon.webp'
  },
  {
    quote: "There are shortcuts to happiness, and dancing is one of them.",
    author: 'Vicki Baum',
    img: './../../assets/mood-pics/pexels-andrew-4226422_32_11zon.webp'
  },
  {
    quote: "The rhythm of the body, the melody of the mind, and the harmony of the soul create the symphony of life.",
    author: 'B.K.S. Iyengar',
    img: './../../assets/mood-pics/pexels-anastasia-shuraeva-8752654_33_11zon.webp'
  },
  {
    quote: "Your feet can learn the steps, but only your spirit can dance.",
    author: 'Unknown',
    img: './../../assets/mood-pics/pexels-andrew-4226422_32_11zon.webp'
  },
  {
    quote: "Life is a dance from one stage to the next. Enjoy the movement.",
    author: 'Unknown',
    img: './../../assets/mood-pics/pexels-anastasia-shuraeva-8935911_34_11zon.webp'
  },
  {
    quote: "Dancing faces you towards Heaven, whichever direction you turn.",
    author: 'Sweetpea Tyler',
    img: './../../assets/mood-pics/pexels-anastasia-shuraeva-8752654_33_11zon.webp'
  },
  {
    quote: "No matter what, keep dancing.",
    author: 'Unknown',
    img: './../../assets/mood-pics/pexels-andrew-4226422_32_11zon.webp'
  },
  {
    quote: "Let your steps carve a path to where your heart thrives.",
    author: 'Unknown',
    img: './../../assets/mood-pics/pexels-anna-tarazevich-6900842_31_11zon.webp'
  },
  {
    quote: "Dance with your heart, and your feet will follow the melody of your spirit.",
    author: 'Unknown',
    img: './../../assets/mood-pics/pexels-anastasia-shuraeva-8935911_34_11zon.webp'
  },
  {
    quote: "Leap into the dance of life and spin with the joy of the moment.",
    author: 'Unknown',
    img: './../../assets/mood-pics/pexels-andrew-4226422_32_11zon.webp'
  },
  {
    quote: "In the rhythm of life, find your dance, find your pace.",
    author: 'Unknown',
    img: './../../assets/mood-pics/pexels-anna-tarazevich-6900842_31_11zon.webp'
  },
  {
    quote: "Spin gracefully through life as if every moment is a dance floor.",
    author: 'Unknown',
    img: './../../assets/mood-pics/pexels-anna-tarazevich-6900842_31_11zon.webp'
  },
];