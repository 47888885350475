import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../firebase/auth.service';
import mixpanel from 'mixpanel-browser';
import { environment } from '../../../environments/environment';
import { AdmobService } from 'src/app/shared/adv/admob.service';

@Component({
  selector: 'app-bazaar-menu',
  templateUrl: './bazaar-menu.component.html',
  styleUrls: ['./bazaar-menu.component.scss'],
})
export class BazaarMenuComponent implements OnInit, OnDestroy {
  environment = environment;
  toolbarData = {
    arrowRouter: '/',
    btnSlot: 'end',
    title: 'Bazaar',
  };

  properties;
  constructor(
    private authService: AuthService,
    private admobService: AdmobService
  ) {}

  ngOnInit(): void {
    mixpanel.init(environment.mixpanelToken, { debug: true });
    const { uid } = this.authService.isUserAuth();
    mixpanel.identify(uid);
  }

  trackEvent(event, parameter, parameterValue) {
    if (parameter === 'screen-name') {
      this.properties = {
        'screen-name': parameterValue,
      };
    }

    mixpanel.track(event, this.properties);
  }
  async ngOnDestroy() {
    await this.admobService.ionViewWillLeave();
  }
}
