import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countryPipe',
})
export class CountryPipe implements PipeTransform {
  transform(value: any[] | any, country: string | null) {
    if (Array.isArray(value) && value && country) {
      const newValue = value.filter(item => item.country.name === country);
      return newValue;
    } else return value;
  }
}
