import { Injectable } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { CrudHelper } from '../../firebase/helpers/crudHelper';
import { CommonService } from '../../shared/component-store/common-service';
import { AuthService } from 'src/app/firebase/auth.service';

@Injectable()
export class PoolsService extends CommonService {
  userId: string;
  isOpenSuccessModal = new BehaviorSubject(false);
  constructor(
    private fb: FormBuilder,
    private firebaseHelperService: CrudHelper,
    private authService: AuthService
  ) {
    super(firebaseHelperService);
  }

  addImage(images) {
    let i = images.length;
    i = this.fb.group({
      image: [null],
    });
    images.push(i);
  }

  deleteImage(images: FormArray, i: number) {
    images.removeAt(i);
  }

  getProfile(email: string) {
    return this.firebaseHelperService.searchByField('users', 'email', email);
  }

  getItems({ collectionName, filter }) {
    const queryFn = ref => {
      let query = ref;

      const {
        country,
        freeEntry,
        dogsAllowed,
        naturePools,
        finsAllowed,
        status,
        creator,
        myContent,
      } = filter;

      if (country && country.length > 0) {
        query = query.where('country', 'in', country);
      }

      if (freeEntry) {
        query = query.where('entryFree', '==', freeEntry);
      }

      if (dogsAllowed) {
        query = query.where('dogsAllowed', '==', dogsAllowed);
      }

      if (finsAllowed) {
        query = query.where('finsAllowed', '==', finsAllowed);
      }

      if (naturePools) {
        query = query.where('natural', '==', naturePools);
      }

      if (status) {
        query = query.where('status', '==', status);
      }

      if (creator || myContent) {
        const user = creator || this.authService.isUserAuth().uid;
        query = query.where('creator.id', '==', user);
        query = query.where('status', 'in', [
          'approved',
          'pending',
          'rejected',
        ]);
      }

      if (!status && !creator && !myContent) {
        query = query.where('status', '==', 'approved');
      }

      query = query.orderBy('createdAt', 'desc');

      return query;
    };

    return this.firebaseHelperService.getCollectionItems({
      collectionName,
      queryFn,
    });
  }

  getMyItems(collectionName, creator) {
    const queryFn = ref => {
      let query = ref;

      query = query.where('creator.id', '==', creator);

      return query;
    };

    return this.firebaseHelperService.getCollectionItems({
      collectionName,
      queryFn,
    });
  }

  checkFilter(filter) {
    const result =
      filter === false ||
      ((filter.country === null || !filter.country.length) &&
        !filter.dogsAllowed &&
        !filter.naturePools &&
        !filter.finsAllowed &&
        !filter.freeEntry &&
        !filter.myContent);
    return !result;
  }
}
