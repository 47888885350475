import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { matchValidator } from 'src/app/auth/sign-up/sign-up.component';
import { confirmModalData } from '../profile.service';
import { AuthService } from 'src/app/firebase/auth.service';
import { ProfileStore } from '../store/profile-store';
import { ActivatedRoute } from '@angular/router';
import { Subject, filter, takeUntil } from 'rxjs';
import {
  createToaster,
  positionType,
} from 'src/app/shared/components/modals/toaster';
import { getAuth, updatePassword } from 'firebase/auth';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss'],
})
export class UpdatePasswordComponent implements OnInit, OnDestroy {
  @Input() showPasswordForm: boolean;

  @Output() update: EventEmitter<boolean> = new EventEmitter();
  @Output() cancel: EventEmitter<boolean> = new EventEmitter();

  form: FormGroup;
  loading = false;
  type: string = 'password';
  typeConfirm: string = 'password';
  typeOld: string = 'password';

  iconName: string = 'eye';
  iconNameConfirm: string = 'eye';
  iconNameOld: string = 'eye';

  showConfirmModal = false;
  confirmModalData = confirmModalData;

  userId: string;
  user;
  authUser;

  private destroy$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private profileStore: ProfileStore,
    private activeRouter: ActivatedRoute
  ) {}

  ngOnInit() {
    this.userId = this.activeRouter.snapshot.params.id;
    this.profileStore.getItemByByValue({ uid: this.userId });
    this.authUser = getAuth().currentUser;
    this.listenUserChanges();
    this.form = this.fb.group(
      {
        oldPassword: [
          '',
          [
            Validators.required,
            Validators.minLength(6),
            matchValidator('confirmPassword', true),
          ],
        ],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(6),
            matchValidator('confirmPassword', true),
          ],
        ],
        repeatPassword: ['', [Validators.required, matchValidator('password')]],
      },
      {
        validators: [matchValidator],
      }
    );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  handleUpdate() {
    this.loading = true;

    updatePassword(this.authUser, this.form.get('password').value)
      .then(() => {
        this.authService
          .signIn({
            email: this.user.email,
            password: this.form.get('password').value,
            rememberMe: true,
          })
          .then(() => {
            this.loading = false;
            this.update.next(false);
            this.form.reset();
            createToaster('Update successful', 'success', positionType.BOTTOM);
          });
      })
      .catch(error => {
        this.loading = false;
        createToaster(
          `ERROR: ${error?.slice(5)}`,
          'danger',
          positionType.BOTTOM
        );
      });

    this.update.next(true);
  }

  handleCancel() {
    this.form.reset();
    this.cancel.next(true);
  }

  changeType() {
    this.type = this.type === 'password' ? 'text' : 'password';
    this.iconName = this.iconName === 'eye' ? 'eye-off' : 'eye';
  }

  changeTypeConfirm() {
    this.typeConfirm = this.typeConfirm === 'password' ? 'text' : 'password';
    this.iconNameConfirm = this.iconNameConfirm === 'eye' ? 'eye-off' : 'eye';
  }

  changeTypeOld() {
    this.typeOld = this.typeOld === 'password' ? 'text' : 'password';
    this.iconNameOld = this.iconNameOld === 'eye' ? 'eye-off' : 'eye';
  }

  private listenUserChanges() {
    this.profileStore
      .selectItem()
      .pipe(
        filter(user => !!user),
        takeUntil(this.destroy$)
      )
      .subscribe(user => (this.user = user));
  }
}
