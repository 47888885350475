import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { combineLatest, EMPTY, filter, Observable, take, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';
import { CommonService } from './common-service';
import { Item } from '../models/item.models';

export interface ItemState<T extends Item> {
  events: any;
  jobs: any;
  services: any;
  pods: any;
  pools: any;
  gallery: any;
  createdId: string;
  item: T;
  items: T[];
  loading: boolean;
  collectionName: string;
  showPendingPoolModal: boolean;
  showPendingPodModal: boolean;
}

export class CommonComponentStore<T extends Item> extends ComponentStore<
  ItemState<T>
> {
  constructor(private commonService: CommonService, state) {
    super({
      item: null,
      items: [],
      loading: false,
      collectionName: '',
      ...state,
    });
  }

  logOut() {
    this.patchState(null);
  }

  getItemByByValue(searchField) {
    return this.effect((searchField: Observable<any>) => {
      return combineLatest([
        searchField,
        this.select(state => state.collectionName),
      ]).pipe(
        switchMap(([searchField, collectionName]) => {
          this.patchState({ loading: true });

          return this.commonService
            .getItemByValue({ collectionName, searchField })
            .pipe(
              tapResponse(
                (items: any) => {
                  this.patchState({ item: items[0], loading: false });
                },
                (error: HttpErrorResponse) => {
                  this.patchState({ loading: false });
                }
              ),
              catchError(async err => console.log(err))
            );
        })
      );
    })(searchField);
  }

  getItems(filter) {
    if (filter === undefined) filter = false;
    return this.effect((filter: Observable<any>) => {
      return combineLatest([
        filter,
        this.select(state => state.collectionName),
      ]).pipe(
        switchMap(([filter, collectionName]) => {
          this.patchState({ loading: true });
          return this.commonService.getItems({ collectionName, filter }).pipe(
            tapResponse(
              (items: any) => {
                this.patchState({ items: items, loading: false });
              },
              (error: HttpErrorResponse) => {
                this.patchState({ loading: false });
              }
            ),
            catchError(() => EMPTY)
          );
        })
      );
    })(filter);
  }

  getFirstPaginatedItems(filter, limit, sortBy) {
    if (filter === undefined) filter = false;
    return this.effect((filter: Observable<any>) => {
      return combineLatest([
        filter,
        this.select(state => state.collectionName),
      ]).pipe(
        switchMap(([filter, collectionName]) => {
          this.patchState({ loading: true });

          return this.commonService
            .getFirstPagePaginatedItems(collectionName, filter, limit, sortBy)
            .pipe(
              tapResponse(
                (items: any) => {
                  this.patchState({ items: items, loading: false });
                },
                (error: HttpErrorResponse) => {
                  this.patchState({ loading: false });
                  console.log(error);
                }
              ),
              catchError(() => EMPTY)
            );
        })
      );
    })(filter);
  }

  getPreviewItems(filter, limit, forbidden) {
    return this.effect((filter: Observable<any>) => {
      return combineLatest([
        filter,
        this.select(state => state.collectionName),
      ]).pipe(
        switchMap(([filter, collectionName]) => {
          this.patchState({ loading: true });
          return this.commonService
            .getPreviewItems(collectionName, filter, limit, forbidden)
            .pipe(
              tapResponse(
                (items: any) => {
                  this.patchState({ items: items, loading: false });
                },
                (error: HttpErrorResponse) => {
                  this.patchState({ loading: false });
                  console.log(error);
                }
              ),
              catchError(() => EMPTY)
            );
        })
      );
    })(filter);
  }

  createItem(data) {
    return this.effect((data: Observable<any>) => {
      return combineLatest([
        data,
        this.select(state => state.collectionName),
      ]).pipe(
        take(1),
        switchMap(([data, collectionName]) => {
          this.patchState({ loading: true });

          return this.commonService.addItem({ collectionName, data }).pipe(
            tapResponse(
              id => {
                this.patchState({ item: data, loading: false, createdId: id });
              },
              (error: HttpErrorResponse) => {
                this.patchState({ loading: false });
                console.log('error', error);
              },
              () => this.patchState({ loading: true })
            ),
            catchError(() => EMPTY)
          );
        })
      );
    })(data);
  }

  updateProfile(item) {
    return this.effect((item: Observable<any>) =>
      item.pipe(
        filter(item => !!item),
        tap(() => this.patchState({ loading: true })),
        switchMap(profile =>
          this.selectItem().pipe(
            take(1),
            switchMap(item => this.updateItem(profile, item))
          )
        )
      )
    )(item);
  }

  removeItem(itemId) {
    return this.effect((itemId: Observable<string>) => {
      return combineLatest([
        itemId,
        this.select(state => state.collectionName),
        this.select(state => state.items),
      ]).pipe(
        take(1),
        switchMap(([itemId, collectionName, items]) => {
          const newItems = items.filter(item => item.id !== itemId);
          this.patchState({ loading: true });
          return this.commonService
            .deleteItem({ collectionName, id: itemId })
            .pipe(
              takeUntil(this.selectLoading()),
              tapResponse(
                () => {
                  this.patchState({
                    loading: false,
                    items: newItems,
                  });
                },
                (error: HttpErrorResponse) => {
                  console.log('error', error);
                  this.patchState({ loading: false });
                }
              ),
              catchError(() => EMPTY)
            );
        })
      );
    })(itemId);
  }

  selectItem() {
    return this.select(state => state.item);
  }

  selectId() {
    return this.select(state => state.createdId);
  }

  selectItems() {
    return this.select(state => state.items);
  }

  selectLoading() {
    return this.select(state => state.loading);
  }

  selectCollectionName() {
    return this.select(state => state.collectionName);
  }

  protected updateItem(profile: any, item: any) {
    return this.select(state => state.collectionName).pipe(
      switchMap(collectionName => {
        return this.commonService
          .updateItemById(collectionName, profile, item.id)
          .pipe(
            tapResponse(
              () => {
                if (collectionName !== 'users') {
                  this.patchState({
                    item: { ...profile },
                    loading: false,
                  });
                } else {
                  this.patchState({
                    item: profile,
                    loading: false,
                  });
                }
              },
              (error: HttpErrorResponse) => {
                this.patchState({ loading: false });
                console.log(error);
              }
            ),
            catchError(() => EMPTY)
          );
      })
    );
  }

  setLoading() {
    return this.patchState({
      loading: true,
    });
  }
  protected updateFeedItem(profile: any, item: any) {
    return this.select(state => state.collectionName).pipe(
      switchMap(collectionName => {
        return this.commonService
          .updateItemById(collectionName, profile, item.itemId)
          .pipe(
            tapResponse(
              () => {
                this.patchState({
                  ...profile,
                  item: profile.item,
                  loading: false,
                });
              },
              (error: HttpErrorResponse) => console.log(error)
            ),
            catchError(() => EMPTY)
          );
      })
    );
  }
}
