import {Component, Input} from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-empty-item',
  templateUrl: './empty-item.component.html',
  styleUrls: ['./empty-item.component.scss'],
})
export class EmptyItemComponent {
  environment = environment;
  @Input() emptyType: string;
  constructor() {
  }
}
