import { Injectable } from '@angular/core';
import { ServicesService } from './services.service';
import { CommonComponentStore } from '../../../shared/component-store/common-component-store';
import { ServiceItem } from '../../bazaar';

@Injectable({ providedIn: 'root' })
export class ServicesStore extends CommonComponentStore<ServiceItem> {
  constructor(private servicesService: ServicesService) {
    super(servicesService, {
      createdId: null,
      item: null,
      items: null,
      loading: false,
      showFormModal: false,
      showPendingModal: false,
      collectionName: 'services',
    });
  }

  selectShowFormModal() {
    // TODO update
    // @ts-ignore
    return this.select(state => state.showFormModal);
  }

  updateShowFormModal(value) {
    // TODO update
    // @ts-ignore
    this.patchState({ showFormModal: value });
  }

  selectShowPendingModal() {
    // TODO update
    // @ts-ignore
    return this.select(state => state.showPendingModal);
  }

  updateShowPendingModal(value) {
    // TODO update
    // @ts-ignore
    this.patchState({ showPendingModal: value });
  }
}
