import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-event-search',
  templateUrl: './event-search.component.html',
  styleUrls: ['./event-search.component.scss'],
})
export class EventSearchComponent {
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Input() color;
  @Output() onSearchChange = new EventEmitter<string>();
  
  toolbarData = {
    arrowRouter: '/events',
    title: 'Events',
  };

  constructor() {}

  searchItem(event) {
    this.onSearchChange.emit(event.target.value);
  }
}
