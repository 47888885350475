import { Component, Input, TemplateRef } from '@angular/core';
import { Timestamp } from 'firebase/firestore';
import { DateService } from 'src/app/services/date.service';

@Component({
  selector: 'app-item-date',
  templateUrl: './item-date.component.html',
  styleUrls: ['./item-date.component.scss'],
})
export class ItemDateComponent {
  @Input() startDate: string;
  @Input() endDate: string;
  @Input() item: string;
  @Input() template: TemplateRef<any>;

  constructor(private dateService: DateService) {}

  checkDate(startDate: Timestamp, endDate: Timestamp) {
    return this.dateService.checkSameDate(startDate, endDate);
  }
}
